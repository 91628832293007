import React from "react";
import Breadcrumbs from "Components/Breadcrumbs";
import TrinityNews from "./TrinityNews";

const TheWord = () => (
  <div className="container bg-white mt-4 p-5">
    <Breadcrumbs items={[{ name: "Home", to: "/" }, { name: "The Word" }]} />
    <TrinityNews />
  </div>
);

export default TheWord;
