import React, { Component } from "react";
import { string, number, arrayOf } from "prop-types";
import Pagination from "../../Components/PaginationWrapper";

import { userType, isInternal } from "../../modules/auth";

import {
  filterPostsByUserType,
  loadPosts,
  getPostDetails,
} from "../../modules/cms";

import { connect } from "react-redux";

import Loading from "Components/Loading";
import { NewsArticle } from "../Home/NewsArticle";

class TrinityNews extends Component {
  static propTypes = {
    categories: arrayOf(number).isRequired,
    userType: string.isRequired,
  };

  state = {
    loaded: false,
    error: false,
    posts: [],
  };

  loadPostsPage(pageIndex) {
    const { categories } = this.props;

    return loadPosts({
      categories,
      params: {
        per_page: 50,
        page: pageIndex,
      },
    });
  }

  loadAllPages(pageIndex = 1) {
    this.loadPostsPage(pageIndex).then(({ posts, pageCount }) => {
      this.setState(state => ({
        posts: state.posts.concat(posts),
        loaded: true,
      }));

      if (pageCount > pageIndex) {
        this.loadAllPages(pageIndex + 1);
      }
    });
  }

  componentDidMount() {
    this.loadAllPages();
  }

  render() {
    const { loaded, error, posts } = this.state;
    const { userType, isInternal } = this.props;

    if (error) return <div>Error</div>;

    if (!loaded)
      return (
        <div
          className="container flex flex-col justify-center"
          style={{
            minHeight: "50vh",
          }}
        >
          <Loading large />
        </div>
      );

    const filteredPosts = filterPostsByUserType({
      posts,
      userType,
      isInternal,
    });

    return (
      <Pagination
        items={filteredPosts}
        itemsPerPage={12}
        render={({ items }) => (
          <div style={{ minHeight: "50vh" }}>
            <div className="flex flex-wrap -mx-4">
              {items.map(post => (
                <div className="w-full md:w-1/2 lg:w-1/3 px-4" key={post.id}>
                  <NewsArticle
                    {...getPostDetails(post)}
                    linkState={{
                      post,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      />
    );
  }
}

export default connect(state => ({
  userType: userType(state),
  isInternal: isInternal(state),
}))(TrinityNews);
