import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getWidth } from "redux-window";
import { withScriptjs } from "react-google-maps";
import axios from "axios";
import { isLoggedIn } from "modules/auth";
import useAxios from "hooks/useAxios";
import Loading from "Components/Loading";
import FeaturedSection, {
  FeaturedSectionNoFetch,
} from "Components/FeaturedSection";
import { ButtonBrand, ButtonBlack, ButtonPrimary } from "Components/Button";
import { Profile as DestinationProfile } from "Components/destinations";
import ArticleSectionAltPaginated from "Components/ArticleSectionAltPaginated";
import BannerImg from "./BannerImg";
import Search from "./Search";
import Map from "./Map";

const Location = ({ location, match, width }) => {
  const [view, setView] = useState("venues");
  const [categoryId, setCategoryId] = useState();
  const [results, setResults] = useState([]);
  const [articles, setArticles] = useState([]);
  const [offers, setOffers] = useState([]);
  const slug = match.params.location;

  const { data, loading } = useAxios(
    {
      url: `${window.API}/destinations/${slug}`,
    },
    location.pathname
  );

  const { data: categories } = useAxios(
    {
      url: `${window.API}/venue-categories`,
    },
    location.pathname
  );

  useEffect(() => {
    if (results.length) {
      axios
        .get(window.API + "/cms/venues", {
          params: { venues: results.map(r => r.id) },
        })
        .then(r => {
          if (r.data && r.data.articles && r.data.offers) {
            setArticles(
              r.data.articles.map(a => ({
                title: a.title,
                content: a.content,
                img: a.image ? a.image.url : null,
                link: `/article/${a.slug}`,
              }))
            );
            setOffers(
              r.data.offers.map(a => ({
                title: a.title,
                content: a.content,
                img: a.image ? a.image.url : null,
                link: `/offer/${a.id}`,
              }))
            );
          }
        })
        .catch(() => {});
    }
  }, [results]);

  if (loading)
    return (
      <div className="py-3">
        <Loading large />
      </div>
    );

  const name = data
    ? data.city
    : slug.replace("-", " ").replace(/\b\w/g, l => l.toUpperCase());
  const size = width > 991 ? "lg" : "md";
  const address = data ? `${name}, ${data.country.name}` : `${name}, UK`;

  const VButton = view === "venues" ? ButtonBlack : ButtonBrand;
  const NButton = view === "news" ? ButtonBlack : ButtonBrand;
  const OButton = view === "special offers" ? ButtonBlack : ButtonBrand;

  return (
    <div className="container">
      <BannerImg slug={slug} size={size} name={name} />

      <div className="my-5 flex flex-wrap flex-col md:flex-row">
        <VButton
          classes="mb-3 md:mr-2 xxl:mb-0 text-sm xxl:text-base"
          onClick={() => {
            setView("venues");
          }}
        >
          Venues
        </VButton>
        {!!articles.length && (
          <NButton
            classes="mb-3 md:mr-2 xxl:mb-0 text-sm xxl:text-base"
            onClick={() => {
              setView("news");
            }}
          >
            News
          </NButton>
        )}
        {!!offers.length && (
          <OButton
            classes="mb-3 xxl:mb-0 md:mr-2 text-sm xxl:text-base"
            onClick={() => {
              setView("special offers");
            }}
          >
            Special Offers
          </OButton>
        )}
        {categories &&
          categories.map(cat => {
            if (!cat.show_on_location) {
              return null;
            }
            const Button = cat.id === categoryId ? ButtonBlack : ButtonPrimary;
            return (
              <Button
                key={cat.id}
                classes="mb-3 xxl:mb-0 md:mr-2 text-sm xxl:text-base"
                onClick={() => {
                  setCategoryId(cat.id);
                }}
              >
                {cat.name}
              </Button>
            );
          })}
      </div>

      {view === "venues" && (
        <React.Fragment>
          <Search
            setResults={setResults}
            address={address}
            categoryId={categoryId}
          />
          <div className={`my-8 ${data ? "xl:flex xl:flex-row-reverse" : ""}`}>
            <div className={data && "mb-8 xl:mb-0 xl:w-1/2"}>
              <Map
                results={results}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvso7D8EYP9kyPsNgBLNa9nQ75gzqmVGU&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div className="h-full relative" style={{ minHeight: 400 }} />
                }
                mapElement={<div className="absolute pin" />}
              />
            </div>
            {data && (
              <div className="xl:w-1/2">
                <DestinationProfile destination={data} />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {view === "news" && (
        <ArticleSectionAltPaginated
          title={`${name} News`}
          articles={articles}
        />
      )}
      {view === "special offers" && (
        <ArticleSectionAltPaginated
          title={`${name} Special Offers`}
          articles={offers}
        />
      )}

      {data && (
        <FeaturedSectionNoFetch
          title={`Other Locations in ${data.country.name}`}
          data={data.similar_destinations}
        />
      )}

      <FeaturedSection
        title="EDGE Worldwide"
        url={`${window.API}/destinations-list?worldwide=1&featured=1`}
        link="/worldwide-venue-locations"
        linkText="See all locations"
      />
    </div>
  );
};

const ConnectedLocation = withRouter(
  connect(state => ({
    width: getWidth(state),
    isLoggedIn: isLoggedIn(state),
  }))(Location)
);

const WithScript = withScriptjs(ConnectedLocation);

const ToExport = props => (
  <WithScript
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `400px` }} />}
    googleMapURL={
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBvso7D8EYP9kyPsNgBLNa9nQ75gzqmVGU&v=3.exp&libraries=geometry,drawing,places,geocoding"
    }
    rest={props}
  />
);

export default ToExport;
