import axios from "axios";

import { CATEGORY_GROUP_IDS, sortBy } from "./utils";

const api = "https://cms.edgevenues.com/wp-json/wp/v2";

export const loadPosts = ({
  categories = undefined,
  categoryGroups = undefined,
  params: propParams = {},
} = {}) => {
  const params = { ...propParams };

  if (categories) {
    params.categories = categories.join(",");
  } else if (categoryGroups) {
    params.categories = categoryGroups
      .reduce(
        (group, category) => group.concat(CATEGORY_GROUP_IDS[category]),
        []
      )
      .join(",");
  }

  params._embed = 1;

  return axios
    .get(api + "/posts", {
      params,
    })
    .then(({ data, headers }) => {
      return {
        posts: sortBy(data, "modified_gmt", true),
        postCount: headers["x-wp-total"],
        pageCount: headers["x-wp-totalpages"],
      };
    });
};
