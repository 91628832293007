import React from 'react';
import RequestForQuote from 'Components/RequestForQuote';
import SingleResult from './SingleResult';
import { searchClickThrough, searchImpressions } from 'modules/analytics';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { isAgent, isLoggedIn } from '../../modules/auth';
import { RequireLogin } from '../login-redirect';
import { pushEvent } from '../../modules/google-tag-manager';

const MAX_RESULTS = 200;
const NO_OF_RESULTS_SENT_TO_ANALYTICS = 25;

class Results extends React.Component {
  state = {
    modal: null,
    id: null,
    name: null,
    show: 25,
    requireLogin: false,
  };

  componentDidMount() {
    if (this.props.results.length) {
      this.props.searchImpressions(
          this.props.results
              .slice(0, NO_OF_RESULTS_SENT_TO_ANALYTICS)
              .map((v, i) => ({
                venue_id: v.id,
                rank: i + 1,
                data_score: v.data_score,
                payload_simple: this.props.payload_simple,
                payload_advanced: this.props.payload_advanced,
              })),
      );
    }
  }

  componentDidUpdate(prev, prevState) {
    if (this.props.results.length && prevState.show !== this.state.show) {
      this.props.searchImpressions(
          this.props.results
              .slice(prevState.show, this.state.show)
              .map((v, i) => ({
                venue_id: v.id,
                rank: i + 1,
                data_score: v.data_score,
                payload_simple: this.props.payload_simple,
                payload_advanced: this.props.payload_advanced,
              })),
      );
    } else if (
        this.props.results.length &&
        this.props.results !== prev.results
    ) {
      this.props.searchImpressions(
          this.props.results.slice(0, this.state.show).map((v, i) => ({
            venue_id: v.id,
            rank: i + 1,
            data_score: v.data_score,
            payload_simple: this.props.payload_simple,
            payload_advanced: this.props.payload_advanced,
          })),
      );
    }
  }

  shortListForm = () => `${this.props.type}-search-shortlist`;

  shortlistedVenues = () =>
      this.props.activeShortlist &&
      Array.isArray(this.props.activeShortlist.venues)
          ? this.props.activeShortlist.venues
          : [];

  venueOnList = id => !!this.shortlistedVenues().find(venue => venue.id === id);

  toggleShortlist = (toggleVenue, isAdding) => {
    const venues = this.shortlistedVenues();

    const newVenuesShortList = isAdding
        ? [...venues, toggleVenue]
        : venues.filter(venue => venue.id !== toggleVenue.id);

    this.props.change(this.shortListForm(), 'venues', newVenuesShortList);
  };

  colour = status => {
    const isPaid = status === 'premium' || status === 'paid';
    if (this.props.isAgent && isPaid) return 'orange';
    if (isPaid) return 'primary';
    return 'dark-grey';
  };

  hasExtras = v => {
    return (
        !!parseInt(v.buy_it_now, 10) ||
        !!v.specialOffer ||
        !!(this.props.isAgent && v.commission)
    );
  };

  listingText = v => {
    if (this.props.isAgent && v.status === 'premium') return 'Agency EDGE';
    if (v.status === 'premium' || v.status === 'paid') return 'EDGE Plus';
    return '';
  };

  quoteHandler = ({ venue, rank }) => {
    const { isLoggedIn } = this.props;

    if (!isLoggedIn) {
      this.setState({ requireLogin: true });
      return;
    }

    this.setState({
      modal: true,
      id: venue.id,
      name: venue.name,
      data_score: venue.data_score,
    });

    pushEvent('search_quote', {
      ecommerce: {
        click: {
          actionField: {
            list: 'Search Results',
          },
          products: [
            {
              id: venue.id,
              name: venue.name,
              category: '',
              position: rank + 1,
            },
          ],
        },
      },
    });

    this.props.searchClickThrough({
      venue_id: venue.id,
      payload_simple: this.props.payload_simple,
      payload_advanced: this.props.payload_advanced,
      rank: rank + 1,
      data_score: venue.data_score,
    });
  };

  render() {
    return (
        <div>
          {this.state.requireLogin ? <RequireLogin/> : null}
          {this.props.results.slice(0, this.state.show).map((venue, rank) => {
            const isSelected = this.venueOnList(venue.id);
            const selectedVenues = (this.props.activeShortlist && this.props.activeShortlist.venues) ? this.props.activeShortlist.venues : [];
            return (
              <SingleResult
                key={venue.id}
                venue={venue}
                onClickThrough={() => {
                  this.props.searchClickThrough({
                    venue_id: venue.id,
                    payload_simple: this.props.payload_simple,
                    payload_advanced: this.props.payload_advanced,
                    rank: rank + 1,
                    data_score: venue.data_score,
                  });
                  pushEvent('search_viewvenueclick', {
                    ecommerce: {
                      click: {
                        actionField: {
                          list: 'Search Results',
                        },
                        products: [
                          {
                            id: venue.id,
                            name: venue.name,
                            category: '',
                            position: rank + 1,
                          },
                        ],
                      },
                    },
                  });
                }}
                selected={isSelected}
                onSelect={() => this.toggleShortlist(venue, !isSelected)}
                onEnquiry={() => this.quoteHandler({venue, rank})}
                selectedVenues={selectedVenues}
              />
            );
          })}
          {this.props.results.length > this.state.show &&
          this.state.show < MAX_RESULTS ? (
              <div className="text-center py-5">
            <span
                className="font-bold text-black pointer hover:text-blue hover:underline"
                onClick={() => {
                  this.setState({
                    show: this.state.show + 25,
                  });
                }}
            >
            Load More Results
            </span>
              </div>
          ) : null}
          {this.props.results.length >= MAX_RESULTS &&
          this.state.show === MAX_RESULTS ? (
              <div className="text-center py-5 italic">
                Only the top {MAX_RESULTS} results are shown, please refine your
                search
              </div>
          ) : null}
          {this.state.modal ? (
              <RequestForQuote
                  closeModal={() => {
                    this.setState({
                      modal: false,
                      id: null,
                      name: null,
                    });
                  }}
                  data_score={this.state.data_score}
                  initialValues={{
                    type: 'General',
                    venues: [this.state.id],
                    venues_text: [this.state.name],
                  }}
              />
          ) : null}
        </div>
    );
  }
}

export default connect(
    (state, props) => ({
      isAgent: isAgent(state),
      isLoggedIn: isLoggedIn(state),
      activeShortlist: getFormValues(`${props.type}-search-shortlist`)(state),
      // passing all form values down here is a bit crap as it triggers unnecessary re-renders
      payload_advanced:
          props.type === 'advanced'
              ? getFormValues('advanced-search')(state)
              : undefined,
      payload_simple:
          props.type === 'simple'
              ? getFormValues('simple-search')(state)
              : undefined,
    }),
    { searchClickThrough, searchImpressions, change },
)(Results);
