import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFormValues, initialize } from "redux-form";
import { user as userFromState } from "../../modules/auth";
import { FaSearch } from "react-icons/fa";

class AdvancedSearchButton extends React.Component {
  render() {
    const { isLoggedIn, history, location, initialize } = this.props;

    return (
      <span
        className="uppercase font-normal text-green text-xs font-body pointer"
        onClick={() => {
          if (!isLoggedIn) {
            history.push(
              `/login?return=${encodeURI(location.pathname)}&logInToProceed=1`
            );
            return;
          }

          initialize &&
            initialize("advanced-search", this.props.values, {
              updateUnregisteredFields: true,
            });
          this.props.history.push(
            "/advanced-search?results=13330&back_projection=0&exhibition=0&location_type=Map&radius=24140.16&sortBy=Capacity&types_text=Hotels"
          );
        }}
      >
        Advanced search
        <FaSearch className="ml-2 text-xxs" />
      </span>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const v = getFormValues("advanced-search")(state) || {};
      const user = userFromState(state);
      return {
        values: {
          location_type: "Map",
          back_projection: 0,
          exhibition: 0,
          radius: 15 * 1609.344,
          location: v.location,
          addressType: v.addressType,
          lat: v.lat,
          lng: v.lng,
          sortBy: v.sortBy,
          capacity: v.capacity,
          total_bedrooms: v.total_bedrooms,
          star_rating: v.star_rating,
          types: v.venueType_id ? [v.venueType_id] : undefined,
          types_text: v.venueType_id ? [v.venueType] : undefined,
        },
        isLoggedIn: Boolean(user),
      };
    },
    {
      initialize,
    }
  )(AdvancedSearchButton)
);
