import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { func, object } from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Field, reduxForm, initialize, getFormValues } from "redux-form";
import { ButtonPrimary, ButtonOutlined } from "Components/Button";
import { LiveSearch, Input } from "Components/Form";
import Location from "Components/Search/Location";
import { FaCamera } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import {generalInteraction} from "../modules/analytics";

const form = "simple-search-start";

const SearchBannerNew = ({
  footer,
  capacity,
  destroy,
  handleSubmit,
  initialize,
  history,
  venueOfTheWeek,
  generalInteraction
}) => {
  const [location, setLocation] = useState(null);
  const [name,] = useState(null);
  const [error, setError] = useState(null);
  const [closePanels, setClosePanels] = useState(false);
  const submit = () => {
    if (location) {
      const locationParams = location ? location : {};
      initialize(
        "simple-search",
        {
          ...locationParams,
          sortBy: "ContentUpdate",
          capacity,
          name,
        },
        {
          updateUnregisteredFields: true,
        }
      );
      history.push("/search");
      reset();
    } else {
      setError(true)
    }
  };
  useEffect(() => {
    if (location !== null) {
      generalInteraction({
        type: "general_home_location_click",
        data: location && location.location
      })
    }
  }, [location])

  useEffect(() => {
    setError(false)
  }, [location])

  useEffect(() => {
    if (history.location.pathname === '/') {
      setClosePanels(true)
    }
  }, [])

  const reset = () => {
    destroy(form);
    setLocation(null);
  };
  return (
    <div className={footer ? "py-16" : ""}>
      <form className="edge-home-hero-search-form" onSubmit={handleSubmit(submit)} autoComplete="off">
        {!footer && (
          <h1 className="text-shadow mb-6 md:mb-8 text-white font-bold font-heading text-2rem md:text-6xl">
            Find the perfect venue
          </h1>
        )}
        <div className="xl:flex">
          <div className="col-w-full justify-center md:justify-start xl:col-w-3/5 flex">
            <div className="row w-full">
              <div className="col xl:w-48 xxl:w-80 mb-5 md:mb-3 xl:mb-0">
                <Location
                  formId={form}
                  id="location-search"
                  icon="/img/map-marker.svg"
                  label="Location"
                  labelClassName="sr-only"
                  name="location"
                  placeholder="Location"
                  fieldClassName="w-full mb-0"
                  wrapperClassName="mb-0"
                  noBottom
                  white
                  tall
                  onPlaceFound={(
                    lat,
                    lng,
                    name,
                    { placeArea },
                    addressType
                  ) => {
                    setLocation({
                      location: name,
                      lat: lat,
                      lng: lng,
                      placeArea: placeArea,
                      addressType,
                    });
                  }}
                />
              </div>
              <div className="col xl:w-48 xxl:w-80 mb-5 md:mb-3 xl:mb-0">
                <LiveSearch
                  id="venue-search"
                  formId={form}
                  url={`${window.API}/venues/quick`}
                  onSelect={result => {
                    history.push(`/venue/${result.slug}`);
                  }}
//                  onSelect={result => {
//                    setName(result.name);
//                  }}
                  label="Venue name"
                  labelClassName="sr-only"
                  name="venue"
                  placeholder="Venue name"
                  fieldClassName="w-full"
                  wrapperClassName="mb-0"
                  noBottom
                  white
                  tall
                  getText={item => item.display_name}
                />
              </div>
              <div className="col xl:w-48 mb-5 md:mb-3 xl:mb-0">
                <Field
                  name="capacity"
                  placeholder="No. of Attendees"
                  white
                  tall
                  noBottom
                  className="md:mr-3 lg:mr-5 xl:mr-8"
                  wrapperClassName="mb-0"
                  labelClassName="mb-0"
                  component={Input}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-w-2/5 md:flex xl:justify-end">
            <div className="md:mr-3 text-center xl:text-left flex justify-center md:block">
              <ButtonPrimary
                big
                submit
                classes="w-3/4 md:w-auto md:px-6 text-xl xl:text-base xxl:text-xxl xxl:px-12 font-bold"
                onClick={() => {
                  generalInteraction({
                    type: "general_home_quick_search_click",
                    data: location && location.location
                  })
                }}
              >
                Quick search
              </ButtonPrimary>
            </div>
            <span className="md:mr-3 font-bold text-white justify-center self-center text-xxl block md:inline-block md:text-left text-center my-2 md:my-0 font-heading">
              OR
            </span>
            <div className="text-center xl:text-left">
              <Link
                className="flex justify-center md:block"
                to={`/advanced-search?results=13326&back_projection=0&exhibition=0&${location && location.location ? 'location=' + location.location + '&' : ''}${location && location.lat ? 'lat=' + location.lat + '&' : ''}${location && location.lng ? 'lng=' + location.lng + '&' : ''}location_type=Map&radius=24140.16${capacity ? '&capacity=' + capacity : ''}&closePanels=${closePanels}&sortBy=ContentUpdate`}
              >
                <ButtonOutlined
                  big
                  classes="w-3/4 md:w-auto text-xl xl:text-base xxl:text-xxl border-3 border-dark-green text-grey-darker font-bold"
                >
                  Advanced Search
                </ButtonOutlined>
              </Link>
            </div>
          </div>
        </div>
        {error && (
          <div className="xl:flex">
            <div className="col-w-full justify-center md:justify-start xl:col-w-3/5 flex pt-4">
              <p className="text-white bg-dark-red p-1 mt-2 font-bold">
                Please choose a location or Venue
              </p>
            </div>
          </div>
        )}
      </form>
      {!!venueOfTheWeek && (
        <ScrollLink
          to="venue-of-the-week"
          smooth
          offset={-100}
          className="pointer"
        >
          <div className="hidden sm:flex justify-center items-center mt-8">
            <span className="flex items-center justify-center flex-no-shrink rounded-100 border-2 border-white opacity-75 h-8 w-8 text-lg mr-4">
              <FaCamera className="text-white opacity-75" />
            </span>
            <span className="text-white opacity-75 font-bold text-lg">
              Venue of the week: {venueOfTheWeek.name}
            </span>
          </div>
        </ScrollLink>
      )}
    </div>
  );
};

SearchBannerNew.propTypes = {
  initialize: func.isRequired,
  history: object.isRequired,
};

export default withRouter(
  reduxForm({ form })(
    connect(
      state => ({
        capacity: (getFormValues(form)(state) || {}).capacity,
      }),
      { initialize, generalInteraction }
    )(SearchBannerNew)
  )
);
