import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getFormValues, initialize } from "redux-form";
import { func } from "prop-types";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { savedSearches, deleteSearch, token } from "modules/auth";
import { ButtonSpan } from "Components/Button";
import Table from "Components/Table";
import { createHandleAxiosError } from "../../modules/api-tools";
import Loading from "../Loading";
import { FaRegListAlt, FaRegTrashAlt } from "react-icons/fa";

class SavedSearchesDisplay extends React.Component {
  static propTypes = {
    search: func, // required when placed on a search page
  };

  state = {
    busyId: null,
  };

  loadSearch = s => {
    const data = JSON.parse(s.search_raw);
    this.props.initialize(data.type, data.values, {
      updateUnregisteredFields: true,
    });
    const path = this.props.location.pathname;
    const page = data.type === "simple-search" ? "/search" : "/advanced-search";
    if (path === page) {
      this.props.search();
    } else {
      this.props.history.push(page);
    }
  };

  delete = id => {
    if (window.confirm("Are you sure you want to delete the search")) {
      const { history, location } = this.props;
      const handleAxiosError = createHandleAxiosError({ history, location });
      this.setState({ busyId: id });
      axios
        .delete(`${window.API}/users/me/shortlists/${id}`, {
          headers: { Authorization: `Bearer ${this.props.token}` },
        })
        .then(() => {
          this.props.deleteSearch(id);
        })
        .catch(
          handleAxiosError(() =>
            alert("Sorry there was an error deleting your search")
          )
        )
        .finally(() => this.setState({ busyId: null }));
    }
  };

  render() {
    const { savedSearches } = this.props;
    const { busyId } = this.state;
    return (
      <div>
        {savedSearches.length ? (
          <div>
            {this.props.smallTitle ? (
              <p className="font-small uppercase font-bold text-grey-md mb-3">
                SAVED SEARCHES
              </p>
            ) : (
              <h3 className="uppercase mb-1 text-primary">your searches</h3>
            )}
            <Table
              showHeader={false}
              headers={["name"]}
              data={savedSearches.map(s => ({
                name: () => (
                  <div className="flex justify-between">
                    <button
                      onClick={() => this.loadSearch(s)}
                      className="text-left px-3 font-light"
                    >
                      {s.name}
                    </button>
                    <div className="flex items-center">
                      {s.id === busyId ? (
                        <Loading classes="text-xs" inline />
                      ) : (
                        <Fragment>
                          <ButtonSpan onClick={() => this.loadSearch(s)}>
                            <FaRegListAlt className="text-blue mr-3" />
                          </ButtonSpan>{" "}
                          <ButtonSpan onClick={() => this.delete(s.id)}>
                            <FaRegTrashAlt className="text-warning" />
                          </ButtonSpan>
                        </Fragment>
                      )}
                    </div>
                  </div>
                ),
              }))}
            />
          </div>
        ) : (
          <p>You have no saved searches</p>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state, props) => ({
      searchParams: getFormValues(props.searchForm)(state),
      savedSearches: savedSearches(state),
      token: token(state),
    }),
    { initialize, deleteSearch }
  )(SavedSearchesDisplay)
);
