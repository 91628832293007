import React from "react";
import { func, object, number } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import debounce from "lodash/debounce";
import {
  reduxForm,
  Field,
  getFormValues,
  change,
  initialize,
  destroy,
} from "redux-form";
import { LiveSearch, Input, Select } from "Components/Form";
import Results from "Components/Search/Results";
import Loading from "Components/Loading";
import SearchWidgets from "Components/Search/Widgets";
import search from "./search-thunk";

const form = "location-search";
const defaultState = {
  results: [],
  count: 0,
  loading: false,
  error: null,
};

class SearchForm extends React.Component {
  static propTypes = {
    destroy: func.isRequired,
    change: func.isRequired,
    values: object.isRequired,
    categoryId: number,
  };

  state = defaultState;

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate = prevProps => {
    if (this.props.categoryId !== prevProps.categoryId) {
      this.props.change(form, "venueCategoryId", this.props.categoryId);
      this.props.search(
        this.props.history,
        this.props.match.params.location,
        this.stateSetter
      );
    }
  };

  init = () => {
    new window.google.maps.Geocoder().geocode(
      {
        address: this.props.address,
      },
      response => {
        const placeRaw = Array.isArray(response) && response[0];

        if (!placeRaw) {
          this.setState({
            error: true,
          });
          return;
        }

        const place = JSON.parse(JSON.stringify(placeRaw));
        this.props.change(form, "venueCategoryId", this.props.categoryId);
        this.props.change(form, "lat", place.geometry.location.lat);
        this.props.change(form, "lng", place.geometry.location.lng);
        // const name = place.formatted_address;
        // this.props.change(form, props.name, name);
        this.props.search(
          this.props.history,
          this.props.match.params.location,
          this.stateSetter
        );
      }
    );
  };

  reset = () => {
    this.props.destroy(form);
    this.setState(
      {
        ...defaultState,
      },
      this.init()
    );
  };

  stateSetter = {
    loading: () => this.setState({ loading: true, results: [] }),
    error: (
      error = "Sorry, there was an error when searching. Please check the fields and try again."
    ) => this.setState({ loading: false, error }),
    clear: () => this.setState({ ...defaultState }),
    results: (results, count) => {
      this.setState({
        results,
        count,
        loading: false,
        error: false,
      });
      this.props.setResults(results);
    },
  };

  searchOnBlur = () => {
    this.props.search(
      this.props.history,
      this.props.match.params.location,
      this.stateSetter
    );
  };

  searchOnChange = debounce((e, v, pv, name) => {
    this.props.search(
      this.props.history,
      this.props.match.params.location,
      this.stateSetter,
      {
        [name]: v,
      }
    );
  }, 500);

  submit = () => {
    // nowt
  };

  resultsHasDestinations = () => {
    const { results } = this.state;
    return !!results && results.some(result => result.destination_city);
  };

  sortByOptions() {
    return [
      {
        text: "Sort by Distance",
        value: "Distance",
      },
      {
        text: "Sort by Capacity",
        value: "Capacity",
      },
      {
        text: "Sort by Bedrooms",
        value: "Bedrooms",
      },
      {
        text: "Sort by Meeting Rooms",
        value: "Meeting rooms",
      },
      {
        text: "Sort by Nearest Airport",
        value: "Nearest airport",
      },
    ].filter(Boolean);
  }

  render() {
    return (
      <div className="mb-8">
        <div className="row">
          <div className="col col-w-full lg:col-w-3/4">
            <form
              onSubmit={this.props.handleSubmit(this.submit)}
              autoComplete="off"
              className="relative"
            >
              <div className="px-3 pt-3 bg-primary">
                <p className="text-white font-normal">
                  Refine &amp; filter for even better results
                </p>
                <div className="row">
                  <div className="col col-w-full md:col-w-1/2 xl:col-w-1/4">
                    <LiveSearch
                      formId={form}
                      url={`${window.API}/search/venues/types`}
                      icon="/img/hotel-solid.svg"
                      labelClassName="sr-only"
                      label="Venue type"
                      name="venueType"
                      setId
                      placeholder="TYPE OF VENUE"
                      wrapperClassName="mb-3"
                      onSelect={() => {
                        this.props.search(
                          this.props.history,
                          this.props.match.params.location,
                          this.stateSetter
                        );
                      }}
                      onRemove={() => {
                        this.props.search(
                          this.props.history,
                          this.props.match.params.location,
                          this.stateSetter
                        );
                      }}
                    />
                  </div>
                  <div className="col col-w-full md:col-w-1/2 xl:col-w-1/4">
                    <Field
                      label="Number of delegates"
                      name="capacity"
                      placeholder="DELEGATES"
                      autoComplete="off"
                      //icon="/img/people.svg"
                      labelClassName="sr-only"
                      wrapperClassName="mb-3"
                      component={Input}
                      onChange={this.searchOnChange}
                    />
                  </div>
                  <div className="col col-w-full md:col-w-1/2 xl:col-w-1/4">
                    <Field
                      label="Number of bedrooms"
                      name="total_bedrooms"
                      placeholder="BEDROOMS"
                      //icon="/img/bed.svg"
                      labelClassName="sr-only"
                      wrapperClassName="mb-3"
                      autoComplete="off"
                      component={Input}
                      onChange={this.searchOnChange}
                    />
                  </div>
                  <div className="col col-w-full md:col-w-1/2 xl:col-w-1/4">
                    <Field
                      name="star_rating"
                      label="STAR RATING"
                      labelClassName="sr-only"
                      wrapperClassName="mb-3"
                      component={Select}
                      placeholder="STAR RATING"
                      options={["3", "4", { value: "5", text: "5+" }]}
                      onChange={this.searchOnChange}
                    />
                  </div>
                </div>
              </div>

              <div className="pt-3 sm:flex sm:justify-between">
                <Field
                  name="sortBy"
                  wrapperClassName="w-64 mb-5 sm:mb-0"
                  noPlaceholder
                  options={this.sortByOptions()}
                  labelClassName="sr-only"
                  label="Sort by"
                  placeholder="Sort by..."
                  selectClasses="uppercase"
                  component={Select}
                  onChange={this.searchOnChange}
                />
                <div className="font-normal">
                  Total results: {this.state.count}
                </div>
              </div>
            </form>
            {this.state.loading ? <Loading large /> : null}
            {this.state.error ? (
              <p className="md:mt-8 text-warning">{this.state.error}</p>
            ) : null}
            {this.state.count ? (
              <div>
                <div>
                  <div className="w-full mt-10" id="results">
                    <Results
                      type="simple"
                      results={this.state.results}
                      loading={this.state.loading}
                      error={this.state.error}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="col col-w-full lg:col-w-1/4">
            <SearchWidgets
              results={this.state.results}
              form="simple-search-shortlist"
              searchForm="simple-search"
              search={() =>
                this.props.search(
                  this.props.history,
                  this.props.match.params.location,
                  this.stateSetter
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  reduxForm({
    form,
    initialValues: {
      sortBy: "Distance",
    },
  })(
    connect(
      state => ({
        values: getFormValues(form)(state) || {},
      }),
      { search, change, initialize, destroy }
    )(SearchForm)
  )
);
