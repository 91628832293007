import React from "react";
import classNames from "classnames";
import { string, node, arrayOf, oneOfType, func, bool, object } from "prop-types";
import { Link } from "react-router-dom";

export const defaultClasses = [
  "inline-block",
  "tracking-loose",
  "font-semibold",
  "font-heading",
  "px-6",
  "max-w-full",
  "uppercase",
  "no-underline",
  "flex",
  "text-center",
  "justify-center",
  "items-center",
];

export const smallStyles = {
    transition: "color .2s ease-in-out, background-color .2s ease-in-out",
    height: "30px",
};

export const defaultStyles = {
  transition: "color .2s ease-in-out, background-color .2s ease-in-out",
  height: "40px",
};

export const bigStyles = {
  transition: "color .2s ease-in-out, background-color .2s ease-in-out",
  height: "64px",
};

ButtonBrand.propTypes = {
  Element: oneOfType([string, func, object]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
  withInput: bool,
};

function ButtonBrand({
  Element = "button",
  classes,
  children,
  submit,
  withInput,
  style,
  big,
  small,
  outlined,
  disabled,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  let defaultStyle = [
    "text-white",
    "border-brand",
    "bg-green",
    "hover:bg-dark-green",
    "hover:text-white",
    "visited:bg-dark-green",
  ];
  if (outlined) {
    defaultStyle = [
      "text-green",
      "border-2 border-green",
      "bg-transparent",
      "hover:bg-green",
      "hover:text-white",
    ];
  }
  if (disabled) {
    defaultStyle = ["bg-light-blue-grey", "text-grey-md"];
  }
  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        defaultStyle,
        "hover:shadow-md",
        Element === "a" ? "flex items-center" : ""
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : small ?
            {
                ...smallStyles,
                ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonTransparent.propTypes = {
  Element: oneOfType([string, func]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
};

function ButtonTransparent({
  Element = "button",
  classes,
  children,
  submit,
  onDark = false,
  style,
  disabled,
  disabledText = true,
  big,
    small,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  return (
    <Element
      className={classNames(
        defaultClasses,
        {
          "bg-transparent": true,
          "shadow-md": true,
        },
        disabled && disabledText
          ? "text-grey-md"
          : {
              "text-white": onDark,
              "text-black": !onDark,
            },
        !disabled && {
          "hover:bg-white": onDark,
          "hover:text-black": onDark,
          "hover:bg-black": !onDark,
          "hover:text-white": !onDark,
        },
        Element === "a" && {
          flex: true,
          "justify-center": true,
          "items-center": true,
        },
        classes
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonPrimary.propTypes = {
  Element: oneOfType([string, func, Link]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
};

function ButtonPrimary({
  Element = "button",
  classes,
  children,
  submit,
  style,
  disabled,
  big,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }
  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-white",
        disabled
          ? ["bg-dark-grey"]
          : [
              "border-primary",
              "bg-primary",
              "hover:bg-dark-blue",
              "hover:text-white",
              "hover:border-dark-blue",
            ]
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonPrimaryRed.propTypes = {
    Element: oneOfType([string, func]),
    classes: oneOfType([string, arrayOf(string)]),
    children: node.isRequired,
    submit: bool,
};
function ButtonPrimaryRed({
                           Element = "button",
                           classes,
                           children,
                           submit,
                           style,
                           disabled,
                           big,
                           ...rest
                       }) {
    let type;
    if (submit) {
        type = "submit";
    } else if (Element === "button") {
        type = "button";
    }

    return (
        <Element
            className={classNames(
                defaultClasses,
                classes,
                "text-white",
                disabled
                    ? ["bg-dark-grey"]
                    : [
                        "border-red",
                        "bg-red",
                        "hover:bg-dark-red",
                        "hover:text-white",
                        "hover:border-dark-red",
                    ]
            )}
            style={
                big
                    ? {
                        ...bigStyles,
                        ...style,
                    }
                    : {
                        ...defaultStyles,
                        ...style,
                    }
            }
            type={type}
            disabled={disabled}
            {...rest}
        >
            {children}
        </Element>
    );
}

ButtonBlack.propTypes = {
  Element: oneOfType([string, func, object]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
};

function ButtonBlack({
  Element = "button",
  classes,
  children,
  submit,
  style,
  big,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-white",
        "border-black",
        "bg-black",
        "hover:bg-white",
        "hover:text-black"
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonGrey.propTypes = {
  Element: oneOfType([string, func]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
};

function ButtonGrey({
  Element = "button",
  classes,
  children,
  submit,
  style,
  big,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-dark-grey",
        "bg-grey",
        "hover:bg-black",
        "hover:text-grey"
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonRed.propTypes = {
  Element: oneOfType([string, func]),
  classes: oneOfType([string, arrayOf(string)]),
  children: node.isRequired,
  submit: bool,
};

function ButtonRed({
  Element = "button",
  classes,
  children,
  submit,
  style,
  big,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-white",
        "border-red",
        "bg-red",
        "hover:bg-white",
        "hover:text-red"
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      {...rest}
    >
      {children}
    </Element>
  );
}

ButtonSpan.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
};

function ButtonSpan({ children, onClick, ...rest }) {
  return (
    <span
      role="button"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
      {...rest}
    >
      {children}
    </span>
  );
}

function ButtonOutlined({
  Element = "button",
  classes,
  children,
  submit,
  style,
  type,
  big,
  ...rest
}) {
  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-grey",
        "border-green",
        "bg-transparent",
        "hover:bg-dark-green",
        "hover:text-white",
        "hover:border-dark-green"
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      {...rest}
    >
      {children}
    </Element>
  );
}

function GroupButton({
  Element = "button",
  active,
  children,
  classes,
  ...rest
}) {
  return (
    <Element
      className={`md:text-3xl text-xl font-semibold rounded-sm p-3 ${
        active
          ? "text-white bg-green"
          : "bg-lighter-blue text-blue-light transition hover:text-blue"
      } ${classes || ""}`}
      {...rest}
    >
      {!active && <span>{children}</span>}
      {active && children}
    </Element>
  );
}

function ButtonLink({ Element = "span", children, classes, href, ...rest }) {
  const element = (
    <Element
      className={`text-blue font-body font-bold hover:underline ${classes ||
        ""}`}
      {...rest}
    >
      {children}
    </Element>
  );

  if (href && Element !== "a") {
    return (
      <Link to={href} className="text-blue font-body font-bold hover:underline">
        {element}
      </Link>
    );
  } else if (href) {
    return (
      <Element
        className={`text-blue font-body font-bold hover:underline ${classes ||
          ""}`}
        href={href}
        {...rest}
      >
        {children}
      </Element>
    );
  }

  return element;
}

function ButtonWhite({
  Element = "button",
  classes,
  children,
  submit,
  style,
  big,
  ...rest
}) {
  let type;
  if (submit) {
    type = "submit";
  } else if (Element === "button") {
    type = "button";
  }

  return (
    <Element
      className={classNames(
        defaultClasses,
        classes,
        "text-grey-darker",
        "border-white",
        "bg-white",
        "hover:bg-white",
        "hover:text-black"
      )}
      style={
        big
          ? {
              ...bigStyles,
              ...style,
            }
          : {
              ...defaultStyles,
              ...style,
            }
      }
      type={type}
      {...rest}
    >
      {children}
    </Element>
  );
}

export {
  ButtonSpan,
  ButtonBlack,
  ButtonBrand,
  ButtonGrey,
  ButtonPrimary,
  ButtonTransparent,
  ButtonRed,
  ButtonOutlined,
  GroupButton,
  ButtonLink,
  ButtonWhite,
ButtonPrimaryRed,
};
