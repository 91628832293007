import React from "react";
import { connect } from "react-redux";
import { object, string, func, arrayOf, oneOfType, number } from "prop-types";
import { withRouter } from "react-router-dom";
import { token } from "modules/auth";
import { ButtonBrand, ButtonSpan } from "Components/Button";
import Loading from "Components/Loading";
import FetchError from "Components/FetchError";
import FormModal from "./FormModal";
import hydrate from "./hydrate";
import deleteById from "./delete";

class ApiTableForm extends React.Component {
  static propTypes = {
    match: object.isRequired,
    history: object.isRequired,
    location: object.isRequired,
    token: string.isRequired,
    renderFields: func.isRequired,
    fields: arrayOf(string.isRequired).isRequired,
    fieldsWithText: arrayOf(string.isRequired),
    th: arrayOf(string.isRequired).isRequired,
    td: arrayOf(func.isRequired).isRequired,
    modalWidth: oneOfType([string, number]),
    modalHeight: oneOfType([string, number]),
    childProps: object,
  };

  state = {
    error: false,
    loading: false,
    dataArray: [],
    modal: undefined, // 'unknown' or ID
  };

  componentDidMount() {
    hydrate(this.props, this.stateHelpers);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      typeof prevState.modal !== "undefined" &&
      typeof this.state.modal === "undefined"
    ) {
      hydrate(this.props, this.stateHelpers);
    }
  }

  stateHelpers = {
    complete: dataArray =>
      this.setState({
        loading: false,
        error: false,
        dataArray,
      }),
    failed: () =>
      this.setState({
        error: true,
        loading: false,
        dataArray: [],
      }),
    loading: () =>
      this.setState({
        loading: true,
        dataArray: [],
      }),
  };

  deleteById = id => {
    this.setState({
      loading: true,
      dataArray: [],
    });
    deleteById(
      this.props,
      {
        complete: dataArray =>
          this.setState({
            loading: false,
            error: false,
            dataArray,
          }),
      },
      id
    );
  };

  render() {
    if (this.state.error) {
      return <FetchError />;
    }
    if (this.state.loading) {
      return <Loading large />;
    }
    return (
      <React.Fragment>
        {this.state.dataArray && this.state.dataArray.length ? (
          <table className="edge-table">
            <thead>
              <tr>
                {this.props.th.map(item => (
                  <th key={item}>{item}</th>
                ))}
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.dataArray.map(data => (
                <tr key={data.id}>
                  {this.props.td.map(item => (
                    <td key={item(data)}>{item(data)}</td>
                  ))}
                  <td className="text-right w-32">
                    <ButtonSpan
                      onClick={() => this.setState({ modal: data.id })}
                      className="pointer text-brand font-normal"
                    >
                      Edit
                      <i className="fal fa-edit ml-3" />
                    </ButtonSpan>
                  </td>
                  <td className="text-right w-32">
                    <ButtonSpan
                      className="pointer text-warning"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete this ${this.props.name}?`
                          )
                        ) {
                          this.deleteById(data.id);
                        }
                      }}
                    >
                      Delete
                      <i className="ml-3 fas fa-trash-alt" />
                    </ButtonSpan>
                    {this.state.modal === data.id ? (
                      <FormModal
                        name={this.props.name}
                        fields={this.props.fields}
                        fieldsWithText={this.props.fieldsWithText}
                        renderFields={this.props.renderFields}
                        data={data}
                        width={this.props.modalWidth}
                        height={this.props.modalHeight}
                        closeModalRefresh={data => {
                          this.props.handleResponse &&
                            this.props.handleResponse(data);
                          this.setState({ modal: undefined });
                        }}
                        closeModal={() => {
                          this.setState({ modal: false });
                        }}
                        childProps={this.props.childProps}
                        pluckTheEquipmentIds={this.props.pluckTheEquipmentIds}
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        <div className="text-right">
          <ButtonBrand
            classes="mt-3"
            onClick={() => {
              this.setState({
                modal: "new",
              });
            }}
          >
            Add {this.props.name.replace("_", " ").replace("-", " ")}
          </ButtonBrand>
          {this.state.modal === "new" ? (
            <FormModal
              name={this.props.name}
              fields={this.props.fields}
              fieldsWithText={this.props.fieldsWithText}
              renderFields={this.props.renderFields}
              width={this.props.modalWidth}
              height={this.props.modalHeight}
              closeModalRefresh={() => {
                this.setState({ modal: undefined });
              }}
              closeModal={() => {
                this.setState({ modal: false });
              }}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(state => ({ token: token(state) }))(ApiTableForm)
);
