import React from "react";
import { string, object, oneOfType, oneOf } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Label from "./Label";
import Error from "./Error";

const DateField = ({
  meta: { touched, error, warning },
  input,
  label,
  wrapperClassName,
  labelClassName = '',
  size,
  classes = '',
  datePickerClass = 'admin-datepicker-input',
  ...rest
}) => {
  let wrapperClass = "max-w-full mb-5";
  if (size) {
    wrapperClass += " mr-6 ";
    switch (size) {
      case "sm":
        wrapperClass += "w-32";
        break;
      case "md":
        wrapperClass += "w-64";
        break;
      case "lg":
        wrapperClass += "w-80";
        break;
      default:
        wrapperClass += "";
    }
  }
  const inputDate = input.value ? moment(input.value) : undefined;
  return (
    <div className={wrapperClassName || wrapperClass}>
      <Label name={input.name} text={label} className={labelClassName} />
      <div>
        <DatePicker
          selected={inputDate}
          onChange={input.onChange}
          dateFormat="DD/MM/YYYY"
          className={classes + `${datePickerClass} admin-datepicker-input leading-none p-2 border border-grey-md w-full outline-none font-normal text-black text-xs focus:border-primary bg-white`}
          {...rest}
        />
      </div>
      <Error touched={touched} warning={warning} error={error} />
    </div>
  );
};

DateField.propTypes = {
  input: object.isRequired,
  meta: object.isRequired,
  label: oneOfType([string, object]).isRequired,
  size: oneOf(["sm", "md", "lg"]),
  wrapperClassName: string,
  labelClassName: string,
};

export default DateField;
