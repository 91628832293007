import React from 'react';
import moment from 'moment';
import Modal from 'Components/Modal';
import { ButtonSpan } from 'Components/Button';
import { LabelledText } from 'ui';
import Additional from './Additional';
import getSaving from './get-saving';
import { GBPFormatter } from 'utils';
import ToggleRevealer from './Components/ToggleRevealer';
import BreakdownTable from './Components/BreakdownTable';
import { getVatRateById } from '../../pages/admin/rfp/Confirmation/Helpers/calculateTotals';
import getSingleSaving from "./get-single-saving";

const getTerms = v => {
  switch (v) {
    case 1:
      return 'allocation';
    case 2:
      return 'contract';
    default:
      return '';
  }
};
const getRoomType = v => {
  switch (v) {
    case 1:
      return 'Single';
    case 2:
      return 'Double';
    case 3:
      return 'Twin';
    case 11:
      return 'Suite';
    case 9:
      return 'Family';
    default:
      return '';
  }
};

const getOccupancy = v => {
  switch (v) {
    case 1:
      return '1 person';
    case 2:
      return '2 people';
    case 3:
      return '3 people';
    case 4:
      return '4 people';
    default:
      return '';
  }
};

const AccommodationPriceBreakdownModal = ({
                                            total = {},
                                            attachments,
                                            notes,
                                            onClose,
                                            accommodation,
                                            vatRates = [],
                                          }) => {
  if (vatRates.length < 1) {
    return null;
  }
  return (
      <Modal>
        <div className="modal">
          <div
              className="modal-inner"
              style={{
                width: 1200,
                maxWidth: '90%',
              }}
          >
            <ButtonSpan
                className="m-3 absolute pin-t pin-r z-10"
                onClick={onClose}
            >
              <i className="fas fa-times-circle fa-2x ml-3"/>
            </ButtonSpan>
            <div>
              <h2 className="mb-3 text-3xl">Accommodation</h2>
              {accommodation.map(
                  (accom) => {
                    const {
                      id,
                      venue_bedroom_type_id,
                      arrival_date,
                      no_of_nights,
                      no_of_rooms,
                      occupancy,
                      price_vat_rate_id,
                      price_net,
                      price_vat,
                      price_reduced_from_net,
                      price_reduced_from_vat,
                      price_percentage_saving,
                      term_type_id,
                      breakfast_included,
                      breakfast_price_net,
                      breakfast_price_vat,
                      breakfast_price_vat_rate_id,
                      release_date,
                      cancellation_policy,
                      rooms_allocated,
                      included_in_package,
                    } = accom;
                    const total_net = !included_in_package && price_net && no_of_nights && no_of_rooms
                        ? (price_net * no_of_nights * no_of_rooms).toFixed(2)
                        : null;
                    const total_vat = !included_in_package && price_vat && no_of_nights && no_of_rooms
                        ? (price_vat * no_of_nights * no_of_rooms).toFixed(2)
                        : null;
                    const total_gross = total_net !== null && total_vat !== null ? Number(total_net) + Number(total_vat) : null;
                    return (
                        <div className="mb-5 pt-3 border-t border-black" key={id}>
                          <div className="flex flex-wrap">
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Date of arrival"
                                text={moment(arrival_date).format('ddd Do MMM YYYY')}
                            />
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Number of nights"
                                text={no_of_nights}
                            />
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Number of rooms"
                                text={no_of_rooms}
                            />
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Room type"
                                text={getRoomType(venue_bedroom_type_id)}
                            />
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Occupancy"
                                text={getOccupancy(occupancy)}
                            />
                          </div>
                          <div className="flex flex-wrap">
                            {included_in_package ? (
                                <p>Price included in package</p>
                            ) : (
                                <>
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Cost per room (£)"
                                      text={GBPFormatter.format(price_net)}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Reduced from (£)"
                                      text={GBPFormatter.format(price_reduced_from_net)}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Total (£)"
                                      text={GBPFormatter.format(total_net)}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Cost saving (%)"
                                      text={price_percentage_saving}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Cost saving (£)"
                                      text={getSingleSaving( price_reduced_from_net * no_of_rooms * no_of_nights, total_net)}
                                  />
                                </>
                            )}
                          </div>
                          {!included_in_package &&
                          <div className="flex flex-wrap">
                            <ToggleRevealer toggleText="Show Tax Breakdown">
                              <BreakdownTable
                                  headings={['Price Per Room', 'Reduced From', 'Total']}
                                  vatRate={getVatRateById(vatRates, price_vat_rate_id).number}
                                  rows={[
                                    [
                                      GBPFormatter.format(price_net),
                                      GBPFormatter.format(price_reduced_from_net),
                                      GBPFormatter.format(total_net),
                                    ],
                                    [
                                      GBPFormatter.format(price_vat),
                                      GBPFormatter.format(price_reduced_from_vat),
                                      GBPFormatter.format(total_vat),
                                    ],

                                    [
                                      GBPFormatter.format(Number(price_net) + Number(price_vat)),
                                      GBPFormatter.format(Number(price_reduced_from_net) + Number(price_reduced_from_vat)),
                                      GBPFormatter.format(Number(total_net) + Number(total_vat)),
                                    ],
                                  ]}/>
                            </ToggleRevealer>
                          </div>
                          }
                          <div className="flex flex-wrap">
                            <LabelledText
                                Wrapper="div"
                                className="mr-8"
                                label="Breakfast included"
                                text={breakfast_included ? 'Yes' : 'Not included in quote'}
                            />
                            {!breakfast_included && (
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label="Net Breakfast price/room (£)"
                                    text={`${GBPFormatter.format(breakfast_price_net)}`}
                                />
                            )}
                            {!breakfast_included && (
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8 text-right"
                                    label="Total (£)"
                                    text={`${GBPFormatter.format((breakfast_price_net * no_of_nights * no_of_rooms).toFixed(2))}`}
                                />
                            )}

                          </div>
                          {!breakfast_included && (
                            <div className="flex flex-wrap">
                              <ToggleRevealer toggleText="Show Tax Breakdown">
                                <BreakdownTable
                                    headings={['Breakfast price/room',  'Total']}
                                    vatRate={getVatRateById(vatRates, breakfast_price_vat_rate_id).number}
                                    rows={[
                                      [
                                        GBPFormatter.format(breakfast_price_net),
                                        GBPFormatter.format((breakfast_price_net * no_of_nights * no_of_rooms).toFixed(2)),
                                      ],
                                      [
                                        GBPFormatter.format(breakfast_price_vat),
                                        GBPFormatter.format((breakfast_price_vat * no_of_nights * no_of_rooms).toFixed(2)),
                                      ],

                                      [
                                        GBPFormatter.format(Number(breakfast_price_net) + Number(breakfast_price_vat)),
                                        GBPFormatter.format(((Number(breakfast_price_net) + Number(breakfast_price_vat)) * no_of_nights * no_of_rooms).toFixed(2)),
                                      ],
                                    ]}/>
                              </ToggleRevealer>
                            </div>
                        )}
                          {!included_in_package ? (
                              <div className="flex flex-wrap">
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8"
                                    label="Terms"
                                    text={getTerms(term_type_id)}
                                />
                                {getTerms(term_type_id) === 'allocation' ?
                                    <LabelledText
                                        Wrapper="div"
                                        className="mr-8"
                                        label="Rooms allocated"
                                        text={rooms_allocated}
                                    />
                                : null
                                }
                                {getTerms(term_type_id) === 'allocation' ?
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8"
                                    label="Release date"
                                    text={moment(release_date).format('ddd Do MMM YYYY')}
                                /> : null }
                                <LabelledText
                                    Wrapper="div"
                                    className="mr-8"
                                    label="Cancellation policy"
                                    text={cancellation_policy}
                                />
                              </div>
                          ) : null}
                        </div>
                    );
                  },
              )}
              <div className="mb-5 pt-3 border-t border-black">
                <h3 className="mb-3">Total</h3>
                <div className="flex flex-wrap">
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Total Including VAT (£)"
                      text={GBPFormatter.format(total.total_inc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Total Excluding VAT (£)"
                      text={GBPFormatter.format(total.total_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Reduced From (£)"
                      text={GBPFormatter.format(total.reduced_from_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Cost Saving (%)"
                      text={getSaving(total)}
                  />
                </div>
              </div>
              <Additional attachments={attachments} notes={notes}/>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default AccommodationPriceBreakdownModal;
