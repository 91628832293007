import store from '../../store';

export const ENQUIRY_VENUE_DATA_STATUS = {
  OPEN: 0,
  INITIAL_RESPONSE_RECEIVED: 10,
  CHECKSHEET_DRAFTED: 20,
  CHECKSHEET_AWAITING_VENUE_APPROVAL: 23,
  CHECKSHEET_VENUE_APPROVED: 26,
  CHECKSHEET_DATA_RECEIVED: 40,
  USER_AMENDS: 50,
  VENUE_AMENDS: 51,
  USER_CONFIRMED: 60,
  VENUE_CONFIRMED: 70,
  ALTERNATIVE_VENUE_BOOKED: 96,
  VENUE_OPTED_OUT: 97,
  REMOVED_BY_CLIENT: 98,
  DISQUALIFIED: 99,
};


export const getStatusOptions = () => {
  const options = [];
  for (const [key, value] of Object.entries(ENQUIRY_VENUE_DATA_STATUS)) {
    let statusExists = options.find((option) => option.text === getStatusPrettyName(value));
    if(!statusExists)
    {
      options.push({
        value,
        text: getStatusPrettyName(value)
      })
    }
  }
  return options;
}
//[
//  {text: 'Open', value: 0},
//  {text: 'Initial Response Received', value: 10},
//  {text: 'Checksheet Drafted', value: 20},
//  {text: 'Checksheet Awaiting Venue Approval', value: 23},
//  {text: 'Checksheet Venue Approved', value: 26},
//  {text: 'Checksheet Data Received', value: 40},
//  {text: 'User Confirmed', value: 60},
//  {text: 'Event Booked', value: 70},
//  {text: 'Venue Opted Out', value: 97},
//  {text: 'Disqualified', value: 99}
//];

export const getStatusPrettyName = (status) => {
  let isInternal =
      !!store.getState().auth.role &&
      (!!~store.getState().auth.role.indexOf("super-admin") ||
          !!~store.getState().auth.role.indexOf("trinity") ||
          !!~store.getState().auth.role.indexOf("edge-admin"));
  if(!isInternal) {
    switch (Number(status)) {
      case ENQUIRY_VENUE_DATA_STATUS.OPEN:
        return 'Awaiting Response';
      case ENQUIRY_VENUE_DATA_STATUS.INITIAL_RESPONSE_RECEIVED:
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DRAFTED:
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_AWAITING_VENUE_APPROVAL:
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_VENUE_APPROVED:
        return 'Preparing Proposal'
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED:
        return 'Proposal Received';
      case ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS:
        return 'Awaiting Venue Review';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS:
        return 'Proposal Amended';
      case ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED:
        return 'Awaiting Venue Confirmation';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED:
        return 'Event Booked';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_OPTED_OUT:
        return 'Venue opted out';
      case ENQUIRY_VENUE_DATA_STATUS.DISQUALIFIED:
        return 'Disqualified';
      case ENQUIRY_VENUE_DATA_STATUS.REMOVED_BY_CLIENT:
        return 'Removed By Client';
      case ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED:
        return 'Alternative Venue Booked';
      default:
        return '';
    }
  }else{
    switch (Number(status)) {
      case ENQUIRY_VENUE_DATA_STATUS.OPEN:
        return 'Open';
      case ENQUIRY_VENUE_DATA_STATUS.INITIAL_RESPONSE_RECEIVED:
        return 'Initial response received';
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DRAFTED:
        return 'Checksheet Drafted';
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_AWAITING_VENUE_APPROVAL:
        return 'Awaiting Venue Approval';
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_VENUE_APPROVED:
        return 'Venue Approved';
      case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED:
        return 'Proposal Received';
      case ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS:
        return 'Awaiting Venue Review';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS:
        return 'Proposal Amended';
      case ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED:
        return 'Awaiting Venue Confirmation';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED:
        return 'Event Booked';
      case ENQUIRY_VENUE_DATA_STATUS.VENUE_OPTED_OUT:
        return 'Venue opted out';
      case ENQUIRY_VENUE_DATA_STATUS.DISQUALIFIED:
        return 'Disqualified';
      case ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED:
        return 'Alternative Venue Booked';
      case ENQUIRY_VENUE_DATA_STATUS.REMOVED_BY_CLIENT:
        return 'Removed By Client';
      default:
        return '';
    }
  }

};

export const getStatusText = enquiry_venue_data => {
  if (Array.isArray(enquiry_venue_data) && enquiry_venue_data.length > 0) {
    let firstNonNegativeDatas = enquiry_venue_data.sort((evdA, evdB) => evdB.status - evdA.status).find((datas) => {
      return datas.status < 96
    });
    if (firstNonNegativeDatas) {
      return getStatusPrettyName(firstNonNegativeDatas.status);
    } else {
      return getStatusPrettyName(enquiry_venue_data[0].status)
    }
  } else if (enquiry_venue_data && enquiry_venue_data.status) {
    return getStatusPrettyName(enquiry_venue_data.status);
  } else if (Array.isArray(enquiry_venue_data) && enquiry_venue_data.length === 0) {
    return 'Draft';
  }
  return '';
};

export const getStatusIcon = (status) => {
  switch (Number(status)) {
    case ENQUIRY_VENUE_DATA_STATUS.OPEN:
    case ENQUIRY_VENUE_DATA_STATUS.INITIAL_RESPONSE_RECEIVED:
    case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DRAFTED:
    case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_AWAITING_VENUE_APPROVAL:
    case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_VENUE_APPROVED:
    case ENQUIRY_VENUE_DATA_STATUS.CHECKSHEET_DATA_RECEIVED:
    case ENQUIRY_VENUE_DATA_STATUS.USER_AMENDS:
    case ENQUIRY_VENUE_DATA_STATUS.VENUE_AMENDS:
    case ENQUIRY_VENUE_DATA_STATUS.USER_CONFIRMED:
    case ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED:
    case ENQUIRY_VENUE_DATA_STATUS.ALTERNATIVE_VENUE_BOOKED:
    case ENQUIRY_VENUE_DATA_STATUS.VENUE_OPTED_OUT:
    case ENQUIRY_VENUE_DATA_STATUS.DISQUALIFIED:
    default:
      return '';
  }
};
