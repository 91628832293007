import {
    SALES_SUMMARY_FIGURES_RECEIVED,
    SALES_SUMMARY_FIGURES_RECEIVED_ERROR,
    SALES_SUMMARY_FIGURES_REQUEST,
    SALES_SUMMARY_FIGURES_UNLOAD,
    SALES_SUMMARY_ITEM_RECEIVED, SALES_SUMMARY_ITEM_RECEIVED_ERROR,
    SALES_SUMMARY_ITEM_REQUEST, SALES_SUMMARY_ITEM_UNLOAD,
    SNAPSHOT_FIGURES_RECEIVED,
    SNAPSHOT_FIGURES_RECEIVED_ERROR,
    SNAPSHOT_FIGURES_REQUEST,
    SNAPSHOT_FIGURES_UNLOAD
} from "./types";

export const reportingFigures = (state = {
    snapshotFigures: {},
    salesSummaryFigures: {},
    salesSummaryItems: {},
    isFetching: false,
    error: null,
}, action) => {
    switch (action.type) {
        case SNAPSHOT_FIGURES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case SNAPSHOT_FIGURES_RECEIVED:
            return {
                ...state,
                isFetching: false,
                snapshotFigures: action.data.data
            }
        case SNAPSHOT_FIGURES_RECEIVED_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case SNAPSHOT_FIGURES_UNLOAD:
            return {
                snapshotFigures: {},
                isFetching: false,
                error: null,
            }
        case SALES_SUMMARY_FIGURES_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case SALES_SUMMARY_FIGURES_RECEIVED:
            return {
                ...state,
                isFetching: false,
                salesSummaryFigures: action.data.data
            }
        case SALES_SUMMARY_FIGURES_RECEIVED_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case SALES_SUMMARY_FIGURES_UNLOAD:
            return {
                salesSummaryFigures: {},
                isFetching: false,
                error: null,
            }
        case SALES_SUMMARY_ITEM_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case SALES_SUMMARY_ITEM_RECEIVED:
            return {
                ...state,
                isFetching: false,
                salesSummaryItems: action.data.data
            }
        case SALES_SUMMARY_ITEM_RECEIVED_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case SALES_SUMMARY_ITEM_UNLOAD:
            return {
                salesSummaryItems: {},
                isFetching: false,
                error: null,
            }
        default:
            return state;
    }
}