import { uniqBy, pipe, filter } from "lodash/fp";

// ALL
export const analyticsImage = () => state =>
  state.analytics.image ? state.analytics.image : null;
export const venueData = id => state =>
    state.analytics.venue_data[id] ? state.analytics.venue_data[id] : null;
// export const analyticsImage  = state => {
//   console.log('test');
//   console.log(state);
// }

export const allTypes = id => state => {
  const clicks = searchClicks(id)(state) || [];
  const searchImps = searchImpressions(id)(state) || [];
  const venueViews = venuePageViews(id)(state) || [];
  const interactions = venueInteractions(id)(state) || [];
  return [...clicks, ...searchImps, ...venueViews, ...interactions];
};

// META
export const venueDataMeta = id => state => {
  const data = venueData(id)(state);
  return {
    error: data && data.error,
    fetching: data && data.fetching,
    exists: data && Boolean(data.data),
    start_date: data && data.start_date,
    end_date: data && data.end_date,
  };
};

// CURRENT DATA SCORE
export const currentDataScore = id => state => {
  const data = venueData(id)(state);
  return data ? data.data.current_data_score : null;
};

// SEARCH
export const searchData = id => state =>
  venueData(id)(state) && venueData(id)(state).data
    ? venueData(id)(state).data.search
    : null;
export const searchImpressions = id => state =>
  searchData(id)(state) ? searchData(id)(state).impressions : null;
export const searchClicks = id => state =>
  searchData(id)(state) ? searchData(id)(state).clicks : null;

// VENUE PAGE
export const venuePageData = id => state =>
  venueData(id)(state) && venueData(id)(state).data
    ? venueData(id)(state).data.venue
    : null;
export const venuePageViews = id => state =>
  venuePageData(id)(state) ? venuePageData(id)(state).impressions : null;
export const venueInteractions = id => state =>
  venuePageData(id)(state) ? venuePageData(id)(state).interactions : null;

export const uniqByUuid = pipe(
  filter("uuid"),
  uniqBy("uuid")
);
