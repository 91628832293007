import React, {useEffect, useState} from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Field, reduxForm, getFormValues, SubmissionError } from "redux-form";
import axios from "axios";
import { Input, Select } from "Components/Form";
import { ButtonBlack } from "Components/Button";
import Warning from "Components/Warning";
import Loading from "Components/Loading";
import { required, minLength, email } from "modules/validation";
import parseErrors from "modules/parse-errors";
import { login } from "modules/auth";
import options from "modules/registration-options";
import {fetchIndustries} from "../modules/industries";
import {isArray} from "lodash";

const form = "register";

const v = {
  firstName: required("Please enter your first name"),
  surname: required("Please enter your surname"),
  email: required("Please enter your email address"),
  password: required("Please enter your password"),
  phone: required("Please enter your phone number"),
  user_type: required("Please select your user type"),
  other_user_type: required("Please specify your user type"),
  company_name: required("Please enter your company name"),
  industry_sector: required("Please enter your industry sector"),
  other_industry_sector: required("Please enter your industry"),
  hotel_name_or_group: required("Please enter your hotel/group"),
  min8: minLength(8),
};

const RegistrationForm = ({
  handleSubmit,
  formType,
  submitting,
  userTypeOther,
  login,
  onRegister,
  industries
}) => {
  const [registerError, setRegisterError] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIndustries())
  }, [dispatch])

  const industriesOptions = [];
  if (undefined !== industries && isArray(industries) && industries.length) {
    industries.map(industry => {
      industriesOptions.push({
        text: industry.data.name,
        value: industry.data.id
      })
    })
  }

  const handleRegister = values => {
    /* Set userTypeId value based on the form */
    const params = { ...values };

    if (formType === "agency") {
      params["user_type"] = 2;
      params["industry_sector"] = "Agency";
    } else if (formType === "corporate") {
      params["user_type"] = 1;
    } else if (formType === "venue") {
      params["user_type"] = 6;
    }

    return axios
      .post(`${window.API}/auth/register`, params)
      .then(({ data }) => {
        login({
          token: data.access_token,
          role: data["roles-string"],
          venues: data.venues,
          user: data.user,
        });

        // the server sets user as corporate when registering as an agent, we need to hack this in for GA
        const isAgent = values.user_type === "2";
        onRegister && onRegister(isAgent);
      })
      .catch(err => {
        const errors = parseErrors(err);
        if (errors) {
          throw new SubmissionError({
            ...errors,
            _error: "Registration failed",
          });
        } else {
          setRegisterError(
            "Sorry there was a problem registering. Please try again."
          );
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(handleRegister)} noValidate>
      <Field
        name="first_name"
        label="First name"
        white
        validate={v.firstName}
        component={Input}
      />
      <Field
        name="surname"
        label="Surname"
        white
        validate={v.surname}
        component={Input}
      />
      {/* AGENCY */}
      {formType === "agency" ? (
        <React.Fragment>
          <Field
            name="company_name"
            label="Agency Name"
            white
            validate={v.company_name}
            component={Input}
          />
        </React.Fragment>
      ) : null}
      {/* CORPORATE */}
      {formType === "corporate" ? (
        <React.Fragment>
          <Field
            name="company_name"
            label="Company Name"
            white
            validate={v.company_name}
            component={Input}
          />
          <Field
            name="industry_sector"
            label="Industry Type"
            white
            validate={v.industry_sector}
            options={industriesOptions}
            component={Select}
          />
        </React.Fragment>
      ) : null}
      {/* OTHER */}
      {formType === "other" ? (
        <React.Fragment>
          <Field
            name="user_type"
            label="User Type"
            white
            validate={v.user_type}
            options={options.userTypeOther}
            component={Select}
          />
          {userTypeOther ? (
            <Field
              name="other_user_type"
              label="Please specify"
              white
              validate={v.other_user_type}
              component={Input}
            />
          ) : null}
        </React.Fragment>
      ) : null}
      {/* VENUE */}
      {formType === "venue" ? (
        <React.Fragment>
          <Field
            name="hotel_name_or_group"
            label="Venue / Hotel Group Name"
            white
            validate={v.hotel_name_or_group}
            component={Input}
          />
        </React.Fragment>
      ) : null}
      <Field
        name="phone"
        label="Phone"
        white
        validate={v.phone}
        component={Input}
      />
      <Field
        name="email"
        type="email"
        label="Email address"
        white
        validate={[v.email, email]}
        component={Input}
      />
      <Field
        name="password"
        type="password"
        label="Password"
        white
        validate={(v.password, v.min8)}
        component={Input}
        right={() => (
          <ButtonBlack type="submit">
            {submitting ? <Loading inline /> : "Register"}
          </ButtonBlack>
        )}
      />
      <Warning className="mt-3">{registerError}</Warning>
    </form>
  );
};

export default withRouter(
  reduxForm({
    form,
  })(
    connect(
      state => {
        const values = getFormValues(form)(state) || {};
        return {
          ...state.industries,
          userTypeOther: values.user_type === "5",
        };
      },
      { login }
    )(RegistrationForm)
  )
);
