import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import nthOccurrence from "modules/nth-occurance";
import Input from "Components/Form/Input";

const ArticleSectionAltPaginated = ({
  h1,
  title,
  articles: raw,
  indexLink,
  id,
  feature2nd,
  brand,
  perPage = 10,
}) => {
  const [term, setTerm] = useState("");

  const articles = raw
    .filter(article => {
      return term
        ? article.title.toLowerCase().includes(term.toLowerCase()) ||
            (article.content &&
              article.content.toLowerCase().includes(term.toLowerCase()))
        : true;
    })
    .map(a => {
      const lastSpaceIndex = nthOccurrence(a.title, " ", 7);
      let title = a.title;
      if (~lastSpaceIndex) {
        title = a.title.substring(0, lastSpaceIndex) + "...";
      }
      return {
        ...a,
        title,
      };
    });

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(articles.length / perPage);
  const latest = (page - 1) * perPage;
  const start = latest + 1;
  const end = page * perPage;

  const handleGoTo = num => setPage(num);

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const latestArticle = articles[latest];
  const others = articles.slice(start, end);

  const H = h1 ? "h1" : "h2";

  return (
    <section className="pb-8" id={id}>
      {title && (
        <H
          className={`text-xl sm:flex justify-between items-center uppercase text-white bg-${
            brand ? "brand" : "black"
          } p-2 mb-5`}
        >
          {indexLink ? (
            <Link className="text-white" to={indexLink}>
              {title}
            </Link>
          ) : (
            [title]
          )}
          <Input
            input={{
              onChange: e => setTerm(e.target.value),
              value: term,
            }}
            labelClassName="sr-only"
            iconRight="/img/search-icon.svg"
            meta={{}}
            name="term"
            label={`Search ${title}`}
            placeholder={`Search ${title}`}
            wrapperClassName="mb-0 mt-2 w-full sm:mt-0 sm:w-64"
          />
        </H>
      )}
      {articles.length ? (
        <div className="row-tiny">
          {latestArticle && (
            <div
              className={`mb-2 col-tiny col-w-full sm:col-w-1/2 md:col-w-full xl:col-w-1/2 md:h-48 xl:h-64 ${
                feature2nd ? "xl:order-2" : "xl:order-1"
              }`}
            >
              <Link
                to={latestArticle.link}
                target="_blank"
                className="md:flex bg-brand text-white block w-full h-full"
              >
                <div
                  className="h-24 md:h-full md:w-2/3 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${latestArticle.top_image ||
                      latestArticle.img.replace('localapi','api').replace('stage-api','api')}?raw)`,
                  }}
                />
                <div className="md:w-1/3 p-3">
                  <h3 className="font-body font-normal text-base md:text-xl leading-normal">
                    {latestArticle.title}
                  </h3>
                </div>
              </Link>
            </div>
          )}
          {others.map((article, i) => {
            let order = feature2nd && !i ? 1 : i + 2;
            if (order > 12) order = 12; // tailwind order utils only go to 12 (no order positions before 1 in CSS!!!)
            return (
              <div
                key={article.title}
                className={`mb-2 col-tiny col-w-full sm:col-w-1/2 md:col-w-1/3 xl:col-w-1/6 xl:order-${order} xl:h-64 overflow-hidden`}
              >
                <Link
                  to={article.link}
                  target="_blank"
                  className="bg-black text-white block w-full h-full"
                >
                  <div
                    className="h-24 w-full bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${article.top_image ||
                        article.img.replace('localapi','api').replace('stage-api','api')}?raw)`,
                    }}
                  />
                  <h3 className="font-body font-normal text-base leading-normal p-3">
                    {article.title}
                  </h3>
                </Link>
              </div>
            );
          })}
        </div>
      ) : null}
      {pages.length > 1 && (
        <div className="text-center">
          <span
            className={classNames("mr-2 pointer", { invisible: page === 1 })}
            onClick={() => handleGoTo(page - 1)}
          >
            &lt; PREVIOUS
          </span>
          {pages.map(pageNumber => {
            return (
              <React.Fragment key={pageNumber}>
                <span
                  className={classNames("hidden md:inline mr-2 pointer", {
                    "font-bold": pageNumber === page,
                  })}
                  onClick={() => handleGoTo(pageNumber)}
                >
                  {pageNumber}
                </span>
              </React.Fragment>
            );
          })}
          <span
            className={classNames("pointer", {
              invisible: page === totalPages,
            })}
            onClick={() => handleGoTo(page + 1)}
          >
            NEXT &gt;
          </span>
        </div>
      )}
    </section>
  );
};

export default ArticleSectionAltPaginated;
