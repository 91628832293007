import React from 'react';

const BreakdownTable = ({ headings, vatRate = false, rows = [] }) => {
  const colHeadings = ['Net', `Vat${!!vatRate ? ` (@ ${vatRate}%)` : ''}`, 'Total'];
  return (
      <table className="table-fixed bg-light-grey text-xs uppercase mb-4">
        <thead>
        <tr className="font-heading">
          <th className="w-1/5 font-normal"/>
          {headings.map(heading => (<th className='w-1/5 font-normal text-right p-1' key={heading}>{heading}</th>))}
        </tr>
        </thead>
        <tbody>
        {colHeadings.map((heading, i) => {
          return (
              <tr key={heading}>
                <th className='font-heading font-normal text-left p-1' >{heading}</th>
                {rows[i] && rows[i].map(val => (<td className='text-right p-1 text-sm' key={heading + val}>{val}</td>))}
              </tr>
          );
        })}
        </tbody>
      </table>
  );
};

export default BreakdownTable;
