import axios from 'axios';
import {
  VENUES_LIST_REQUEST, VENUES_LIST_RECEIVED_REQUEST,
  VENUES_LIST_RECEIVED_ERROR,
  VENUES_LIST_UNLOAD
} from "../reducers/types";

export const venuesListRequest = () => ({
  type: VENUES_LIST_REQUEST
});

export const venuesListReceivedRequest = data => ({
  type: VENUES_LIST_RECEIVED_REQUEST,
  data
});

export const venuesListReceivedError = error => ({
  type: VENUES_LIST_RECEIVED_ERROR,
  error
});

export const venuesListUnload = () => ({
  type: VENUES_LIST_UNLOAD
})

export const fetchVenues = ids => {
  return (dispatch) => {
    dispatch(venuesListRequest());
    return axios.get(`${window.NODE_AS}/compare`, {
      params: {
        venues: ids,
      }
    })
      .then(response => dispatch(venuesListReceivedRequest(response.data)))
      .catch(error => dispatch(venuesListReceivedError(error.data)))
  }
}
