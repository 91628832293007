import React, {useEffect, useState} from 'react';
import {corporateDataCapture as v} from './validate';
import {Field, getFormValues} from 'redux-form';
import {connect, useSelector} from 'react-redux';
import {token} from '../../modules/auth';
import {Select} from "../../ui";
import {
  corporateAccountBookingDesksUnload,
  corporateDepartmentsUnload,
  fetchCorporateBookingDesks, fetchCorporateAccountUsers,
  fetchCorporateDepartments, unloadCorporateAccountUsers
} from "../../redux/actions";

const mapStateToProps = state => {
  return ({
    token: token(state),
    ...state.corporateBookingDesks,
    ...state.corporateAccountUsers,
    ...state.corporateDepartments
  })
};

const mapDispatchToProps = {
  fetchCorporateBookingDesks,
  corporateAccountBookingDesksUnload,
  fetchCorporateDepartments,
  corporateDepartmentsUnload,
  fetchCorporateAccountUsers,
  unloadCorporateAccountUsers
}

const CorporateSpecificEnquiryData = (props) => {
  const [bookingDesk, setBookingDesk] = useState(null)
  const [department, setDepartment] = useState(null)
  const [meetingHost, setMeetingHost] = useState(null)
  const values = useSelector(getFormValues(props.form));
  useEffect(() => {
    if (props.corporateAccount && props.corporateAccount.id) {
      props.fetchCorporateBookingDesks(props.corporateAccount.id, props.token);
      props.fetchCorporateDepartments(props.corporateAccount.id, props.token);
      props.fetchCorporateAccountUsers(props.corporateAccount.id, props.token);
    }
  }, []);

  // useEffect(() => {
  //   if (!values.department_id) {
  //     if (values.meeting_host_id && props.corporateAccountUsers && props.corporateAccountUsers.users) {
  //       let meetingHost = props.corporateAccountUsers.users.filter((user) => {
  //         return user.user_id == values.meeting_host_id
  //       })[0];
  //       props.change(props.form, 'department_id', meetingHost.department);
  //     }
  //   }
  // }, [values.meeting_host_id]);

  useEffect(() => {
    if (!props.corporateAccount && props.company && props.company.corporate_account_id) {
      props.fetchCorporateBookingDesks(props.company.corporate_account_id, props.token);
      props.fetchCorporateDepartments(props.company.corporate_account_id, props.token);
      props.fetchCorporateAccountUsers(props.company.corporate_account_id, props.token);
    }
    return () => {
      props.corporateAccountBookingDesksUnload();
      props.corporateDepartmentsUnload();
      props.unloadCorporateAccountUsers();
    }
  }, [props.company]);

  const getBookingDesksOptions = () => {
    let options = [];
    if (props.corporateBookingDesks && props.corporateBookingDesks.length > 0) {
      props.corporateBookingDesks.map(corporateBookingDesk => {
        options.push({
          text: corporateBookingDesk.name,
          value: corporateBookingDesk.id
        })
      })
    }
    return options;
  }

  const getDepartmentsOptions = () => {
    let options = [];
    if (props.corporateDepartments && props.corporateDepartments.length > 0) {
      props.corporateDepartments.map(department => {
        options.push({
          text: department.name,
          value: department.id
        })
      })
    }
    return options;
  }

  const getMeetingHostsOptions = () => {
    let options = [];
    if (props.corporateAccountUsers && props.corporateAccountUsers.users && props.corporateAccountUsers.users.length > 0) {
      props.corporateAccountUsers.users.map(user => {
        options.push({
          text: user.first_name + " " + user.surname + " (" + user.email + ")",
          value: user.id
        })
      })
    }
    return options;
  }

  const handleMeetingHostSelection = (meetingHost) => {
    props.corporateAccountUsers.users.map(user => {
      if (meetingHost == user.id && user.department != null) {
        setDepartment(user.department)
      }
    })
    setMeetingHost(meetingHost)
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col col-w-1/4">
          {getBookingDesksOptions().length > 0 && (
            <Field
              name="booking_desk_id"
              label="Booking Desk"
              component={Select}
              options={getBookingDesksOptions()}
              onChange={e => setBookingDesk(e.target.value)}
              // validate={v.booking_desk_id}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col col-w-1/4">
          <Field
            name="meeting_host_id"
            label="Meeting Host"
            component={Select}
            options={getMeetingHostsOptions()}
            onChange={e => {
              handleMeetingHostSelection(e.target.value)
              if (values.meeting_host_id && props.corporateAccountUsers && props.corporateAccountUsers.users) {
                let meetingHost = props.corporateAccountUsers.users.filter((user) => {
                  return user.user_id == values.meeting_host_id
                })[0];
                props.change(props.form, 'department_id', meetingHost.department);
              }
            }}
            wrapperClassName="inline mb-8 xl:w-1/2"
          />
        </div>
        <div className="col col-w-1/4">
          {getDepartmentsOptions().length > 0 && (
            <Field
              name="department_id"
              label="Department"
              component={Select}
              options={getDepartmentsOptions()}
              onChange={e => setDepartment(e.target.value)}
              wrapperClassName="inline mb-8 xl:w-1/2"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorporateSpecificEnquiryData);
