import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, number, arrayOf, shape } from "prop-types";
import { ButtonBrand } from "Components/Button";
import RequestForQuote from "Components/RequestForQuote";
import { venueInteraction } from "modules/analytics";
import { isLoggedIn } from "modules/auth";
import MeetingRoomsModal from "./MeetingRoomsModal";
import { pushEvent } from "../../modules/google-tag-manager";
import {
  FaHandshake,
  FaBed,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from "react-icons/fa";
import Gallery from "./Gallery";
import { getWidth } from "redux-window";
import TechnicalSpecificationDownload from "./TechnicalSpecificationDownload";
import {VENUE_TAGS} from "../../modules/analytics/tags";

class MeetingRooms extends React.Component {
  static propTypes = {
    anchor: string.isRequired,
    featuredImg: string,
    totalBedrooms: number.isRequired,
    totalMeetingRooms: number.isRequired,
    meetingRooms: arrayOf(
      shape({
        name: string.isRequired,
        id: number.isRequired,
        max_capacity_theatre: number,
        max_capacity_classroom: number,
        max_capacity_boardroom: number,
        max_capacity_ushape: number,
        max_capacity_cabaret: number,
        max_capacity_dining: number,
        max_capacity_exhibition: number,
      })
    ),
  };

  state = {
    modalOpen: false,
    quoteModalOpen: false,
    expandedTable: false,
    expandedView: false,
  };

  toggleTable = () => {
    if (!this.state.expandedTable) {
      this.props.venueInteraction({
        type: VENUE_TAGS.VIEW_MEETING_ROOMS.SHOW_ALL_MEETING_ROOMS,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });
    }
    this.setState({
      expandedTable: !this.state.expandedTable,
    });
  };



  toggleQuoteModal = () => {
    if (!this.props.isLoggedIn) {
      this.props.history.push(
        `/login?return=${encodeURI(
          this.props.location.pathname
        )}&logInToProceed=1`
      );
      return;
    }
    if (!this.state.quoteModalOpen) {
      this.props.venueInteraction({
        type: VENUE_TAGS.WEB_ENQUIRIES.ENQUIRE_VIA_MEETING_ROOMS,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });

      pushEvent("enquire_venue_mainbody");
    }
    this.setState({
      quoteModalOpen: !this.state.quoteModalOpen,
    });
  };

  openModal = id => {
    if (!this.state.modalOpen) {
      this.props.venueInteraction({
        type: VENUE_TAGS.VIEW_MEETING_ROOMS.VIEW_MEETING_ROOM,
        id: this.props.venueId,
        impression_id: this.props.impression_id,
        data_score: this.props.data_score,
      });
    }
    this.setState({
      modalOpen: id,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  toggle = () => {
    this.setState({
      expandedView: !this.state.expandedView,
    });
  };

  render() {
    const {
      anchor,
      featuredImg,
      totalBedrooms,
      totalMeetingRooms,
      meetingRooms,
      mobile,
    } = this.props;

    const visibileRows = this.state.expandedTable
      ? meetingRooms
      : meetingRooms.slice(0, 4);
    return (
      <React.Fragment>
        <span className="anchor" id={anchor}>
          Meeting rooms section
        </span>
        <section
          className={`bg-white text-lg mb-3 shadow ${
            !mobile ? "py-4 px-3" : ""
          }`}
        >
          {!mobile ? (
            <div className="flex justify-between">
              <h2
                className={`text-xl ${mobile &&
                  "mb-3 py-4 px-3 text-xl border-b border-light-blue-grey flex items-center justify-between"}`}
                onClick={this.toggle}
              >
                Meeting rooms
                {mobile && !this.state.expandedView && (
                  <FaRegPlusSquare className="text-base" />
                )}
                {mobile && this.state.expandedView && (
                  <FaRegMinusSquare className="text-base" />
                )}
              </h2>

              <div className="flex justify-end">
                <div className="flex items-center mx-4">
                  <span className="uppercase text-grey-md font-bold text-xs mr-3">
                    Meeting rooms
                  </span>
                  <div>
                    <FaHandshake className="mr-2 text-2xl" />
                  </div>
                  <div>
                    <span className="font-heading">{totalMeetingRooms}</span>
                  </div>
                </div>
                <div className="flex items-center mx-4">
                  <span className="uppercase text-grey-md font-bold text-xs mr-3">
                    Bedrooms
                  </span>
                  <div>
                    <FaBed className="mr-2 text-2xl" />
                  </div>
                  <div>
                    <span className="font-heading">{totalBedrooms}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h2
              className={`text-xl ${mobile &&
                "mb-3 py-4 px-3 text-xl border-b border-light-blue-grey flex items-center justify-between"}`}
              onClick={this.toggle}
            >
              Meeting rooms
              {!this.state.expandedView && (
                <FaRegPlusSquare className="text-base" />
              )}
              {this.state.expandedView && (
                <FaRegMinusSquare className="text-base" />
              )}
            </h2>
          )}

         <TechnicalSpecificationDownload technicalSpec={this.props.technicalSpec }/>

          {(!mobile || (mobile && this.state.expandedView)) && (
            <div className="px-3 lg:px-0 py-4 lg:pt-6">
              {!!this.props.floorplan && (
                <a
                  href={`${window.CDN_URL}/${ this.props.floorplan.path.replace(/\/\//g, "/")}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-edge-blue uppercase text-sm pointer font-bold">
                    Read more about this venue site
                  </span>
                </a>
              )}
              {featuredImg && (
                <img
                  src="http://edgevenues.com/<?=$data->meetingRoomsImg?>"
                  alt="Main meeting rooms"
                  className="mb-5"
                />
              )}
              <div className="w-full overflow-x-scroll xl:overflow-x-hidden mt-2 mb-2">
                <table className="meeting-rooms-table text-base">
                  <thead>
                    <tr>
                      <th className="font-normal text-left">Room Name</th>
                      <th></th>
                      <th className="font-normal">Floor Level</th>
                      <th className="font-normal">Square Metres</th>
                      <th className="font-normal">Theatre</th>
                      <th className="font-normal">Classroom</th>
                      <th className="font-normal">Boardroom</th>
                      <th className="font-normal">U-Shape</th>
                      <th className="font-normal">Cabaret</th>
                      <th className="font-normal">Dining</th>
                    </tr>
                  </thead>
                  <tbody>
                    {visibileRows.map((room, i) => (
                      <tr key={room.id} className="font-bold">
                        <td
                          className="text-edge-blue pointer font-bold"
                          onClick={() => this.openModal(room.id)}
                        >
                          {room.name}
                        </td>
                        <td className="flex">
                          {room.image && (
                            <Gallery images={[`${room.image.path}`]} small />
                          )}
                          {/* <RoundedIcon name="video" size={24} classes="mr-2" />
                            <RoundedIcon name="rotate" size={24} /> */}
                        </td>
                        <td className="text-center">
                          {room.floor || "-"}
                        </td>
                        <td className="text-center">
                          {room.width || "-"} x {room.length || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_theatre || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_classroom || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_boardroom || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_ushape || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_cabaret || "-"}
                        </td>
                        <td className="text-center">
                          {room.max_capacity_dining || "-"}
                        </td>
                        {this.state.modalOpen === room.id && (
                          <MeetingRoomsModal
                            closeModal={this.closeModal}
                            room={room}
                          />
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                <div className="flex flex-wrap">
                  {meetingRooms.length > 4 && (
                  <ButtonBrand
                    outlined
                    onClick={this.toggleTable}
                    classes="md:text-lg w-full lg:w-auto mb-2 lg:mb-0 border-4 rounded-sm mr-2"
                  >
                    {this.state.expandedTable ? (
                      <span>Hide meeting rooms</span>
                    ) : (
                      <span>Show all meeting rooms</span>
                    )}
                  </ButtonBrand>
                  )}
                  <ButtonBrand
                    classes="w-full md:w-auto md:px-12"
                    onClick={() => {
                      this.props.venueInteraction({
                        id: this.props.venueId,
                        type: VENUE_TAGS.WEB_ENQUIRIES.ENQUIRE_VIA_MEETING_ROOMS
                      })
                      this.props.history.push(
                          `/request-for-proposal?venues=${this.props.venueId}`
                      );
                    }}
                  >
                    Book Now
                  </ButtonBrand>
                  {this.state.quoteModalOpen ? (
                    <RequestForQuote
                      closeModal={this.toggleQuoteModal}
                      impression_id={this.props.impression_id}
                      data_score={this.props.data_score}
                      initialValues={{
                        type: "General",
                        venues: [this.props.venueId],
                        venues_text: [this.props.venueName],
                      }}
                    />
                  ) : null}
                </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      isLoggedIn: isLoggedIn(state),
      mobile: getWidth(state) < 992,
    }),
    { venueInteraction }
  )(MeetingRooms)
);
