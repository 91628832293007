import React from "react";
import { Link } from "react-router-dom";

const agencyOverlayImage =
  "https://cms.edgevenues.com/wp-content/uploads/2018/11/agency_edge_overlay.png";

const renderArticleImage = (imageUrl, isAgencyType) => {
  return imageUrl ? (
    <div
      style={{
        height: 94,
        width: 94,
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {isAgencyType && (
        <div
          style={{
            height: 94,
            width: 94,
            backgroundImage: `url(${agencyOverlayImage})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      )}
    </div>
  ) : isAgencyType ? (
    <div
      style={{
        height: 94,
        width: 94,
        background: `#22292f url(${agencyOverlayImage}) no-repeat center`,
      }}
    />
  ) : (
    <div
      style={{
        height: 94,
        width: 94,
        background: `url(/img/logo.png) no-repeat center`,
        backgroundSize: "contain",
      }}
    />
  );
};

export const NewsArticle = ({
  imageUrl,
  title,
  excerpt,
  linkTo,
  isAgencyType = false,
  linkState,
}) => {
  return (
    <Link
      to={{
        pathname: linkState.post.acf ? `the-word${linkTo}` : linkTo,
        state: linkState,
      }}
      className="block h-full"
    >
      <div
        className="flex flex-col sm:flex-row mb-1 p-2 text-black h-full"
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="mr-2">{renderArticleImage(imageUrl, isAgencyType)}</div>
        <div>
          <h4
            className="mt-1 mb-1 text-sm"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="text-xs">
            <span dangerouslySetInnerHTML={{ __html: excerpt }} />{" "}
            <span
              className="font-heading font-bold uppercase text-xs text-brand"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Read more
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
