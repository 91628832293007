import React, {useEffect, useState} from "react";
import {fetchUserInvite, registerInvitedUser, userInviteUnload} from "../../redux/actions/corporateInvites";
import {token} from "../../modules/auth";
import {connect} from 'react-redux'
import Loading from "../../Components/Loading";
import NotFound from "../NotFound";
import {isLoggedIn} from "../../modules/auth";
import UserInvitedForm from "../../Components/corporate/UserInvitedForm";
import {useHistory} from "react-router-dom";

const mapStateToProps = (state) => {
  return ({
    ...state.userInvite,
    ...state.isFetchingUserInvite,
    token: token(state),
    isLoggedIn: isLoggedIn(state),
  })
}

const mapDispatchToProps = {
  fetchUserInvite,
  userInviteUnload,
  registerInvitedUser
}

const CompanyLandingPage = ({
                              match,
                              fetchUserInvite,
                              isFetchingUserInvite,
                              userInvite,
                              userInviteUnload,
                              isLoggedIn,
                              registerInvitedUser
                            }) => {
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useState(false)
  useEffect(() => {
    if (isLoggedIn) {
      setLoggedIn(true)
    }
    fetchUserInvite(match.params.invite_code)
    return () => {
      userInviteUnload();
    }
  }, []);
  if (userInvite === null && isFetchingUserInvite) {
    return <Loading/>
  }
  if (userInvite === null && !isFetchingUserInvite || loggedIn) {
    return (
      <NotFound>
        Sorry, the page your are looking for has not been found.
        Please check the URL and try again.
      </NotFound>
    )
  }
  const onSubmit = formValues => {
    return registerInvitedUser(userInvite.invite_code, formValues).then(() => {
      history.push({
        pathname: '/login',
        state: {
          message: "You have successfully registered. Please login."
        }
      });
    })
  }
  return (
    <div className="flex justify-center md:py-16">
      <div className="bg-white max-w-full" style={{width: 900}}>
        <div className="branding text-center">
          <h2 className="mt-0 mb-8 md:mb-8 text-2rem">You are invited to create an account
            with {userInvite && userInvite.company.name}</h2>
        </div>
        <UserInvitedForm
          userInvite={userInvite}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLandingPage)
