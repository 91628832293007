import axios from "axios";
import { useReducer, useEffect } from "react";

const CancelToken = axios.CancelToken;

const initialState = {
  loading: true,
  error: false,
  data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      return {
        ...state,
        loading: true,
        error: false,
      };

    case "ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "RESPONSE":
      return {
        loading: false,
        error: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

const defaults = source => ({
  cancelToken: source.token,
});

const useAxios = (options = {}, trigger = true) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async ({ source }) => {
    dispatch({
      type: "FETCH",
    });

    try {
      const { data } = await axios({
        ...defaults(source),
        ...options,
      });

      dispatch({
        type: "RESPONSE",
        payload: data,
      });
    } catch (error) {
      if (axios.isCancel(error)) return;

      dispatch({
        type: "ERROR",
        payload: error,
      });
    }
  };

  useEffect(() => {
    const source = CancelToken.source();
    if (trigger) {
      getData({ source });
    }

    return () => source.cancel("Request cancelled");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return { ...state };
};

export default useAxios;
