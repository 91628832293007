import React, { useEffect, useState } from 'react';
import { Input, Toggle } from '../../Components/Form';
import { additionalDataCapture as v } from './validate';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { token } from '../../modules/auth';
import { LiveSearchSimple } from '../../Components/Form/LiveSearch/LiveSearchSimple';
import { createCompany } from '../../redux/actions';
import { ButtonPrimary } from '../../Components/Button';
import * as ROUTES from '../../Router/corporate_routes';

const mapStateToProps = state => ({
  token: token(state),
});

const mapDispatchToProps = {
  createCompany,
};

const AgencySpecificEnquiryData = ({
                                     setShowNewCompany,
                                     setShowNewUser,
                                     setCompany,
                                     company,
                                     setClientName,
                                     addCompany,
                                     addClient,
                                     client,
                                     setNewCompanyModal,
                                     setNewUserModal,
                                     formValues,
                                     shouldClearSearches = false,
                                     setShouldClearSearches,
                                     editEnquiry = false
                                   }) => {
  const [companyResultCount, setCompanyResultCount] = useState(false);
  useEffect(() => {
    addCompany();
    addClient();
    if (shouldClearSearches) {
//       setCompany(null)
//       setClientName(null)
      setShouldClearSearches(false);
    }
  }, [company, client, shouldClearSearches]);

  let companySearch = window.API + ROUTES.API.COMPANIES.SEARCH;
  if (formValues.is_edge_enquiry) {
    companySearch = window.API + ROUTES.API.COMPANIES.SEARCH_EDGE;
  } else if (formValues.is_trinity_enquiry) {
    companySearch = window.API + ROUTES.API.COMPANIES.SEARCH_TRIN;
  }

  const clearSearchFields = () => {
    setCompany(null);
    setClientName(null);
  };

  return (
      <>
        <div className="row">
          <div className="col col-w-1/3">
            {!editEnquiry && (
              <LiveSearchSimple
                auth
                label="Search Company"
                getText={result => result.name}
                url={companySearch}
                onChange={setCompany}
                onNoResults={result => setShowNewCompany(result)}
                onClear={() => clearSearchFields()}
                setResultCount={setCompanyResultCount}
                shouldClearSearches={shouldClearSearches}
              />
            )}
            {editEnquiry && company && (
              <p>
                <strong>{company.name}</strong>
              </p>
            )}
          </div>
          <div className="hidden">
            <Field
                name="company_id"
                component={Input}
            />
          </div>
          {!company && companyResultCount === 0 && (
            <div className="col col-w-1/4">
              <div className="ml-4 mt-5">
                <ButtonPrimary onClick={() => setNewCompanyModal(true)}>
                  Create a new Company
                </ButtonPrimary>
              </div>
            </div>
          )}
        </div>
        {company && (
            <div className="row">
              <div className="col col-w-1/3">
                <LiveSearchSimple
                    auth
                    label="Search Users"
                    getText={result => result.first_name + ' ' + result.surname + ': ' + result.email}
                    url={`${window.API}/users?companyUser=1&companyId=${company.id}`}
                    onChange={setClientName}
                    query="companyUser"
                    onNoResults={result => setShowNewUser(result)}
                    onClear={() => setClientName(null)}
                    shouldClearSearches={shouldClearSearches}
                    placeholder={client && client.first_name + ' ' + client.surname + ': ' + client.email}
                />
              <div className="hidden">
                  <Field
                      name="client_id"
                      component={Input}
                  />
                </div>
              </div>
              {!client && <div className="col col-w-1/4">
                <div className="ml-4 mt-5">
                  <ButtonPrimary onClick={() => setNewUserModal(true)}>
                    Create a new User
                  </ButtonPrimary>
                </div>
              </div>}
            </div>
        )}
        <div className="row">
          <div className="col col-w-1/2">
            <Field
                name="share_with_venues"
                label="Share client name with the Venues?"
                component={Toggle}
                validate={v.share_with_venues}
                wrapperClassName="inline mb-8 xl:w-1/2"
            />
          </div>
        </div>
        <div className="row mt-10">
          <div className="col col-w-1/4">
            <Field
                name="estimated_value"
                label="Estimated Value"
                component={Input}
                validate={v.estimated_value}
                wrapperClassName="w-full mb-8"
            />
          </div>
          <div className="col col-w-1/4">
            <Field
                name="estimated_commission"
                label="Estimated Commission"
                component={Input}
                validate={v.estimated_commission}
                wrapperClassName="w-full mb-8"
            />
          </div>
        </div>
      </>
  );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AgencySpecificEnquiryData);
