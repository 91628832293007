import React from "react";
import { number, object, string } from "prop-types";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors } from "redux-form";
import { getWidth } from "redux-window";
import { isLoggedIn } from "modules/auth";
import Loading from "Components/Loading";
import prettyRadius from "modules/pretty-radius";
import SearchWidgets from "Components/Search/Widgets";

class ResultsMeta extends React.Component {
  static propTypes = {
    count: number.isRequired,
    location_type: string,
    location: string,
    values: object.isRequired,
  };

  locationCriteria = () => {
    const { values } = this.props;
    switch (values.location_type) {
      case "Map":
        const radius = prettyRadius(values.radius);
        return (
          <React.Fragment>
            <Criteria label="Location" value={values.location} />
            <Criteria
              label="Radius"
              value={
                values.location && radius
                  ? radius.toString() + ` mile${values.radius === 1 ? "" : "s"}`
                  : null
              }
            />
          </React.Fragment>
        );
      case "Country":
        return <Criteria label="Country" value={values.country} />;
      case "Flight time from the UK":
        return (
          <Criteria
            label="Flight time from the UK"
            value={
              values.flight_time_from_uk
                ? "Less than " + values.flight_time_from_uk / 60 + " hours"
                : ""
            }
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { values, errors, width } = this.props;

    const count = this.props.count;

    if (width < 992) {
      return (
        <div className="fixed pin-b pin-l pin-r z-50 bg-white">
          <div>
            <SearchWidgets
              results={this.props.results}
              form="advanced-search-shortlist"
              searchForm="advanced-search"
              search={this.props.search}
            />
            <div className="bg-green text-white flex justify-between items-center px-3 py-2">
              {this.props.loading ? (
                <Loading dark inline />
              ) : (
                <>
                  <div className="col col-w-1/5">
                    <span className="font-bold font-heading text-2xl">
                      {count}
                    </span>
                  </div>
                  <div className="col col-w-3/5">
                    <span>
                      Your matching venues so far. Keep narrowing your search to
                      find that perfect match.
                    </span>
                  </div>
                </>
              )}
              <div>
                <a
                  href="#results"
                  className="no-underline bg-black text-white inline-block p-2 mr-5"
                >
                  VIEW
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="font-normal rounded-sm">
        <div className="text-white bg-green p-3 shadow-lg mb-3">
          <div className="px-6">
            {this.props.loading ? (
              <Loading dark />
            ) : (
              <>
                <p className="text-7xl font-bold font-heading text-center leading-none mb-4">
                  {count}
                </p>
                <p className="text-white font-normal lg:text-center text-lg">
                  Your matching venues so far. Keep narrowing your search to
                  find that perfect match.
                </p>
              </>
            )}
          </div>
        </div>
        <div className="text-white bg-black mb-5 px-5 pb-10 pt-5 rounded-sm">
          <div className="mb-0">
            <span className="border-white inline-block mb-5 leading-tight text-xl">
              Your Criteria
            </span>
            <br />
            {this.locationCriteria()}
            <Criteria label="Capacity" value={values.capacity} />
            {parseInt(values.back_projection, 10) ? (
              <Criteria label="Back projection" value="Yes" />
            ) : null}
            <Criteria label="Layout" value={values.layout} />
            <Criteria label="Bedrooms" value={values.total_bedrooms} />
            <Criteria
              label="Meeting rooms"
              value={values.total_meeting_rooms}
            />
            {parseInt(values.exhibition, 10) ? (
              <React.Fragment>
                <Criteria label="Exhibition" value="Yes" />
                <Criteria
                  label="Floor space"
                  value={values.floor_space ? values.floor_space + "m2" : null}
                />
              </React.Fragment>
            ) : null}
            <Criteria label="Star rating" value={values.star_rating} />
            <Criteria
              label="Venue type"
              value={
                Array.isArray(values.types_text) && values.types_text.length
                  ? values.types_text.join(", ")
                  : null
              }
            />
          </div>
        </div>
        {Object.keys(errors).length ? (
          <div className="text-white bg-warning p-3">
            <span className="border-b border-white inline-block mb-2 leading-tight">
              Errors
            </span>
            <ul className="list-reset">
              {Object.keys(errors).map(error => (
                <li className="mb-1" key={error}>
                  <i className="fal fa-exclamation-circle mr-2" />
                  {error.replace(/_/g, " ")}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <SearchWidgets
          results={this.props.results}
          form="advanced-search-shortlist"
          searchForm="advanced-search"
          search={this.props.search}
          smallTitle
        />
      </div>
    );
  }
}

function Criteria({ label, value }) {
  if (!value) return null;
  return (
    <p className="mb-1 text-sm">
      <strong>{label}</strong>: {value}
    </p>
  );
}

export default connect(state => ({
  values: getFormValues("advanced-search")(state) || {},
  errors: getFormSyncErrors("advanced-search")(state) || {},
  width: getWidth(state),
  isLoggedIn: isLoggedIn(state),
}))(ResultsMeta);
