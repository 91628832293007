import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  isBefore,
  isValid,
  isWithinRange,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from "date-fns";
import iteratee from "lodash/iteratee";

export const createDateRange = (start, end) => {
  const dateRange = { start: startOfDay(start), end: endOfDay(end) };

  if (
    !isValid(dateRange.start) ||
    !isValid(dateRange.end) ||
    isBefore(dateRange.end, dateRange.start)
  ) {
    throw new Error("Date range cannot be created");
  }

  return dateRange;
};

export const dateRangeFilter = (items, dateRange, dateKey) => {
  const { start, end } = dateRange;

  const getDate = iteratee(dateKey);

  return items.filter(item => isWithinRange(getDate(item), start, end));
};

// Takes a dateRange and return an array of months from start to finish
export const getDateRangeMonths = ({ start, end }) => {
  const periodStart = startOfMonth(start);
  const periodEnd = endOfMonth(end);

  let result = [];

  for (
    let currentMonth = periodStart;
    currentMonth <= periodEnd;
    currentMonth = addMonths(currentMonth, 1)
  ) {
    result.push(currentMonth);
  }

  return result;
};

// Takes a dateRange and return an array of days from start to finish
export const getDateRangeDays = ({ start, end }) => {
  const periodStart = startOfDay(start);
  const periodEnd = endOfDay(end);

  let result = [];

  for (
    let currentDay = periodStart;
    currentDay <= periodEnd;
    currentDay = addDays(currentDay, 1)
  ) {
    result.push(currentDay);
  }

  return result;
};

const getToday = () => new Date();

export const createRangeMonthToDate = (now = getToday()) =>
  createDateRange(startOfMonth(now), now);

export const createRangeMonthOnMonth = (now = getToday()) =>
  createDateRange(subMonths(now, 1), now);

export const createRangeYearToDate = (now = getToday()) =>
  createDateRange(startOfYear(now), now);

export const createRangeYearOnYear = (now = getToday()) =>
  createDateRange(subYears(now, 1), now);
