import React, {useEffect, useState} from "react";
import { Field, getFormValues, reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import {
  Date as DateField,
  Input,
  Toggle,
  Textarea,
  Select,
} from "Components/Form";
import { stage1 as v } from "./validate";
import RfpSubmit from "./RfpSubmit";
import Loading from "../../Components/Loading";
import BriefPreferences from "./BriefPreferences";

const Stage1 = ({ prevPage, form, handleSubmit, roomStyleOptions, hasVenues, editEnquiry, isSuperAgent, isAgency, briefPreferencesRelevant, enquiry, setShowDateWarningMessage, change }) => {
  const values = useSelector(getFormValues(form)) || {};
  const [dateChanged, setDateChanged] = useState(false);

  useEffect(() => {
    // set date_to = date_from on initial set of date_from
    if ( values['date_from'] && !values['date_to'] ) {
      change('date_to', values['date_from']);
    }
    if (enquiry && enquiry.enquiry_venue_datas) {
      enquiry.enquiry_venue_datas.forEach(evd => {
        if (dateChanged && evd.status > 40) {
          setShowDateWarningMessage(true)
        }
      })
    }
  }, [enquiry, dateChanged])

  const showBriefPreferences = (enquiry && briefPreferencesRelevant !== null) ? briefPreferencesRelevant : ((!isAgency || isSuperAgent) && (editEnquiry || !hasVenues));

  if (!roomStyleOptions) {
    return <Loading large />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col col-w-full xl:col-w-6/10">
          <div className="row">
            <div className="col col-w-full md:col-w-2/3">
              <Field
                name="event_name"
                label="Event name"
                component={Input}
                wrapperClassName="w-full mb-8 mr-2"
                validate={v.event_name}
              />
            </div>
            <div className="col col-w-1/3">
              <Field
                name="number_of_delegates"
                label="Number of delegates"
                component={Input}
                wrapperClassName="w-full mb-8"
                validate={v.number_of_delegates}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-w-1/2 md:col-w-1/3">
              <Field
                name="date_from"
                label="Date from"
                component={DateField}
                placeholder="Date from"
                onChange={() => setDateChanged(true)}
                wrapperClassName="w-full mb-8 mr-2"
                validate={!editEnquiry && v.date_from}
              />
            </div>
            <div className="col col-w-1/2 md:col-w-1/3">
              <Field
                name="date_to"
                label="Date to"
                wrapperClassName="w-full mb-8 mr-2"
                onChange={() => setDateChanged(true)}
                component={DateField}
                placeholder="Date to"
                validate={!editEnquiry && v.date_to}
              />
            </div>
            <div className="col col-w-full md:col-w-1/3">
              {values && values.date_to && values.date_from ? (
                <Field
                  name="date_flexible"
                  label="Flexible dates?"
                  component={Toggle}
                  wrapperClassName="mb-8 flex-grow"
                  validate={v.date_flexible}
                />
              ) : null}
            </div>
          </div>
          {values && values.date_flexible ? (
            <Field
              name="date_flexible_notes"
              label="Flexible dates notes"
              component={Textarea}
              rows={5}
              wrapperClassName="w-full mb-8"
              validate={v.date_flexible_note}
            />
          ) : null}
        </div>
        <div className="col col-w-full xl:col-w-4/10">
          <Field
            name="room_style_id"
            label="Main room layout"
            component={Select}
            options={roomStyleOptions}
            wrapperClassName="mb-3"
            validate={v.room_style_id}
          />
          {values && values.room_style_id ? (
            <div
              className="re-16/9 mb-8 bg-cover"
              style={{
                maxWidth: 300,
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url('/img/assets/mr-${roomStyleOptions
                  .find(
                    option => option.value === parseInt(values.room_style_id)
                  )
                  .text.toLowerCase()
                  .replace("-", "")}.jpg')`,
              }}
            />
          ) : null}
        </div>
      </div>
      {showBriefPreferences && (
        <BriefPreferences editEnquiry={editEnquiry}/>
      )}
      <RfpSubmit page={0} prevPage={prevPage} />
    </form>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Stage1);
