import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getWidth } from "redux-window";
import { connect } from "react-redux";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from "swiper";
import 'swiper/swiper.min.css';

const Slides = ({ current, onClick, rawSlides, windowSize }) => {
  const { xs, sm, md, lg, xl, xxl } = windowSize;
  const numSlides = rawSlides.length;

  let showSides = true;
  let className;
  let total;
  let width;
  let left;
  let iconClassName;
  let iconSide;

  if (xs || sm) {
    if (numSlides === 1) {
      className = "w-full";
      total = 1;
      width = "100%";
      left = "0";
      iconClassName = "";
      iconSide = "";
      showSides = false;
    } else {
      className = "w-1/3";
      total = 3;
      width = "240%";
      left = "-70%";
      iconClassName = "w-8 h-8 text-base";
      iconSide = "28%";
    }
  }
  if (md || lg) {
    if (numSlides < 3) {
      className = "w-1/2";
      total = numSlides;
      width = "100%";
      left = "0";
      iconClassName = "";
      iconSide = "";
      showSides = false;
    } else {
      className = "w-1/4";
      total = 4;
      width = "133.333333%";
      left = "-16.66666665%";
      iconClassName = "w-10 h-10 text-lg";
      iconSide = "17%";
    }
  }
  if (xl || xxl) {
    if (numSlides < 5) {
      className = "w-1/4";
      total = numSlides;
      width = "100%";
      left = "0";
      iconClassName = "";
      iconSide = "";
      showSides = false;
    } else {
      className = "w-1/5";
      total = 5;
      width = "125%";
      left = "-12.5%";
      iconClassName = "w-12 h-12 text-xl";
      iconSide = "13%";
    }
  }

  const slides = [];
  let currentSlide = current;
  for (let i = 0; i < total; i++) {
    if (rawSlides[currentSlide]) {
      slides.push(rawSlides[currentSlide]);
      currentSlide++;
    } else {
      currentSlide = 0;
      slides.push(rawSlides[currentSlide]);
      currentSlide++;
    }
  }


  if (xs || sm || ((md || lg) && numSlides >= 3)) {
    slides.unshift(rawSlides[current ? current - 1 : rawSlides.length - 1]);
  }
  const visibleSlides = slides.slice(0, total);

  const icon = right => (
    <div
      className={classNames(
        "pointer text-white bg-black absolute flex items-center justify-center rounded-100 z-10",
        iconClassName
      )}
      onClick={() => onClick(right)}
      style={{
        top: "50%",
        transform: "translateY(-50%)",
        left: right ? null : iconSide,
        right: right ? iconSide : null,
      }}
    >
      <i
        className={classNames("fal", {
          "fa-chevron-left": !right,
          "fa-chevron-right": right,
        })}
      ></i>
    </div>
  );

  if (!rawSlides.length) return null;

  return (
    <div className={classNames("relative clearfix")} style={{ width, left }}>
      {showSides && icon()}
      {visibleSlides.map((Slide, i) => {
        return (
          <div
            className={classNames(className, "float-left px-1", {
              "opacity-50": showSides && (!i || i === total - 1),
            })}
            key={i}
          >
            <Slide />
          </div>
        );
      })}
      {showSides && icon(true)}
    </div>
  );
};

const Slider = ({ slides: rawSlides = [], windowSize, useSwiper = true }) => {
  const { xs, sm, md, lg, xl, xxl } = windowSize;
  //NOTE: No idea why the slider wraps 1 back, this is a crap fix for now.
  const [current, setCurrent] = useState((rawSlides.length > 0) ? rawSlides.length - 1 : 0);
  const [slidesInView, setSlidesInView] = useState(1);
  const [showNavigation, setShowNavigation] = useState(true);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  useEffect(() => {
    const slideCount = rawSlides.length;
    if (xs || sm) {
      setSlidesInView(1.25);
    }
    if (md || lg) {
      setSlidesInView(3.25);
    }
    if (xl || xxl) {
      if (slideCount < 5) {
        setShowNavigation(false)
        setSlidesInView(3.25);
      } else {
        setSlidesInView(4.25);
      }
    }
  }, [windowSize])

  const handleClick = next => {
    let nextSlide;

    if (next) {
      nextSlide = current + 1;
      if (nextSlide === rawSlides.length) {
        nextSlide = 0;
      }
    } else {
      nextSlide = current - 1;
      if (nextSlide < 0) {
        nextSlide = rawSlides.length - 1;
      }
    }
    setCurrent(nextSlide);
  };

  if (!rawSlides.length) {
    return null;
  }

  return (
    <div className="w-full overflow-hidden">
      <div className="md:overflow-hidden relative z-0">
        {useSwiper ? (
          <Fragment>
            {showNavigation ? (
              <div
                className={"pointer text-white bg-black absolute flex m-auto items-center justify-center rounded-100 z-10"}
                ref={prevRef}
                style={{
                  bottom: "50%"
                }}
              >
                <i
                  className={"fal fa-chevron-left"}
                  style={{
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></i>
              </div>
            ) : null}
            <div style={{left: "35px", position: "inherit"}}>
            <Swiper
              spaceBetween={5}
              modules={[Pagination, Navigation]}
              navigation={{
                prevEl: prevRef?.current,
                nextEl: nextRef?.current
              }}
              slidesPerView={slidesInView}
              centerInsufficientSlides={false}
              onSwiper={setSwiper}
            >
              {rawSlides.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>
                    {slide}
                  </SwiperSlide>
                )
              })}
            </Swiper>
            </div>
            {showNavigation ? (
              <div
                className={"pointer text-white ml-2 absolute bg-black flex m-auto items-center justify-center rounded-100 z-10"}
                ref={nextRef}
                style={{
                  bottom: "50%",
                  right: "0px"
                }}
              >
                <i className={"fal fa-chevron-right"}
                   style={{
                     width: "30px",
                     height: "30px",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                   }}
                ></i>
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Slides
            current={current}
            onClick={handleClick}
            rawSlides={rawSlides}
            windowSize={windowSize}
          />
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.func),
  windowSize: PropTypes.object.isRequired,
};

export default connect(state => {
  const width = getWidth(state);
  return {
    windowSize: {
      xs: width < 576,
      sm: width >= 576 && width < 768,
      md: width >= 768 && width < 992,
      lg: width >= 992 && width < 1200,
      xl: width >= 1200 && width < 1600,
      xxl: width >= 1600,
    },
  };
})(Slider);
