import axios from "axios";

const getRoomStyles = async  (textAsValues = false, token) => {
  try {
    const response = await axios.get(
      `${window.API}/rfp/room-style`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (!response.data.data) {
      return {
        result: [],
      };
    }

    return {
      result: response.data.data.map(style => ({
        text: style.name,
        value: textAsValues? style.name : style.id,
      })),
    };
  } catch (error) {
    return { error, result: [] };
  }
};



export { getRoomStyles };
