import React from "react";
import moment from "moment";

export const formatDate = date => moment(date).format("D/M/YY");

export const getArticleImage = (article, defaultImage) => {
  if (article.image) return article.image;

  if (article.venues && article.venues.length > 0) {
    const venueWithFeatureImage = article.venues.find(
      venue => venue.featured_image && venue.featured_image.url
    );

    if (venueWithFeatureImage) return venueWithFeatureImage.featured_image;

    const venueWithImages = article.venues.find(
      venue => venue.images.length > 0
    );

    if (venueWithImages) return venueWithImages.images[0];
  }

  if (defaultImage) return defaultImage;
};

export const offerStyles = {
  blog: {
    color: "202,17,61",
    icon: "fa-comments",
    name: "Blog",
    title: <React.Fragment>Blog</React.Fragment>,
  },
  "special-offers": {
    color: "202,17,61",
    icon: "fa-money-bill-wave-alt",
    name: "Special Offers",
    title: (
      <React.Fragment>
        Special
        <br />
        Offers
      </React.Fragment>
    ),
  },
  "fam-trips": {
    color: "66,123,194",
    icon: "fa-eye",
    name: "Familiarity Visits",
    title: (
      <React.Fragment>
        Familiarity
        <br />
        Visits
      </React.Fragment>
    ),
  },
  "agency-rates": {
    color: "31,114,72",
    icon: "fa-pound-sign",
    name: "Agency Rates",
    title: (
      <React.Fragment>
        Agency
        <br />
        Rates
      </React.Fragment>
    ),
  },
  "movers-and-shakers": {
    color: "238,80,15",
    icon: "fa-users",
    name: "Movers & Shakers",
    title: (
      <React.Fragment>
        Movers &amp;
        <br />
        Shakers
      </React.Fragment>
    ),
  },
  "renovation-news": {
    color: "22,54,92",
    icon: "fa-hotel",
    name: "Renovation News",
    title: (
      <React.Fragment>
        Renovation
        <br />
        News
      </React.Fragment>
    ),
  },
  "commission-offers": {
    color: "89,57,120",
    icon: "fa-pound-sign",
    name: "Commission Offers",
    title: (
      <React.Fragment>
        Commission
        <br />
        Offers
      </React.Fragment>
    ),
  },
};
