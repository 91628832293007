import {
  CORPORATE_ACCOUNT_BOOKING_DESK_CREATED,
  CORPORATE_ACCOUNT_BOOKING_DESK_DELETED,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST,
  CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD
} from "./types";

export const corporateBookingDesks = (state = {
  corporateBookingDesks: {},
  isFetchingBookingDesks: false,
  error: false,
  showBookingDeskForm: false,
}, action) => {
  switch (action.type) {
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_REQUEST:
      return {
        ...state,
        isFetchingBookingDesks: true,
      }
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED:
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: action.data
      }
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_RECEIVED_ERROR:
      return {
        ...state,
        isFetchingBookingDesks: false,
      }
    case CORPORATE_ACCOUNT_BOOKING_DESK_LIST_UNLOAD:
      return {
        ...state,
        corporateBookingDesks: {}
      }
    case CORPORATE_ACCOUNT_BOOKING_DESK_DELETED:
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: [...state.corporateBookingDesks.filter(obj => {
          return obj.id !== action.data.data.id
        })]
      }
    case CORPORATE_ACCOUNT_BOOKING_DESK_CREATED:
      state.corporateBookingDesks.push(action.data);
      return {
        ...state,
        isFetchingBookingDesks: false,
        corporateBookingDesks: [...state.corporateBookingDesks],
        showBookingDeskForm: false
      }
    default:
      return state;
  }
}