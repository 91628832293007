import React from "react";
import classNames from "classnames";
import { string, object, number, bool, oneOfType } from "prop-types";
import Label from "./Label";
import Error from "./Error";

export default class Textarea extends React.Component {
  static propTypes = {
    input: object.isRequired,
    meta: object.isRequired,
    label: oneOfType([string, object]).isRequired,
    type: string,
    placeholder: string,
    wrapperClassName: string,
    labelClassName: string,
    textareaClassName: string,
    rows: number,
    white: bool,
  };

  render() {
    const {
      meta: { touched, error, warning },
      input,
      label,
      type,
      rows,
      placeholder,
      wrapperClassName,
      labelClassName,
      textareaClassName,
      white,
    } = this.props;

    return (
      <div className={wrapperClassName || "mb-5"}>
        <Label name={input.name} className={labelClassName} text={label} />
        <textarea
          {...input}
          placeholder={placeholder}
          type={type}
          rows={rows}
          className={
            textareaClassName ||
            classNames(
              [
                "leading-none",
                "p-2",
                "w-full",
                "outline-none",
                "font-normal",
                "text-black",
                "text-sm",
                "border",
                "w-full",
                "focus:border-primary",
                "bg-white",
                "border-black",
              ],
              {
                "border-grey-md": !white,
                "border-white": white,
              }
            )
          }
        />
        <Error touched={touched} warning={warning} error={error} />
      </div>
    );
  }
}
