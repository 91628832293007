import React, { useState } from 'react';
import Modal from 'Components/Modal';
import { ButtonSpan } from 'Components/Button';
import { LabelledText } from 'ui';
import useService from 'hooks/useService';
import { getCateringTypes } from 'services/rfpService';
import Additional from './Additional';
import getSaving from './get-saving';
import getSingleSaving from './get-single-saving';
import {formattedApplicableDates, GBPFormatter} from 'utils';
import { getVatRateById } from '../../pages/admin/rfp/Confirmation/Helpers/calculateTotals';
import ToggleRevealer from './Components/ToggleRevealer';
import BreakdownTable from './Components/BreakdownTable';

const PackagePriceBreakdownModal = ({
    brief,
                                      packages = [],
                                      onClose,
                                      total = {},
                                      attachments,
                                      notes,
                                      rooms,
                                      vatRates = [],
                                      enquiryVenueData
                                    }) => {
  const { data: cateringTypes } = useService({
    initialData: [],
    service: getCateringTypes,
    trigger: true,
  });
  if (vatRates.length < 1) {
    return null;
  }
    return (
      <Modal>
        <div className="modal">
          <div
              className="modal-inner"
              style={{
                width: 1200,
                maxWidth: '90%',
              }}
          >
            <ButtonSpan
                className="m-3 absolute pin-t pin-r z-10"
                onClick={onClose}
            >
              <i className="fas fa-times-circle fa-2x ml-3"/>
            </ButtonSpan>
            <div>
              <h2 className="mb-3 text-3xl">Conference Packages</h2>
              {packages.map(
                  ({
                     id,
                     description,
                     no_of_delegates,
                     no_of_days,
                     applicable_days,
                     total_number,
                     price_per_delegate_net,
                     price_per_delegate_vat,
                     price_vat_rate_id,
                     price_reduced_from_net,
                     price_reduced_from_vat,
                     price_reduced_from_total_net,
                     price_reduced_from_total_vat,
                     price_total_net,
                     price_total_vat,
                     price_includes_vat,
                     price_percentage_saving,
                     catering_includes,
                     min_number_of_delegates_for_qualify,
                     catering_note,
                     includes_main_room_hire,
                     includes_flipchart,
                     includes_accommodation,
                     includes_free_wifi,
                     includes_paper_and_pencils,
                     other_equipment_notes,
                   }) => {
                      return (
                          <div className="mb-5 pt-3 border-t border-black" key={id}>
                              <h3 className="mb-3">{description}</h3>
                              <div className="flex flex-wrap">
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Number of delegates"
                                      text={no_of_delegates}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                label="Dates package applies to"
                                text={formattedApplicableDates(
                                    enquiryVenueData && enquiryVenueData.date_from_override ? enquiryVenueData.date_from_override :  brief.date_from,
                                    enquiryVenueData && enquiryVenueData.date_to_override ? enquiryVenueData.date_to_override :   brief.date_to,
                                    applicable_days.split(',').map(d => Number(d))).join(', ')}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Minimum Number of Delegates For Qualification"
                                      text={min_number_of_delegates_for_qualify}
                                  />
                              </div>
                              <div className="flex flex-wrap">
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Total number"
                                      text={total_number}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Net Unit Price (£)"
                                      text={GBPFormatter.format(Number(price_per_delegate_net))}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Reduced from net unit price (£)"
                                      text={GBPFormatter.format(Number(price_reduced_from_net))}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Cost (£)"
                                      text={GBPFormatter.format(Number(price_total_net))}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8 text-right"
                                      label="Reduced from (£)"
                                      text={GBPFormatter.format(price_reduced_from_total_net)}
                                  />
                                  {/*<LabelledText*/}
                                  {/*    Wrapper="div"*/}
                                  {/*    className="mr-8"*/}
                                  {/*    label="Includes VAT"*/}
                                  {/*    text={price_includes_vat ? 'Yes' : 'No'}*/}
                                  {/*/>*/}
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Cost saving (%)"
                                      text={`${price_percentage_saving}%`}
                                  />
                                  <LabelledText
                                      Wrapper="div"
                                      className="mr-8"
                                      label="Cost saving (£)"
                                      text={getSingleSaving(price_reduced_from_total_net, price_total_net)}
                                  />
                              </div>

                              <div className="flex flex-wrap">
                                  <ToggleRevealer toggleText="Show Tax Breakdown">
                                      <BreakdownTable
                                          headings={['Unit Price', 'Reduced From Unit Price', 'Cost', 'Reduced From']}
                                          vatRate={getVatRateById(vatRates, price_vat_rate_id).number}
                                          rows={[
                                              [
                                                  GBPFormatter.format(price_per_delegate_net),
                                                  GBPFormatter.format(price_reduced_from_net),
                                                  GBPFormatter.format(price_total_net),
                                                  GBPFormatter.format(price_reduced_from_total_net),
                                              ],
                                              [
                                                  GBPFormatter.format(price_per_delegate_vat),
                                                  GBPFormatter.format(price_reduced_from_vat),
                                                  GBPFormatter.format(price_total_vat),
                                                  GBPFormatter.format(price_reduced_from_total_vat),
                                              ],

                                              [
                                                  GBPFormatter.format(Number(price_per_delegate_net) + Number(price_per_delegate_vat)),
                                                  GBPFormatter.format(Number(price_reduced_from_net) + Number(price_reduced_from_vat)),
                                                  GBPFormatter.format(Number(price_total_net) + Number(price_total_vat)),
                                                  GBPFormatter.format(Number(price_reduced_from_total_net) + Number(price_reduced_from_total_vat)),
                                              ],
                                          ]}/>
                                  </ToggleRevealer>
                              </div>
                              {catering_includes && !!catering_includes.length && (
                                  <div className="mb-5">
                                      <h3 className="mb-3">Included Catering</h3>
                                      <table>
                                          <tbody>
                                          {catering_includes.map((inc, i) => (
                                              <tr key={inc.id}>
                                                  <td>
                                                      <LabelledText
                                                          Wrapper="div"
                                                          className="mr-8"
                                                          label="Type"
                                                          hideLabel={!!i}
                                                          text={
                                                              (
                                                                  cateringTypes.find(
                                                                      type =>
                                                                          type.value === inc.catering_type_id,
                                                                  ) || {}
                                                              ).text
                                                          }
                                                      />
                                                  </td>
                                                  <td>
                                                      <LabelledText
                                                          Wrapper="div"
                                                          className="mr-8"
                                                          label="Served with"
                                                          hideLabel={!!i}
                                                          text={inc.served_with}
                                                      />
                                                  </td>
                                                  <td>
                                                      <LabelledText
                                                          Wrapper="div"
                                                          className="mr-8"
                                                          label="Location"
                                                          hideLabel={!!i}
                                                          text={
                                                              inc.room_name
                                                          }
                                                      />
                                                  </td>
                                                  <td>
                                                      <LabelledText
                                                          Wrapper="div"
                                                          className="mr-8"
                                                          label="Serving instructions"
                                                          hideLabel={!!i}
                                                          text={inc.serving_instructions || 'N/A'}
                                                      />
                                                  </td>
                                              </tr>
                                          ))}
                                          </tbody>
                                      </table>
                                      {catering_note && (
                                          <LabelledText
                                              Wrapper="div"
                                              label="Additional catering notes"
                                              text={catering_note}
                                          />
                                      )}
                                  </div>
                              )}
                              <div className="mb-5">
                                  <h3 className="mb-3">Also Includes</h3>
                                  <div className="flex flex-wrap">
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label="Accommodation"
                                          text={includes_accommodation ? 'Yes' : 'No'}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label="Main room hire"
                                          text={includes_main_room_hire ? 'Yes' : 'No'}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label="Flip chart"
                                          text={includes_flipchart ? 'Yes' : 'No'}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label="Free wifi"
                                          text={includes_free_wifi ? 'Yes' : 'No'}
                                      />
                                      <LabelledText
                                          Wrapper="div"
                                          className="mr-8"
                                          label="Stationery"
                                          text={includes_paper_and_pencils ? 'Yes' : 'No'}
                                      />
                                      {other_equipment_notes && (
                                          <LabelledText
                                              Wrapper="div"
                                              className="mr-8"
                                              label="Other"
                                              text={other_equipment_notes}
                                          />
                                      )}
                                  </div>
                              </div>
                          </div>
                      )
                   }
              )}
              <div className="mb-5 pt-3 border-t border-black">
                <h3 className="mb-3">Total</h3>
                <div className="flex flex-wrap">
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Total Including VAT (£)"
                      text={GBPFormatter.format(total.total_inc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Total Excluding VAT (£)"
                      text={GBPFormatter.format(total.total_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Reduced From (£)"
                      text={GBPFormatter.format(total.reduced_from_exc_vat)}
                  />
                  <LabelledText
                      Wrapper="div"
                      className="mr-8 text-right"
                      label="Cost Saving (%)"
                      text={getSaving(total)}
                  />
                </div>
              </div>
              <Additional attachments={attachments} notes={notes}/>
            </div>
          </div>
        </div>
      </Modal>
  );
};

export default PackagePriceBreakdownModal;
