import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import {isInternal, isLoggedIn} from "../../modules/auth";
import { useSelector } from 'react-redux';

const Label = ({ type = "booking", offers, town, venue, onClickThrough, fadeout = false }) => {

  const isUserInternal = useSelector(state => isInternal(state));
  const loggedIn = useSelector(state => isLoggedIn(state));
  let bgColor = "bg-edge-blue";
  if (type === "offers") bgColor = "bg-dark-red";
  if (type === "specialOffers") bgColor = "bg-green";
  if (type === "info") bgColor = "bg-dark-green";
  if (type === "site-visits") bgColor = "bg-orange";

  let text = "offer";
  if (type === "offers") {
    text = offers > 1 ? "agency offers" : "agency offer";
  }
  if (type === "specialOffers") {
    text = offers > 1 ? "offers" : "offer";
  }
  return (
    <div
      className={`rounded-sm ${bgColor} py-1 px-2 uppercase text-white text-sm font-normal mr-2 mb-2 ${fadeout ? 'fade-out' : ''}`}
    >
      {type === "booking" && <span title="This venue allows for instant booking...">Instant book</span>}
      {type === "siteVisits" && loggedIn &&  <Link
          to={`/venue/${venue.slug}#site-visits`}
          className={'text-white text-sm font-normal'}
      >
        <span title="This venue has site visit insights available">Site Visit Insights</span>
      </Link> }
      {( type === "specialOffers") && typeof venue !== 'undefined' && 'slug' in venue ? (
          <Link
              to={`/venue/${venue.slug}#offers`}
              className={'text-white text-sm font-normal'}
              onClick={() => {
                // console.log(onClickThrough)
                onClickThrough();
              }}
          >
              <span title="This venue has some active offers...">
                <span className="font-bold">{offers}</span> {text}
              </span>
          </Link>


      ): (null)}

      {(type === "offers") && (
          <span title="This venue has some active offers...">
          <span className="font-bold">{offers}</span> {text}
        </span>
      )}


      {type === "info" && (
        <>
          <span
            data-tip
            data-for="clickme"
            data-event="click"
            className="flex items-center pointer"
          >
            Destination info <FaInfoCircle className="ml-2" />
          </span>
          <ReactTooltip
            id="clickme"
            effect="solid"
            type="light"
            clickable={true}
            className="tooltip-shadow"
            globalEventOff="click"
          >
            <div className="flex text-transform-none">
              <div>
                <FaInfoCircle className=" text-lg mr-2" />
              </div>
              <div>
                {/* <p>
                  <b>Value for money: </b> Prices are genarally about 20% higher
                  than in the UK. You can...
                </p> */}
                <a
                  className="text-blue"
                  href={`/destination/${town}`}
                >{`Learn more about ${town}`}</a>
              </div>
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default Label;
