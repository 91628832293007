import React from "react";
import axios from "axios";
import { reduxForm, Field, SubmissionError } from "redux-form";
import parseErrors from "modules/parse-errors";
import Input from "Components/Form/Input";
import { ButtonBrand } from "Components/Button";
import Warning from "Components/Warning";
import Loading from "Components/Loading";

const form = "reset-password";

class ResetPassword extends React.Component {
  state = {
    error: undefined,
    complete: false,
  };

  resetPassword = values => {
    return axios
      .post(`${window.API}/auth/forgotten-password`, values)
      .then(() => {
        this.setState({
          complete: true,
          email: values.email,
          error: undefined,
        });
      })
      .catch(err => {
        const errors = parseErrors(err);
        if (errors) {
          throw new SubmissionError({
            ...errors,
            _error: "Password update failed",
          });
        } else {
          this.setState({
            complete: false,
            error: true,
          });
        }
      });
  };

  render() {
    if (this.state.complete) {
      return (
        <p className="font-normal">
          An email with a link to reset your password has been sent to{" "}
          {this.state.email}
        </p>
      );
    }
    return (
      <form onSubmit={this.props.handleSubmit(this.resetPassword)} noValidate>
        <p className="mb-5 font-normal">
          Please enter your email and we will send you a link to reset your
          password.
        </p>
        <div className="flex mb-5">
          <Field
            name="email"
            type="email"
            label="Email address"
            component={Input}
            right={() => (
              <ButtonBrand type="submit">
                {this.props.submitting ? <Loading inline /> : "Submit"}
              </ButtonBrand>
            )}
          />
        </div>
        {this.state.error ? (
          <Warning>
            Sorry there was a problem resetting your password. Please check your
            email address and try again.
          </Warning>
        ) : null}
      </form>
    );
  }
}

export default reduxForm({ form })(ResetPassword);
