import React from "react";
import {connect} from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { object, func } from "prop-types";
import {ButtonBrand, ButtonPrimary} from "Components/Button";
import Modal from "Components/Modal";
import TrueFalseList from "Components/TrueFalseList";
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const avEquipment = [
  {
    text: "Flipchart",
    value: 1,
  },
  {
    text: "LCD Projector",
    value: 2,
  },
  {
    text: "Screen",
    value: 3,
  },
  {
    text: "Staging",
    value: 5,
  },
  {
    text: "Lectern",
    value: 6,
  },
  {
    text: "Hearing Loop",
    value: 8,
  },
  {
    text: "Wi-Fi",
    value: 9,
  },
  {
    text: "PA System",
    value: 10,
  },
  {
    text: "Microphone",
    value: 11,
  },
  {
    text: "Plasma Screen",
    value: 15,
  },
  {
    text: "Smartboard",
    value: 16,
  },
];

const capacityStyles = {
  width: 260,
  height: 130,
  float: "left",
};

const capacityInnerStyles = {
  fontSize: 40,
  padding: "16px 0 0 20px",
  color: "#000",
};

class MeetingRooms extends React.Component {
  static propTypes = {
    room: object.isRequired,
    closeModal: func.isRequired,
  };

  state = {
    showAdditionalInfo: false,
    showExtraEquipment: false,
  };

  render() {
    const {
      name,
      image_id,
      is_part_of_divided_room,
      natural_daylight,
      aircon,
      floor,
      min_height,
      max_height,
      width,
      length,
      files,
      has_pillars,
      additional_information,
      equipment,
      max_capacity_boardroom,
      max_capacity_ushape,
      max_capacity_cabaret,
      max_capacity_theatre,
      max_capacity_classroom,
      max_capacity_dining,
      max_capacity_exhibition,
    } = this.props.room;
    return (
      <Modal>
        <div className="modal">
          <div
            className="modal-inner max-w-full"
            style={{ width: 1000, maxWidth: "90%" }}
          >
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="mb-5 text-primary">Room: {name}</h2>

                {is_part_of_divided_room ? (
                  <div className="mb-5 text-warning bg-off-white p-3">
                    Please note that this is part of a divided room.
                  </div>
                ) : null}

                <div className="row">
                  <div className="col col-w-full lg:col-w-1/3 mb-5">
                    {image_id && (
                      <div
                        className="mb-3 re-16/9 bg-cover bg-center bg-no-repeat"
                        style={{
                          backgroundImage: `url(https://api.edgevenues.com/venues/${this.props.match.params.venue}/images/${image_id})`,
                        }}
                      />
                    )}
                    <TrueFalseList
                      items={[
                        {
                          name: "Natural Daylight",
                          value: !!natural_daylight,
                        },
                        {
                          name: "Air Conditioning",
                          value: !!aircon,
                        },
                        {
                          name: "Has Pillars",
                          value: !!has_pillars,
                        },
                      ]}
                    />
                    {floor ? (
                      <p>
                        <strong>Floor Level:</strong>
                        &nbsp;
                        {floor}
                      </p>
                    ) : null}
                    {max_height || min_height ? (
                      <p>
                        <strong>Height: </strong>
                        {min_height ? (
                          <span>
                            {min_height}
                            (min){" "}
                          </span>
                        ) : null}
                        {max_height ? (
                          <span>
                            {max_height}
                            (max){" "}
                          </span>
                        ) : null}
                      </p>
                    ) : null}
                    {width && length ? (
                      <p>
                        <strong>Area: </strong>
                        W:
                        {width}m * L:
                        {length}m ={" "}
                        {(parseFloat(width) * parseFloat(length))
                          .toFixed(2)
                          .toString()
                          .replace(".00", "")}
                        m<sup>2</sup>
                      </p>
                    ) : null}
                  </div>

                  <div className="col col-w-full lg:col-w-2/3 mb-5">
                    <div className="lg:row">
                      {max_capacity_boardroom ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-boardroom.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_boardroom}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {max_capacity_ushape ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-ushape.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_ushape}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {max_capacity_cabaret ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-cabaret.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_cabaret}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {max_capacity_classroom ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-classroom.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_classroom}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {max_capacity_theatre ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-theatre.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_theatre}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {max_capacity_dining ? (
                        <div className="lg:col lg:col-w-1/2">
                          <div
                            style={{
                              ...capacityStyles,
                              background:
                                "url('/img/assets/mr-dining.jpg') no-repeat top left",
                            }}
                          >
                            <div style={capacityInnerStyles}>
                              {max_capacity_dining}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  {additional_information ? (
                    <ButtonPrimary
                      classes="mr-3 mb-5"
                      onClick={() =>
                        this.setState({
                          showAdditionalInfo: !this.state.showAdditionalInfo,
                        })
                      }
                    >
                      {this.state.showAdditionalInfo ? "Hide" : "Show"}{" "}
                      additional info
                    </ButtonPrimary>
                  ) : null}
                  {Array.isArray(equipment) && equipment.length ? (
                    <ButtonPrimary
                      classes="mb-5 mr-3"
                      onClick={() =>
                        this.setState({
                          showExtraEquipment: !this.state.showExtraEquipment,
                        })
                      }
                    >
                      {this.state.showExtraEquipment ? "Hide" : "Show"} AV
                      Equipment
                    </ButtonPrimary>
                  ) : null}
                  {Array.isArray(files) && files.length ? (
                    <ButtonPrimary
                      classes="mb-5"
                      onClick={() =>
                        this.setState({
                          showFiles: !this.state.showFiles,
                        })
                      }
                    >
                      {this.state.showExtraEquipment ? "Hide" : "Show"}{" "}
                      Documents
                    </ButtonPrimary>
                  ) : null}
                </div>
                {this.state.showAdditionalInfo ? (
                  <div>
                    <h4>
                      <strong>Additional Information:</strong>
                    </h4>
                    <p>{additional_information}</p>
                  </div>
                ) : null}
                {this.state.showExtraEquipment ? (
                  <div>
                    <h4>
                      <strong>Inbuilt AV equipment:</strong>
                    </h4>
                    <p>
                      {equipment.map((item, i) => {
                        const thing = avEquipment.filter(
                          a => a.value === item.id
                        )[0];
                        return thing ? (
                          <span key={thing.text}>
                            {thing.text}
                            {i === equipment.length - 1 ? "" : ", "}
                          </span>
                        ) : (
                          <span key={i} />
                        );
                      })}
                    </p>
                  </div>
                ) : null}
                {this.state.showFiles ? (
                  <div>
                    <h4>
                      <strong>Documents:</strong>
                    </h4>
                    <ul className="list-reset">
                      {files.map(file => (
                        <li className="mb-2">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-normal"
                          >
                            <i className="fas fa-file-pdf mr-2" />
                            {file.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
              <div className="text-right">
                <ButtonPrimary onClick={this.props.closeModal}>
                  Close
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null, {venueInteraction})(withRouter(MeetingRooms));
