import React, { useState } from "react";
import classNames from "classnames";

const WhitePanelWithHeader = ({
  children,
  title,
  icon,
  defaultOpen,
  collapsible,
}) => {
  const [open, setOpen] = useState(defaultOpen || !collapsible);
  return (
    <div
      className={classNames("bg-white mb-5 shadow", {
        pointer: collapsible && !open,
      })}
      onClick={collapsible && !open ? () => setOpen(true) : null}
    >
      <div
        className={classNames("p-2 font-normal", {
          pointer: collapsible,
        })}
        onClick={collapsible && open ? () => setOpen(false) : null}
        style={{
          backgroundImage: "linear-gradient(to right, #f7f7f7, #dfdfdf)",
        }}
      >
        {(collapsible || icon) && (
          <i
            className={classNames("mr-2 text-brand", {
              [icon]: !collapsible,
              "fas fa-caret-up": open,
              "fas fa-caret-down": !open,
            })}
          ></i>
        )}
        {title}
      </div>
      <div className="p-3">{open ? children : "..."}</div>
    </div>
  );
};

export default WhitePanelWithHeader;
