import axios from "axios";
import pick from "lodash/pick";
import moment from "moment";

const sections = [
  "accommodation",
  "catering",
  "conference_packages",
  "meeting_rooms",
  "equipments",
];


const submitChecksheet = async (formData, token, vatRates) => {
  /**
   * Top level
   */
  const payload = {
    ...pick(formData, [
      "venue_id",
      'is_draft',
      "is_venue_approval",
      "is_venue_approved",
      "enquiry_id",
      "why_choose_this_hotel",
      "hold_for",
      "overview_notes",
      "packages_notes",
      "meeting_rooms_notes",
      "catering_notes",
      "accommodation_notes",
      "misc_notes",
      "commission_notes",
    ]),
    display_prices_including_vat: formData.display_prices_including_vat,
  };

  /**
   * Packages
   */
  payload.packages = (formData.packages || []).map(p => ({
    ...pick(p, [
      "id",
      "package_type",
      "package_name",
      "includes_main_room_hire",
      "includes_accommodation",
      "includes_flipchart",
      "includes_free_wifi",
      "includes_paper_and_pencils",
      "includes_other",
      "includes_notes",
      "price_per_delegate",
      "total_number",
      "num_delegates",
      "num_days",
      "min_number_of_delegates_for_qualify",
      "day",
      "display_price_including_vat",
      "price_vat_rate_id",
      "price_total",
      "reduced_from_price",
      "reduced_from_total",
      "saving",
        "price_per_delegate_net",
        "price_per_delegate_vat",
        "price_total_net",
        "price_total_vat",
        "price_reduced_from_net",
        "price_reduced_from_vat",
        "price_reduced_from_total_net",
        "price_reduced_from_total_vat"
    ]),
    includes: (p.includes || []).map(include => ({
      ...pick(include, [
        "catering_type_id",
        "served_with",
        "serving_instructions",
        "room_id",
      ]),
    })),
  }));

  /**
   * Meeting Rooms
   */

  // if is main room && main room hire is included in stage 2 don't show pricing fields

  payload.meeting_rooms = (formData.meeting_rooms || []).map(meetingRoom => {
    // ensure dates are in order regardless of click order
    meetingRoom.applicable_days = meetingRoom.applicable_days.split(',');
    meetingRoom.applicable_days.sort(function(a, b) {
      return a - b;
    });
    meetingRoom.applicable_days = meetingRoom.applicable_days.join(',');

    if (isNaN(meetingRoom.price_total_net)) {
      delete meetingRoom.price_total_net
    }
    if (isNaN(meetingRoom.price_total_vat)) {
      delete meetingRoom.price_total_vat
    }
    return meetingRoom;
//    /// DC: what is this ?!
//    if (meetingRoom.room_vat_rate_id) {
//      const reducedFromPriceComponents = calculateVat(Boolean(meetingRoom.room_include_vat),  meetingRoom.reduced_from, getVatRateById(vatRates, meetingRoom.room_vat_rate_id).number);
//      meetingRoom.reduced_from = reducedFromPriceComponents.net
//      meetingRoom.room_vat_value = reducedFromPriceComponents.vat
//    }
  });

  payload.equipment = (formData.equipment || []).map(eq => {
    if (eq.vat_rate_id) {
////      eq.display_price_including_vat = Boolean(eq.include_vat);
 //     delete eq.include_vat;
////      eq.price_vat_rate_id = eq.vat_rate_id;
 //     delete eq.vat_rate_id;
////      const vatRateAsNumber = getVatRateById(vatRates, eq.price_vat_rate_id).number;
//
////      const costPriceComponents = calculateVat(eq.display_price_including_vat, eq.cost, vatRateAsNumber);
////      eq.price_net = costPriceComponents.net;
////      eq.price_vat = costPriceComponents.vat;
  //    delete eq.cost;
//
////      const reducedFromPriceComponents = calculateVat(eq.display_price_including_vat,  eq.reduced_from, vatRateAsNumber);
////      eq.price_reduced_from_net = reducedFromPriceComponents.net;
////      eq.price_reduced_from_vat = reducedFromPriceComponents.vat;
  //    delete eq.reduced_from;
    }
    return eq;
  });

  /**
   * Catering
   */
  payload.supplementary_catering = (formData.supplementary_catering || []).map(item => {
    const supplementaryCatering = { ...item };
//
//    // main item
//    supplementaryCatering.display_price_including_vat = Boolean(item.price_include_vat);
    delete supplementaryCatering.include_vat;
//
//    supplementaryCatering.price_vat_rate_id = supplementaryCatering.vat_rate_id;
    delete supplementaryCatering.vat_rate_id;
//    const vatRateAsNumber = getVatRateById(vatRates, supplementaryCatering.price_vat_rate_id).number;
//
//    const priceComponents = calculateVat(supplementaryCatering.display_price_including_vat, supplementaryCatering.price_per_person, vatRateAsNumber);
    delete supplementaryCatering.price_per_person;
//    supplementaryCatering.price_per_person_net = priceComponents.net
//    supplementaryCatering.price_per_person_vat = priceComponents.vat
//
//    const reducedFromPriceComponents = calculateVat(supplementaryCatering.display_price_including_vat, supplementaryCatering.reduced_from_unit, vatRateAsNumber);
    delete supplementaryCatering.reduced_from_unit;
//    supplementaryCatering.price_reduced_from_net = reducedFromPriceComponents.net
//    supplementaryCatering.price_reduced_from_vat = reducedFromPriceComponents.vat
//
//
//    // main items room data
//    supplementaryCatering.room_display_price_including_vat = Boolean(item.room_include_vat);
    delete supplementaryCatering.room_include_vat;
//
//    supplementaryCatering.room_price_vat_rate_id = supplementaryCatering.room_vat_rate_id;
    delete supplementaryCatering.room_vat_rate_id;
//    const roomVatRateAsNumber = getVatRateById(vatRates, supplementaryCatering.room_price_vat_rate_id).number;
//
//    const roomPriceComponents = calculateVat(supplementaryCatering.room_display_price_including_vat, supplementaryCatering.room_cost, roomVatRateAsNumber);
    delete supplementaryCatering.room_cost;
//    supplementaryCatering.room_price_net = roomPriceComponents.net
//    supplementaryCatering.room_price_vat = roomPriceComponents.vat
//
//    const reducedFromRoomPriceComponents = calculateVat(supplementaryCatering.room_display_price_including_vat, supplementaryCatering.room_reduced_from, roomVatRateAsNumber);
    delete supplementaryCatering.room_reduced_from;
//    supplementaryCatering.room_price_reduced_from_net = reducedFromRoomPriceComponents.net
//    supplementaryCatering.room_price_reduced_from_vat = reducedFromRoomPriceComponents.vat
//
    return supplementaryCatering;
  });
//
//
  payload.catering_additional_costings = (formData.catering_additional_costings || []).map(cateringAdditionalCostings => {
//    cateringAdditionalCostings.display_price_including_vat = Boolean(cateringAdditionalCostings.price_include_vat);
    delete cateringAdditionalCostings.include_vat;
//
//    cateringAdditionalCostings.price_vat_rate_id = cateringAdditionalCostings.vat_rate_id;
    delete cateringAdditionalCostings.vat_rate_id;
//    const additionalVatRateAsNumber = getVatRateById(vatRates, cateringAdditionalCostings.price_vat_rate_id).number;
//
//
//    const priceComponents = calculateVat(cateringAdditionalCostings.display_price_including_vat, cateringAdditionalCostings.cost, additionalVatRateAsNumber);
//    cateringAdditionalCostings.price_net = priceComponents.net;
//    cateringAdditionalCostings.price_vat = priceComponents.vat;
//
//    const reducedFromPriceComponents = calculateVat(cateringAdditionalCostings.display_price_including_vat, cateringAdditionalCostings.reduced_from, additionalVatRateAsNumber);
//    cateringAdditionalCostings.price_reduced_from_net = reducedFromPriceComponents.net;
//    cateringAdditionalCostings.price_reduced_from_vat = reducedFromPriceComponents.vat;
    return cateringAdditionalCostings;
  })

  /**
   * Accommodation
   */
  if(formData.accommodation && formData.accommodation.length > 0) {
    payload.accommodation = formData.accommodation.map(item => ({
      ...item,
//    display_price_including_vat: Boolean(item.include_vat),
      rooms_allocated: item.rooms_allocated || 0,
      // daylight saving hacks
      date_of_arrival: moment(item.date_of_arrival).add(12, "hours"),
      release_date: moment(item.release_date).add(12, "hours"),
    }));
  }

  if(payload.accommodation && payload.accommodation.length > 0) {
    payload.accommodation.map(accom => {
      delete accom.include_vat;

      if (!accom.included_in_package) {
//      accom.price_vat_rate_id = accom.vat_rate_id;
        delete accom.vat_rate_id;

//      const accomVatRateAsNumber = getVatRateById(vatRates, accom.price_vat_rate_id).number;
//
//      const accomPriceComponents = calculateVat(accom.display_price_including_vat,  accom.cost, accomVatRateAsNumber);
//      accom.price_net = accomPriceComponents.net;
//      accom.price_vat = accomPriceComponents.vat;
//
//      const accomReducedFromPriceComponents = calculateVat(accom.display_price_including_vat,  accom.reduced_from, accomVatRateAsNumber);
//      accom.price_reduced_from_net = accomReducedFromPriceComponents.net;
//      accom.price_reduced_from_vat = accomReducedFromPriceComponents.vat;
      }else{
        delete accom.price_net;
        delete accom.price_vat;
        delete accom.price_reduced_from_net;
        delete accom.price_reduced_from_vat;

      }

      if (!accom.breakfast_included) {
//      accom.breakfast_price_vat_rate_id = accom.breakfast_vat_rate_id;
        delete accom.breakfast_vat_rate_id;
//      const breakfastVatRateAsNumber = getVatRateById(vatRates, accom.breakfast_price_vat_rate_id).number;

//      accom.breakfast_display_price_including_vat = accom.breakfast_include_vat;
        delete accom.breakfast_include_vat;

//      const breakfastPriceComponents = calculateVat(accom.breakfast_display_price_including_vat,  accom.breakfast_cost, breakfastVatRateAsNumber);
//      accom.breakfast_price_net = breakfastPriceComponents.net;
//      accom.breakfast_price_vat = breakfastPriceComponents.vat;

//      const reducedFromPriceComponents = calculateVat(accom.breakfast_display_price_including_vat,  accom.breakfast_reduced_from, breakfastVatRateAsNumber);
//      accom.breakfast_price_reduced_from_net = reducedFromPriceComponents.net;
//      accom.breakfast_price_reduced_from_vat = reducedFromPriceComponents.vat;

//      delete accom.breakfast_reduced_from;
        delete accom.breakfast_cost;
      }else{
        delete accom.breakfast_price_net;
        delete accom.breakfast_price_vat;
      }
      if(accom.terms && accom.terms === 'contract')
      {
        delete accom.rooms_allocated;
        delete accom.release_date;
      }
      return accom;
    })
  }


  // /**
  //  * Misc
  //  */
  // payload.facilities_and_technicals = [
  //   {
  //     ...pick(formData, [
  //       "car_parking_number_spaces",
  //       "is_wheelchair_accessible",
  //       "is_dda_compliant",
  //       "has_nearby_parking",
  //       "virtual_tours",
  //       "wifi_link",
  //       "has_free_on_site_parking",
  //       "has_own_catering",
  //       "has_outside_catering",
  //       "has_outside_space",
  //       "number_of_restaurants",
  //       "catering_capacity_dinner_lunch",
  //       "reception_catering_capacity",
  //       "additional_value_notes",
  //       "csr_link",
  //       "csr_text",
  //       "notes_facilities_technical",
  //       "porterage_charge",
  //       "last_date_refurbished",
  //       "on_site_leisure_facilities",
  //       "on_site_leisure_facilities_text",
  //       "off_site_leisure_facilities",
  //       "off_site_leisure_facilities_text",
  //     ]),
  //     places_of_interest: formData.place_of_interest,
  //   },
  // ];

  /**
   * Commission
   */
  const commission_items = [];

  sections.forEach(section => {
    const commission_item = {
      type: section,
    };

    if (formData[`commission_total_${section}`]) {
      commission_item.rate = formData[`commission_rate_${section}`];
      commission_item.applied_on = formData[`commission_applied_on_${section}`];
      commission_item.include_vat =
        formData[`commission_include_vat_${section}`];
      commission_item.total_commission =
        formData[`commission_total_${section}`];
      commission_items.push(commission_item);
    }
  });

  if (commission_items.length) {
    commission_items.push({
      type: "total",
      total_cost_before_commission: formData.price_summary_total_inc_vat,
      total_commission: formData.commission_total,
    });
  }

  payload.commission_items = commission_items;

  /**
   * Summary
   */
  const summary = {
    summary_items: [],
  };

  sections.forEach(section => {
    const summary_item = {
      type: section,
    };

    if (formData[`price_summary_total_exc_vat_${section}`]) {
      summary_item.total_exc_vat =
        formData[`price_summary_total_exc_vat_${section}`];
      summary_item.reduced_from_exc_vat =
        formData[`price_summary_reduced_from_exc_vat_${section}`];
      summary_item.total_inc_vat =
        formData[`price_summary_total_inc_vat_${section}`];
      summary_item.reduced_from_inc_vat =
        formData[`price_summary_reduced_from_inc_vat_${section}`];
      summary_item.percentage_saving =
        formData[`price_summary_cost_saving_${section}`];
      summary.summary_items.push(summary_item);
    }
  });

  if (summary.summary_items.length) {
    summary.summary_items.push({
      type: "total",
      total_exc_vat: formData.price_summary_total_exc_vat,
      reduced_from_exc_vat: formData.price_summary_reduced_from_exc_vat,
      total_inc_vat: formData.price_summary_total_inc_vat,
      reduced_from_inc_vat: formData.price_summary_reduced_from_inc_vat,
      percentage_saving: formData.price_summary_cost_saving,
    });
  }

  payload.summary = summary;
  payload.amendment_confirmation = formData['amendment_confirmation'];
  try {
    const response = await axios.patch(`${window.API}/rfp/proposal`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

export { submitChecksheet };
