import {
  hasLicensedVenues,
  hasPremiumVenues,
  hasVenues,
  canEditVenue,
  canEditUsers,
  canCreateVenue,
  isInternal,
  isRoleSuperAdminOrTrinityEditor,
  isMarketingManager,
  canEditRoles,
  isRoleAccountManager,
  canSeeAnalytics,
  canSeeSubscriptions,
  canCreateSubscriptions,
  isRoleTrinityEdgeRFP,
  isRoleEdgeAdmin,
  canReadChecksheet,
  canAdministerCompany,
  canAdministerAgencies,
  isAgencyAdmin,
  isCompanyAdmin,
  isRoleTrinity,
  hasCorporateAccount,
  isHeadOfProcurement,
  isHeadOfEvents,
  isEventSpecialist,
  isCompanyUser,
  corporateAccountId,
  isAgencyUser, canViewEvents, isNonEventSpecialist, isSuperAgent
} from './permissions';
import {isAgency, isAgencyApproved} from "./auth";
import * as ROUTES from "../Router/corporate_routes";

const everybody = () => true;

const items = [
  {
    text: 'My EDGE',
    path: '/admin/my-edge',
    authTest: everybody,
  },
  {
    text: 'Venues',
    path: '/admin/venues',
    authTest: auth => isInternal(auth) || canEditVenue(auth) || canCreateVenue(auth),
  },
  {
    text: 'Agency',
    path: ROUTES.FRONTEND.AGENCY.ALL,
    authTest: auth => (isAgencyAdmin(auth) && isAgencyApproved(auth)),
  },
  // {
  //   text: 'Company',
  //   path: ROUTES.FRONTEND.COMPANY.ALL,
  //   authTest: auth => isAgencyAdmin(auth) || isCompanyAdmin(auth),
  // },
  {
    text: 'Your RFPs',
    path: '/admin/your-rfps',
    authTest: auth => {
      return !isInternal(auth) && !isRoleTrinityEdgeRFP(auth) && hasVenues(auth) && canReadChecksheet(auth)
    },
  },
  {
    text: 'Subscriptions',
    path: '/admin/subscriptions',
    authTest: auth => canSeeSubscriptions(auth),
  },
  {
    text: 'Site Visits',
    path: '/admin/site-visits',
    authTest: auth => isInternal(auth),
  },
  {
    text: 'Edge Plus',
    path: '/admin/edge-plus',
    authTest: auth => hasLicensedVenues(auth) && (isMarketingManager(auth) || isInternal(auth) || isRoleAccountManager(auth)),
  },
  {
    text: 'Agency Edge',
    path: '/admin/agency-edge',
    authTest: auth => (isMarketingManager(auth) || isInternal(auth) || isRoleAccountManager(auth)) && hasPremiumVenues(auth),
  },
  {
    text: 'Users',
    path: '/admin/users',
    authTest: auth => isInternal(auth) || canEditUsers(auth),
  },
  {
    text: 'Analytics',
    path: '/admin/analytics',
    authTest: auth => canSeeAnalytics(auth),
  },
  {
    text: 'Analytics',
    path: '/admin/analytics-stats',
    authTest: auth => hasVenues(auth) && !hasLicensedVenues(auth),
  },
  {
    text: 'Content Management',
    path: '/admin/content-management',
    authTest: auth => isInternal(auth),
  },
  {
    text: 'RFP',
    path: '/admin/rfp',
    authTest: auth => isRoleTrinityEdgeRFP(auth) || isRoleEdgeAdmin(auth),
  },
  {
    text: 'Corporate',
    path: '/admin/corporate-accounts',
    authTest: auth =>  (isRoleEdgeAdmin(auth)) || ((isCompanyAdmin(auth) || isEventSpecialist(auth) || isHeadOfEvents(auth) || isHeadOfProcurement(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth)),
  },
  {
    text: 'Events',
    path: '/admin/events',
    authTest: auth => isRoleEdgeAdmin(auth) || (isSuperAgent(auth) && isAgencyAdmin(auth))
  },
  {
    text: 'Company Settings',
    path: '/admin/users/company/edit',
    authTest: auth => !isInternal(auth) && isCompanyAdmin(auth)
  },
];

const secondaryItems = {

  'my-edge': [
    {
      text: 'Search venues',
      icon: 'fal fa-search',
      desc:
          'Search a database consisting of over 19,000 venues. Ensuring you find the perfect venue for your next event or meeting.',
      buttonLabel: 'Find venues on EDGE',
      path: '/advanced-search',
      authTest: everybody,
    },
    {
      text: 'Your saved searches',
      icon: 'fal fa-save',
      desc: 'Pick up from where you left off and review your saved searches.',
      buttonLabel: 'View saved searches',
      path: '/admin/my-edge/saved-searches',
      authTest: everybody,
    },
    {
      text: 'Your shortlists',
      icon: 'fal fa-heart',
      desc: 'Review venues you\'ve shortlisted.',
      buttonLabel: 'View shortlists',
      path: '/admin/my-edge/shortlists',
      authTest: everybody,
    },
    {
      text: 'Your notes',
      icon: 'fal fa-pencil-alt',
      desc: 'Read through your notes.',
      buttonLabel: 'View notes',
      path: '/admin/my-edge/notes',
      authTest: everybody,
    },
    {
      text: 'Your RFPs',
      icon: 'fal fa-file-invoice',
      desc:
          'Manage and view your Request for Proposals',
      path: '/admin/my-edge/your-rfps',
      authTest: auth => !isInternal(auth) && !hasVenues(auth),
      buttonLabel: 'View your proposals',
    },
    {
      text: 'Draft RFPs',
      icon: 'fal fa-file-invoice',
      desc: 'Manage and view your Drafted Enquiries',
      path: '/admin/my-edge/draft-rfps',
      authTest: auth => !isInternal(auth) && !hasVenues(auth),
      buttonLabel: 'View your draft proposals',
    },
  ],
  venues: [
    {
      text: 'Your venues',
      icon: 'fa fa-hotel',
      desc: 'View and manage your venues.',
      buttonLabel: 'View your venues',
      path: '/admin/venues/your-venues',
      authTest: auth => isInternal(auth) || (hasVenues(auth) && canEditVenue(auth)) || canCreateVenue(auth),
    },
    {
      text: 'Venue importer',
      icon: 'far fa-file-import',
      desc: 'Bulk import your venue data into EDGE Venues.',
      buttonLabel: 'Import your venues',
      path: '/admin/venues/venue-importer',
      authTest: auth => isInternal(auth) || canCreateVenue(auth),
    },
    {
      text: 'Meeting room importer',
      icon: 'far fa-file-import',
      desc: 'Bulk import your meeting room data into EDGE Venues.',
      buttonLabel: 'Import your meeting rooms',
      path: '/admin/venues/meeting-room-importer',
      authTest: auth => isInternal(auth) || (hasVenues(auth) && canEditVenue(auth)),
    },
    {
      text: 'Create a new venue',
      icon: 'fal fa-plus',
      desc: 'Add a single venue to EDGE Venues.',
      buttonLabel: 'Create new venue',
      path: '/admin/venues/create-a-new-venue',
      authTest: auth => isRoleSuperAdminOrTrinityEditor(auth) || canCreateVenue(auth),
    },
  ],
  subscriptions: [
    {
      text: 'All Subscriptions',
      path: '/admin/subscriptions/all',
      desc: 'View all current EDGE subscriptions',
      buttonLabel: 'All Subscriptions',
      authTest: auth => canSeeSubscriptions(auth),
    },
    {
      text: 'Create Subscription',
      path: '/admin/subscriptions/create',
      desc: 'Create a new EDGE subscription',
      buttonLabel: 'Create new subscription',
      authTest: auth => canCreateSubscriptions(auth),
    },
    {
      text: 'Reports',
      path: '/admin/subscriptions/reports',
      desc: 'View reporting on all EDGE subscriptions',
      authTest: auth => canCreateSubscriptions(auth),
    },
  ],
  'site-visits': [
    {
      text: 'Submit Site Report',
      icon: 'fal fa-plus',
      path: '/admin/site-visit/create',
      desc: 'Submit your site visit report for a venue',
      buttonLabel: 'New Report',
      authTest: auth => isInternal(auth),
    },
    {
      text: 'View My Site Visit Reports',
      path: '/admin/site-visit/my-reports',
      icon: 'far fa-eye',
      desc:
          'View a list of your submitted site visits, both approved and unapproved',
      buttonLabel: 'My Reports',
      authTest: auth => isInternal(auth),
    },
    {
      text: 'Awaiting Approval',
      icon: 'fas fa-clipboard-list',
      path: '/admin/site-visit/to-approve',
      desc:
          'View all submitted site visit reports that are still awaiting admin approval',
      buttonLabel: 'View Reports',
      authTest: auth => isInternal(auth),
    },
  ],
  users: [
    {
      text: 'Manage users',
      icon: 'fal fa-users',
      desc: 'Find and manage existing users',
      buttonLabel: 'Manage users',
      path: '/admin/users/manage',
      authTest: auth => isInternal(auth) || canEditUsers(auth),
    },
    {
      text: 'Create a new user',
      icon: 'fal fa-user-plus',
      desc: 'Add a new user to EDGE Venues.',
      buttonLabel: 'Create new user',
      path: '/admin/users/create',
      authTest: auth => isInternal(auth) || canEditUsers(auth),
    },
    {
      text: 'Activations',
      icon: 'fal fa-user-check',
      desc: 'Review which users have recently activated their account.',
      buttonLabel: 'View activations',
      path: '/admin/users/activations',
      authTest: auth => isInternal(auth),
    },
    {
      text: 'Manage agencies',
      icon: 'fal fa-user-tie',
      desc: 'Find and manage agencies',
      buttonLabel: 'Manage agencies',
      path: ROUTES.FRONTEND.AGENCY.ALL,
      authTest: auth => isAgencyAdmin(auth) || canAdministerAgencies(auth)
    },
    {
      text: 'Create Agency',
      icon: 'fal fa-user-tie',
      desc: 'Create an agency',
      buttonLabel: 'Create agency',
      path: ROUTES.FRONTEND.AGENCY.CREATE,
      authTest: auth => isRoleEdgeAdmin(auth)
    },
    {
      text: 'Manage companies',
      icon: 'fal fa-building',
      desc: 'Find and manage companies',
      buttonLabel: 'Manage companies',
      path: ROUTES.FRONTEND.COMPANY.ALL,
      authTest: auth => isRoleEdgeAdmin(auth) || (isSuperAgent(auth) && isAgencyAdmin(auth)), //canAdministerCompany(auth) && !isCompanyAdmin(auth)
    },
    {
      text: 'Manage Roles',
      path: '/admin/users/roles',
      icon: 'fal fa-cog',
      desc: 'Manage Roles and Permissions used by the system',
      buttonLabel: 'Manage Roles',
      authTest: auth => isInternal(auth),
    },
    {
      text: 'Create New Role',
      path: '/admin/roles/create',
      icon: 'fal fa-cog',
      desc: 'Adding a New role requires code changes from dev team',
      buttonLabel: 'Add a New Roles',
      authTest: auth => isInternal(auth) || canEditRoles(auth),
    },
  ],
  rfp: [],
  'corporate-accounts': [

    {
      text: 'Manage Corporate Accounts',
      icon: 'fal fa-users',
      desc: 'Find and manage existing corporate accounts',
      buttonLabel: 'Corporate Accounts',
      path: ROUTES.FRONTEND.CORPORATE.ALL,
      authTest: auth => isInternal(auth),
    },
    {
      text: 'Manage Public Events',
      icon: 'fal fa-calendar-alt',
      desc: 'Manage Public Events',
      buttonLabel: 'Public Events',
      path: ROUTES.FRONTEND.EVENTS.ALL,
      authTest: auth => isInternal(auth) || isRoleEdgeAdmin(auth),
    },
    {
      text: 'Events',
      icon: 'fal fa-list',
      desc: 'View all event data, manage the bookings and export data',
      buttonLabel: 'View all events',
      path: ROUTES.FRONTEND.BOOKED_EVENTS.ALL,
      authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyAdmin(auth) ) && hasCorporateAccount(auth),
    },
    {
      text: 'Documents',
      icon: 'fal fa-file-download',
      desc: 'View useful document and information',
      buttonLabel: 'View all documents',
      path: ROUTES.FRONTEND.CORPORATE.DOCUMENTS.CATEGORY,
      type: 'corporate',
      authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && hasCorporateAccount(auth),
    },
    {
      text: 'Event Calendar',
      icon: 'fal fa-calendar-alt',
      desc: 'View a calendar of company-wide events',
      buttonLabel: 'View calendar',
      path: ROUTES.FRONTEND.EVENTS.COMPANY_ALL,
      type: 'corporate',
      authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth) || isNonEventSpecialist(true)) && hasCorporateAccount(auth),
    },
    {
      text: 'Company Locations',
      icon: 'fal fa-map-marker-alt',
      desc: 'View key company locations and recommended venues',
      buttonLabel: 'View locations',
      path: ROUTES.FRONTEND.LOCATIONS.COMPANY_ALL,
      type: 'corporate',
      authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && hasCorporateAccount(auth),
    },
    {
      text: 'Manage Industry Events',
      icon: 'fal fa-edit',
      desc: 'Add key industry events to the calendar',
      buttonLabel: 'Manage industry events',
      path: ROUTES.FRONTEND.CORPORATE.INDUSTRY_EVENTS.MANAGE,
      type: 'corporate',
      authTest: auth => (isCompanyAdmin(auth)) && hasCorporateAccount(auth),
    },
  ],

  edgeAnalytics: [],
  edgeCms: [],
  'content-management': [
//    {
//    text: "Articles",
//          icon: "fal fa-newspaper",
//      buttonLabel: "Manage articles",
//      path: "/admin/content-management/articles",
//      authTest: isInternal,
//    },
//    {
//    text: "Offers",
//    icon: "fal fa-tag",
//      buttonLabel: "Manage offers",
//      path: "/admin/content-management/offers",
//      authTest: isInternal,
//    },
    {
      text: "Blog",
      icon: "fal fa-megaphone",
      desc: "Update venue blog.",
      buttonLabel: "Manage blog articles",
      path: "/admin/content-management/articles/blog",
      authTest: everybody,
    },
    {
      text: "News",
      icon: "fal fa-newspaper",
      desc: "Manage your news articles.",
      buttonLabel: "Manage articles",
      path: "/admin/content-management/articles/news",
      authTest: everybody,
    },
    {
      text: "Renovation News",
      icon: "fal fa-paint-roller",
      desc: "Manage your renovation news articles.",
      buttonLabel: "Manage articles",
      path: "/admin/content-management/articles/renovation-news",
      authTest: everybody,
    },
    {
      text: "Movers and Shakers",
      icon: "fal fa-bolt",
      desc: "Manage your renovation news articles.",
      buttonLabel: "Manage articles",
      path: "/admin/content-management/articles/movers-and-shakers",
      authTest: everybody,
    },
    {
      text: "Special Offers",
      icon: "fal fa-tag",
      desc: "Speical offers.",
      buttonLabel: "Manage offers",
      path: "/admin/content-management/offers/special-offers",
      authTest: everybody,
    },
    {
      text: "Commission Offers",
      icon: "fal fa-percent",
      desc: "Commission Offers.",
      buttonLabel: "Manage offers",
      path: "/admin/content-management/offers/commission-offers",
      authTest: everybody,
    },
    {
      text: "Agency Rates",
      icon: "fal fa-percent",
      buttonLabel: "Manage agency rates",
      path: "/admin/content-management/agency-rates",
      authTest: isInternal,
    },
    {
      text: "Fam Trips",
      icon: "fal fa-suitcase-rolling",
      buttonLabel: "View Fam Trips",
      path: "/admin/content-management/fam-trips",
      authTest: isInternal,
    },
    {
      text: "Destination Profiles",
      icon: "fal fa-globe-americas",
      buttonLabel: "Manage destination profiles",
      path: "/admin/content-management/destinations",
      authTest: isInternal,
    },
    {
      text: "SEO",
      icon: "fal fa-search",
      desc: "",
      buttonLabel: "Manage SEO",
      path: "/admin/content-management/seo",
      authTest: isRoleSuperAdminOrTrinityEditor,
    },
    {
      text: 'Analytics Image',
      icon: 'fal fa-analytics',
      desc: '',
      buttonLabel: 'Manage Analytics Image',
      path: '/admin/content-management/analytics-image',
      authTest: isRoleSuperAdminOrTrinityEditor,
    },
    {
      text: 'Venue Of The Week',
      icon: 'fas fa-building',
      desc: 'Set the Venue that appears on home page',
      buttonLabel: 'Manage Venue Of The Week',
      path: '/admin/content-management/venue-of-the-week',
      authTest: isRoleSuperAdminOrTrinityEditor,
    },
  ],
  agencyProfile: [
    {
      text: 'Agency Profile',
      desc: 'Agency Profile',
      buttonLabel: 'Agency Profile',
      path: ROUTES.FRONTEND.AGENCY.ALL,
      authTest: auth => isAgency
    }
  ],
  articles: [
    {
      text: 'Blog',
      icon: 'fal fa-megaphone',
      desc: 'Update venue blog.',
      buttonLabel: 'Manage blog articles',
      path: '/admin/content-management/articles/blog',
      authTest: everybody,
    },
    {
      text: 'News',
      icon: 'fal fa-newspaper',
      desc: 'Manage your news articles.',
      buttonLabel: 'Manage articles',
      path: '/admin/content-management/articles/news',
      authTest: everybody,
    },
    {
      text: 'Renovation News',
      icon: 'fal fa-paint-roller',
      desc: 'Manage your renovation news articles.',
      buttonLabel: 'Manage articles',
      path: '/admin/content-management/articles/renovation-news',
      authTest: everybody,
    },
    {
      text: 'Movers and Shakers',
      icon: 'fal fa-bolt',
      desc: 'Manage your renovation news articles.',
      buttonLabel: 'Manage articles',
      path: '/admin/content-management/articles/movers-and-shakers',
      authTest: everybody,
    },
  ],
  offers: [
    {
      text: 'Special Offers',
      icon: 'fal fa-tag',
      desc: 'Special offers.',
      buttonLabel: 'Manage offers',
      path: '/admin/content-management/offers/special-offers',
      authTest: everybody,
    },
    {
      text: 'Commission Offers',
      icon: 'fal fa-percent',
      desc: 'Commission Offers.',
      buttonLabel: 'Manage offers',
      path: '/admin/content-management/offers/commission-offers',
      authTest: everybody,
    },
  ],
};
const homepageItems = [
  {
    text: 'Home',
    path: '/',
  },
    {
    text: 'About',
    path: '/about',
  },
  {
    text: 'Advanced search',
    path:
        '/advanced-search?results=13326&back_projection=0&exhibition=0&location_type=Map&radius=24140.16&sortBy=Capacity&types=1',
  },
  {
    text: 'UK Locations',
    path: '/uk-venue-locations',
  },
  {
    text: 'Worldwide locations',
    path: '/worldwide-venue-locations',
  },
  {
    text: 'Site visits',
    path: '/site-visits',

  },
  {
    text: 'Offers',
    path: '/special-offers',
    submenu: [
      {
        text: 'Commission Offers',
        path: '/commission-offers',
      },
      {
        text: 'Agency Rates',
        path: '/agency-rates',
      },
      {
        text: 'FAM Trips',
        path: '/fam-trips',
      },
    ],
  },
  {
    text: 'Venue news',
    path: '/edge-venue-news',
  },
  {
    text: 'Industry insights',
    path: '/the-word',
  },
  {
    text: 'Agency Info',
    path: '/',
    submenu: [
      {
        text: 'Movers and Shakers',
        path: '/movers-and-shakers',
      },
      {
        text: 'Renovation News',
        path: '/renovation-news',
      },
      {
        text: 'Venue news',
        path: '/edge-venue-news',
      },
    ],
  },
  // {
  //     text: 'Suppliers directory',
  //     path: '/',
  //     submenu: []
  // },
];

const getMenuItems = (auth, menuItems) =>
    menuItems.filter(({ authTest }) => (authTest ? authTest(auth) : true));

export { getMenuItems, secondaryItems, homepageItems };

export default items;
