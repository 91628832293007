import React from 'react';
import Modal from 'Components/Modal';
import { ButtonSpan } from 'Components/Button';
import { LabelledText } from 'ui';

const CommissionPriceBreakdownModal = ({
                                         commissionItems,
                                         summaryItems,
                                         onClose,
                                         venueId,
                                       }) => {
  return (
      <Modal>
        <div className="modal">
          <div
              className="modal-inner"
              style={{
                width: 1200,
                maxWidth: '90%',
              }}
          >
            <ButtonSpan
                className="m-3 absolute pin-t pin-r z-10"
                onClick={onClose}
            >
              <i className="fas fa-times-circle fa-2x ml-3"/>
            </ButtonSpan>
            <div>
              <h2 className="mb-3 text-3xl">Commission Breakdown</h2>
              {commissionItems.map(item => item.step_id !== 9 ? (
                      <div className="mb-5 pt-3 border-t border-black" key={item.step.name + venueId}>
                        <h3 className="mb-3">{item.step.name}</h3>
                        <div className="flex flex-wrap">
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label="Total Cost Before Commission"
                              text={summaryItems.find(summaryItem => summaryItem.step_id === item.step.id).total_inc_vat}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Rate"
                              text={item.rate}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Applied on"
                              text={item.applied_on}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8"
                              label="Include VAT"
                              text={item.include_vat ? 'true' : 'false'}
                          />
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label="Total Commission"
                              text={item.total_commission}
                          />

                        </div>

                      </div>
                  ) : (
                      <div className="mb-5 pt-3 border-t border-black" key={item.step.name + venueId}>
                        <h3 className="mb-3">{item.step.name}</h3>
                        <div className="flex flex-wrap">
                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label="Total Cost Before Commission"
                              text={summaryItems.find(summaryItem => summaryItem.step_id === item.step.id).total_inc_vat}
                          />

                          <LabelledText
                              Wrapper="div"
                              className="mr-8 text-right"
                              label="Total Commission"
                              text={item.total_commission}
                          />

                        </div>

                      </div>
                  ),
              )}


            </div>
          </div>
        </div>
      </Modal>
  );
};

export default CommissionPriceBreakdownModal;
