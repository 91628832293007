import React from "react";
import { shape, number, bool, string, arrayOf } from "prop-types";
import TrueFalseList from "Components/TrueFalseList";
import { Label } from "Components/Form";
import {connect} from 'react-redux'
import {
  FaUtensils,
  FaParking,
  FaWheelchair,
  FaWifi,
  FaTree,
  FaInfo,
  FaDumbbell,
  FaRegPlusSquare,
  FaRegMinusSquare,
  FaFileAlt
} from "react-icons/fa";
import {venueInteraction} from "../../modules/analytics";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const SingleTab = ({ icon, title, active, mobile, ...rest }) => (
  <div
    className={`pointer p-2 md:p-4 hover:bg-brand hover:text-white ${
      active ? "bg-white" : "bg-light-grey"
    } lg:border-t ${mobile &&
      "border-r"} border-light-blue-grey col-w-1/2 lg:col-w-full`}
    {...rest}
  >
    <h5 className="uppercase flex text-xs sm:text-base">
      {icon} {title}
    </h5>
  </div>
);

class Specifications extends React.Component {
  static propTypes = {
    anchor: string.isRequired,
    data: shape({
      parkingSpaces: number,
      freeOnSiteParking: bool,
      nearbyParking: bool,
      wheelchairAccessible: bool,
      isDdaCompliant: bool,
      outsideSpace: bool,
      wifiLink: string,
      csrLink: string,
      csrText: string,
      leisure: shape({
        notes: string,
        onSite: arrayOf(
          shape({
            id: number.isRequired,
            name: string.isRequired,
          })
        ),
        offSite: arrayOf(
          shape({
            id: number.isRequired,
            name: string.isRequired,
          })
        ),
      }),
      catering: shape({
        internal: bool,
        external: bool,
        totalRestaurants: number,
        dinnerLunchCapacity: number,
        receptionCapacity: number,
      }).isRequired,
    }).isRequired,
    placeOfInterest: string,
    notes: string,
    filesOverview: arrayOf(
      shape({
        id: number.isRequired,
        name: string.isRequired,
        url: string.isRequired
      })
    )
  };

  constructor(props) {
    super(props);
    this.state = {
      tab: "catering",
      expandedFeatures: false,
      expandedFacilities: false,
    };

    this.tabs = {
      catering: {
        name: "Catering",
        icon: <FaUtensils className="mr-2 sm:mr-4" />,
      },
      parking: {
        name: "Parking",
        icon: <FaParking className="mr-2 sm:mr-4" />,
      },
      disabled: {
        name: "Disabled access",
        icon: <FaWheelchair className="mr-2 sm:mr-4" />,
      },
      leisure: {
        name: "Leisure",
        icon: <FaDumbbell className="mr-2 sm:mr-4" />,
      },
      wifi: {
        name: "Connectivity",
        icon: <FaWifi className="mr-2 sm:mr-4" />,
      },
      csr: {
        name: "CSR",
        icon: <FaTree className="mr-2 sm:mr-4" />,
      },
      misc: {
        name: "Miscellaneous",
        icon: <FaInfo className="mr-2 sm:mr-4" />,
      },
      usefulDocs: {
        name: "Other Useful Documents",
        icon: <FaFileAlt className="mr-2 sm:mr-4"/>,
      }
    };
    this.csrRef = React.createRef()
  }

  setActiveTab = tab => {
    this.props.venueInteraction({
      type: `${tab}_expanded`,
      data_score: this.props.data_score,
      impression_id: this.props.impression_id,
      id: this.props.venueId,
    })
    this.setState({ tab });
  };

  toggleFeatures = () => {
    this.setState({
      expandedFeatures: !this.state.expandedFeatures,
    });
  };

  toggleFacilities = () => {
    this.setState({
      expandedFacilities: !this.state.expandedFacilities,
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      }
    );
    if (this.cateringRef) {
      this.observer.observe(this.cateringRef);
    }
    if (this.facilitiesAndTechnicalRef) {
      this.observer.observe(this.facilitiesAndTechnicalRef);
    }
    if (this.featuresRef) {
      this.observer.observe(this.featuresRef);
    }
  }

  handleObserver(entities) {
    entities.map(entity => {
      if (entity.isIntersecting) {
        this.props.venueInteraction({
          type: entity.target.getAttribute('interactionKey'),
          data_score: this.props.data_score,
          impression_id: this.props.impression_id,
          id: this.props.venueId,
        })
        this.observer.unobserve(entity.target)
      }
    })
  }

  render() {
    const {
      // catering
      catering,
      // parking
      parkingSpaces,
      freeOnSiteParking,
      nearbyParking,
      // connectivity
      wifiLink,
      // disabled access
      wheelchairAccessible,
      isDdaCompliant,
      // leisure
      leisure,
      // csr
      csrLink,
      csrText,
      // misc
      placeOfInterest,
      outsideSpace,
      porterageCharge,
      // notes
      notes,
      usefulDocs
    } = this.props.data;

    const { mobile } = this.props;

    return (
      <React.Fragment>
        <span className="anchor sr-only" id={this.props.anchor}>
          Facilities &amp; Technical section
        </span>
        <section
          className="bg-white mb-3 shadow"
          id="features"
          ref={featuresRef => (this.featuresRef = featuresRef)}
          interactionKey={VENUE_TAGS.VIEW_FEATURES}>
          <h2
            className={`text-xl py-4 px-3 ${mobile &&
              "border-w-full flex items-center justify-between"}`}
            onClick={this.toggleFeatures}
          >
            Features
            {mobile && !this.state.expandedFeatures && (
              <FaRegPlusSquare className="text-base" />
            )}
            {mobile && this.state.expandedFeatures && (
              <FaRegMinusSquare className="text-base" />
            )}
          </h2>
          {(!mobile || (mobile && this.state.expandedFeatures)) && (
            <div className="flex flex-wrap">
              <div className="col-w-full lg:col-w-1/5 flex flex-wrap lg:block bg-light-grey border-t border-b border-light-blue-grey lg:border-t-0 lg:border-b-0">
                <SingleTab
                  active={this.state.tab === "catering"}
                  onClick={() => this.setActiveTab("catering")}
                  mobile={mobile}
                  title={this.tabs.catering.name}
                  icon={this.tabs.catering.icon}
                />
                <SingleTab
                  active={this.state.tab === "parking"}
                  onClick={() => this.setActiveTab("parking")}
                  mobile={mobile}
                  title={this.tabs.parking.name}
                  icon={this.tabs.parking.icon}
                />
                <SingleTab
                  active={this.state.tab === "disabled"}
                  onClick={() => this.setActiveTab("disabled")}
                  mobile={mobile}
                  title={this.tabs.disabled.name}
                  icon={this.tabs.disabled.icon}
                />
                {((Array.isArray(leisure.onSite) && leisure.onSite.length) ||
                  (Array.isArray(leisure.offSite) && leisure.offSite.length) ||
                  leisure.notes) && (
                  <SingleTab
                    active={this.state.tab === "leisure"}
                    mobile={mobile}
                    onClick={() => this.setActiveTab("leisure")}
                    title={this.tabs.leisure.name}
                    icon={this.tabs.leisure.icon}
                  />
                )}
                {wifiLink && (
                  <SingleTab
                    active={this.state.tab === "wifi"}
                    mobile={mobile}
                    onClick={() => this.setActiveTab("wifi")}
                    title={this.tabs.wifi.name}
                    icon={this.tabs.wifi.icon}
                  />
                )}
                {(csrLink || csrText) && (
                  <SingleTab
                    active={this.state.tab === "csr"}
                    title={this.tabs.csr.name}
                    mobile={mobile}
                    onClick={() => this.setActiveTab("csr")}
                    icon={this.tabs.csr.icon}
                  />
                )}
                {(placeOfInterest || outsideSpace) && (
                  <SingleTab
                    active={this.state.tab === "misc"}
                    mobile={mobile}
                    onClick={() => this.setActiveTab("misc")}
                    title={this.tabs.misc.name}
                    icon={this.tabs.misc.icon}
                  />
                )}
                {(Array.isArray(usefulDocs) && usefulDocs.length > 0) && (
                  <SingleTab
                    active={this.state.tab === "usefulDocs"}
                    mobile={mobile}
                    onClick={() => this.setActiveTab("usefulDocs")}
                    title={this.tabs.usefulDocs.name}
                    icon={this.tabs.usefulDocs.icon}
                  />
                )}
              </div>
              <div className="col-w-full lg:col-w-4/5 px-3 py-4 lg:py-0 font-normal">
                <h3 className="mb-4 flex items-end">
                  {this.tabs[this.state.tab].icon}{" "}
                  {this.tabs[this.state.tab].name}
                </h3>
                {this.state.tab === "catering" && (
                  <div ref={cateringRef => (this.cateringRef = cateringRef)} interactionKey={VENUE_TAGS.VIEW_CATERING.VIEW_CATERING}>
                    <TrueFalseList
                      items={[
                        {
                          name: "On Site",
                          value: catering.internal,
                        },
                        {
                          name: "External",
                          value: catering.external,
                        },
                      ]}
                    />
                    {typeof catering.totalRestaurants !== "undefined" ? (
                      <p>
                        Total Restaurants:{" "}
                        <strong>{catering.totalRestaurants}</strong>
                      </p>
                    ) : null}
                    {typeof catering.dinnerLunchCapacity !== "undefined" ? (
                      <p>
                        Dinner/Lunch Capacity:{" "}
                        <strong>{catering.dinnerLunchCapacity}</strong>
                      </p>
                    ) : null}
                    {typeof catering.receptionCapacity !== "undefined" ? (
                      <p>
                        Reception Capacity:{" "}
                        <strong>{catering.receptionCapacity}</strong>
                      </p>
                    ) : null}
                  </div>
                )}
                {this.state.tab === "parking" && (
                  <div ref={parkingRef => (this.parkingRef = parkingRef)}>
                    <TrueFalseList
                      items={[
                        {
                          name: "Free On Site Parking",
                          value: freeOnSiteParking,
                        },
                        {
                          name: "Nearby Parking",
                          value: nearbyParking,
                        },
                      ]}
                    />
                    {typeof parkingSpaces !== "undefined" ? (
                      <p>
                        On Site Parking Spaces: <strong>{parkingSpaces}</strong>
                      </p>
                    ) : null}
                  </div>
                )}
                {this.state.tab === "wifi" && wifiLink && (
                  <p>
                    <a
                      rel="noopener noreferrer"
                      href={wifiLink}
                      target="_blank"
                      className="font-normal"
                    >
                      Statement
                    </a>
                  </p>
                )}
                {this.state.tab === "disabled" && (
                  <TrueFalseList
                    items={[
                      {
                        name: "Wheelchair Accessible",
                        value: wheelchairAccessible,
                      },
                      {
                        name: "DDA Compliant",
                        value: isDdaCompliant,
                      },
                    ]}
                  />
                )}
                {this.state.tab === "leisure" && (
                  <>
                    {Array.isArray(leisure.onSite) && leisure.onSite.length ? (
                      <div>
                        <Label text="On Site" />
                        <p>
                          {leisure.onSite.map(item => item.name).join(", ")}
                        </p>
                      </div>
                    ) : null}
                    {Array.isArray(leisure.offSite) &&
                    leisure.offSite.length ? (
                      <div>
                        <Label text="Off Site" />
                        <p>
                          {leisure.offSite.map(item => item.name).join(", ")}
                        </p>
                      </div>
                    ) : null}
                    {leisure.notes ? (
                      <div>
                        <Label text="Notes" />
                        <p>{leisure.notes}</p>
                      </div>
                    ) : null}
                  </>
                )}
                {this.state.tab === "csr" && (
                  <div ref={this.csrRef}>
                    {csrLink ? (
                      <p>
                        <a
                          href={csrLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-normal"
                          onClick={() => {
                            this.props.venueInteraction({
                              type: VENUE_TAGS.VIEW_CSR_LINK,
                              data_score: this.props.data_score,
                              impression_id: this.props.impression_id,
                              id: this.props.venueId,
                            })
                          }}
                        >
                          View statement
                        </a>
                      </p>
                    ) : null}
                    {csrText ? (
                      <div>
                        <h4 className="mb-2">Statement</h4>
                        <p>{csrText}</p>
                      </div>
                    ) : null}
                  </div>
                )}
                {this.state.tab === "miscellaneous" && (
                  <>
                    {placeOfInterest ? (
                      <div>
                        <Label text="Places of Interest" />
                        <p>{placeOfInterest}</p>
                      </div>
                    ) : null}
                    <TrueFalseList
                      items={[
                        {
                          name: "Outside Space",
                          value: outsideSpace,
                        },
                      ]}
                    />
                    {porterageCharge ? (
                      <div>
                        <h4 className="mb-2">Porterage Charge</h4>
                        <p>
                          &pound;
                          {porterageCharge}
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
                {this.state.tab === "usefulDocs" && (
                  <>
                    {usefulDocs && (
                      <div>
                        {usefulDocs.map(file => {
                          return (
                            <ul className="list-reset">
                              <li key={file.id} className="mb-3 leading-normal">
                                <a href={file.url}>
                                  {file.name}
                                </a>
                              </li>
                            </ul>
                          )
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </section>
        {notes ? (
          <section
            className="bg-white mb-3 shadow"
            id="facilities-and-technical"
            ref={facilitiesAndTechnicalRef => (this.facilitiesAndTechnicalRef = facilitiesAndTechnicalRef)}
            interactionKey={VENUE_TAGS.VIEW_FACILITIES}
          >
            <h2
              className={`text-xl py-4 px-3 ${mobile &&
                "border-b border-light-blue-grey w-full flex items-center justify-between"}`}
              onClick={this.toggleFacilities}
            >
              Facilities and Technical notes
              {mobile && !this.state.expandedFacilities && (
                <FaRegPlusSquare className="text-base" />
              )}
              {mobile && this.state.expandedFacilities && (
                <FaRegMinusSquare className="text-base" />
              )}
            </h2>
            {(!mobile || (mobile && this.state.expandedFacilities)) && (
              <div className="px-3 pb-4">
                <p className="font-normal text-base">{notes}</p>
              </div>
            )}
          </section>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect(null, {venueInteraction})(Specifications)