import React, { useState } from "react";
import { connect } from "react-redux";
import { func, object } from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { reduxForm, initialize, getFormValues } from "redux-form";
import { ButtonSpan, ButtonTransparent } from "Components/Button";
import { LiveSearch } from "Components/Form";
import Location from "Components/Search/Location";

const form = "simple-search-start";

const SearchBanner = ({
  footer,
  capacity,
  destroy,
  handleSubmit,
  initialize,
  history,
}) => {
  const [location, setLocation] = useState(null);

  const submit = () => {};

  React.useEffect(() => {
    if (location) {
      const locationParams = location ? location : {};
      initialize(
        "simple-search",
        {
          ...locationParams,
          sortBy: "Distance",
          capacity,
        },
        {
          updateUnregisteredFields: true,
        }
      );
      history.push("/search");
      reset();
    }
  });

  const reset = () => {
    destroy(form);
    setLocation(null);
  };

  return (
    <div className={footer ? "py-16" : ""}>
      <form onSubmit={handleSubmit(submit)} autoComplete="off">
        {!footer && (
          <h1 className="mdtext-shadow mb-5 text-white font-normal font-body text-2xl">
            Find the perfect venue for your next event
          </h1>
        )}
        <div className="md:flex">
          <Location
            formId={form}
            id="location-search"
            icon="/img/map-marker.svg"
            label="Location"
            labelClassName="sr-only"
            name="location"
            placeholder="LOCATION"
            fieldClassName="w-full"
            wrapperClassName="w-full mb-3 md:mr-3"
            noBottom
            white
            onPlaceFound={(lat, lng, name, { placeArea }, addressType) => {
              setLocation({
                location: name,
                lat: lat,
                lng: lng,
                placeArea: placeArea,
                addressType,
              });
            }}
          />
          <LiveSearch
            id="venue-search"
            formId={form}
            url={`${window.API}/venues/quick`}
            onSelect={result => {
              history.push(`/venue/${result.slug}`);
            }}
            icon="/img/hotel-solid.svg"
            label="Venue name"
            labelClassName="sr-only"
            name="venue"
            placeholder="VENUE NAME"
            fieldClassName="w-full"
            wrapperClassName="w-full mb-3 md:mr-3 lg:mr-5 xl:mr-8"
            noBottom
            white
            getText={item => item.display_name}
          />
          <ButtonTransparent
            submit
            onDark
            classes={`mb-3 lg:ml-5 xl:ml-8 w-full${
              !footer ? " mdtext-shadow" : " border-white border"
            }`}
          >
            Find
            <i className="fas fa-search ml-3" />
          </ButtonTransparent>
        </div>
      </form>
      <div className="sm:flex sm:flex-row-reverse justify-between">
        <ButtonSpan
          className="block pointer uppercase font-bold text-white self-end mt-3 mb-3 ms:mb-0"
          onClick={() => {
            reset();
          }}
        >
          <i className="fas fa-minus-circle mr-2" />
          Clear all
        </ButtonSpan>
        <Link
          to="/advanced-search"
          className={`${
            !footer ? "mdtext-shadow " : ""
          }font-normal text-white leading-loose block`}
        >
          Advanced Search
        </Link>
      </div>
    </div>
  );
};

SearchBanner.propTypes = {
  initialize: func.isRequired,
  history: object.isRequired,
};

export default withRouter(
  reduxForm({ form })(
    connect(
      state => ({
        capacity: (getFormValues(form)(state) || {}).capacity,
      }),
      { initialize }
    )(SearchBanner)
  )
);
