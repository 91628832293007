import { GBPFormatter } from "../../utils";

export default (reduce_from, total) => {
    let savingPrice = "";
        savingPrice = (
            parseFloat(reduce_from) - parseFloat(total)
        ).toFixed(2);
    return GBPFormatter.format(savingPrice);
};


