import React from "react";
import {ButtonBlack, ButtonPrimary} from "Components/Button";

const RfpSubmit = ({
                     page,
                     prevPage,
                     enabled = true,
                     hasVenues,
                     editEnquiry,
                     setSubmitToAgent,
                     setEnquiryToSubmitted,
                     isSuperAgent = false,
                     isInternal = null,
                     isAgency = null,
                     setSubmitToDraft,
                     enquiry
                   }) => {
  let buttonText = "Save Draft";
  if (enquiry && enquiry.enquiry_status.code !== "DRAFT") {
    buttonText = "Save";
  }
  let showSendEnquiry = (!enquiry && hasVenues) || (enquiry && enquiry.enquiry_status.code === "DRAFT" && hasVenues);
  return (
    <div className="justify-end flex flex-col md:flex-row">
      {prevPage && (
        <ButtonBlack
          onClick={() => prevPage()}
          classes="px-5 md:mr-8"
          style={{height: 57, lineHeight: "57px"}}
        >
          <i className="pr-2 fas fa-angle-left"></i>
          Back
        </ButtonBlack>
      )}
      <ButtonPrimary
        classes={`px-5 md:mr-8`}
        submit
        // disabled={!enabled}
        onClick={() => {
          if (page === 2) {
            setSubmitToDraft(true)
          }
        }}
        style={{height: 57, lineHeight: "57px"}}
      >
        {page === 2 ? buttonText : "Tell us more"}
      </ButtonPrimary>
      {(showSendEnquiry && page === 2) && (
        <ButtonPrimary
          classes="px-5"
          submit
          // disabled={!enabled}
          style={{height: 57, lineHeight: "57px"}}
          onClick={() => setEnquiryToSubmitted(true)}
        >
          Send Enquiry
        </ButtonPrimary>
      )}
      {(!hasVenues && page === 2) && (
        <ButtonPrimary
          classes="px-5 ml-2"
          submit
          // disabled={!enabled}
          onClick={() => setSubmitToAgent(true)}
          style={{height: 57, lineHeight: "57px"}}
        >
          Submit to Agent
        </ButtonPrimary>
      )}
    </div>
  );
};

export default RfpSubmit;
