import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { string, bool, shape, array, object } from "prop-types";
import { venueInteraction } from "modules/analytics";
import MobileGallery from "./MobileGallery";
import { Select } from "Components/Form";
import { scroller } from "react-scroll";
import ReactTooltip from 'react-tooltip';
import {useLocation} from "react-router-dom";
import {VENUE_TAGS} from "../../modules/analytics/tags";

const getImgs = (images, featuredImage) => {
  const imgs = [...images];
  // just a placeholder if nothing
  if (!featuredImage && !imgs.length) {
    return ["/img/venue-placeholder.jpg"];
  }
  // just images if nothing featured
  if (!featuredImage) {
    return imgs.map(img =>
      `${img.path}`
    );
  }
  // make sure featured is the first one
  const filtered = imgs.filter(img => img.id !== featuredImage.id);
  filtered.unshift(featuredImage);
  return filtered.map(img =>
    `${img.path}`
  );
};

MobileBanner.propTypes = {
  featuredImg: object,
  images: array,
  name: string.isRequired,
  hasMeetingRooms: bool.isRequired,
  hasHealthAndSafety: bool.isRequired,
  hasContacts: bool.isRequired,
  anchors: shape({
    details: string.isRequired,
    contacts: string.isRequired,
    meetingRooms: string.isRequired,
    location: string.isRequired,
    facilitiesAndTechnical: string.isRequired,
    destination: string.isRequired,
  }).isRequired,
};

const allOptions = [
  {
    value: "details",
    text: "Venue details",
  },
  {
    value: "meeting-rooms",
    text: "Meeting rooms",
  },
  {
    value: "features",
    text: "Features",
  },
  {
    value: "facilities-and-technical",
    text: "Facilities and Technical notes",
  },
  {
    value: "offers",
    text: "Offers/Rates",
  },
  {
    value: "pricing",
    text: "Prices",
  },
  /*#Hidden Site Visits*/
  {
    value: "site-visit-reports",
    text: "Site Visit Insights",
  },
  {
    value: "health-and-safety",
    text: "Health And Safety",
  },
  {
    value: "news",
    text: "Venue News",
  },
  {
    value: "insights",
    text: "EDGE Insights",
  },
];

function MobileBanner({
  featuredImg,
  name,
  hasMeetingRooms,
  covid,
  images,
  venueInteraction,
  hasSiteVisitReports,
  venueId,
  impression_id,
  data_score,
  renderPricing,
  isInternal,
  isLicensed,
  virtualTours,
  facilitiesAndTechnical,
  hasHealthAndSafety,
  hasOffers,
  hasMeetingRoomFor10,
  hasArticles,
}) {
  const track = type =>
    venueInteraction({
      type,
      id: venueId,
      impression_id,
      data_score,
    });

  const location = useLocation();
  useEffect(()=> {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({behavior: "smooth"})
      }
    } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
  }, [location,])

  const [selected, setSelected] = useState(null);

  const options = allOptions.map(option => {
    if (option.value === "meeting-rooms" && !hasMeetingRooms) return null;
    if (option.value === "facilities-and-technical" && !facilitiesAndTechnical)
      return null;
    if (option.value === "offers" && !hasOffers) return null;
    if (option.value === "site-visit-reports" && !hasSiteVisitReports) return null;
    if (option.value === "health-and-safety" && !hasHealthAndSafety) return null;
    if (option.value === "pricing" && !hasMeetingRoomFor10) return null;
    if (option.value === "news" && !hasArticles) return null;

    return option;
  });

  const filteredOptions = options.filter(option => option !== null);

  return (
    <React.Fragment>
      <MobileGallery
        name={name}
        venueId={venueId}
        impression_id={impression_id}
        data_score={data_score}
        images={getImgs(images, featuredImg)}
        covid={covid}
      />
      <div className="bg-white py-4 container flex flex-wrap">
        <div className="col col-w-2/3">
          <Select
            placeholder="Jump to section:"
            meta={{ touched: true }}
            options={filteredOptions}
            wrapperClassName="mb-0"
            labelClassName="mb-0"
            label={''}
            input={{
              name: "jump_to",
              value: selected,
              onChange: ({ target }) => {
                setSelected(target.value);
                scroller.scrollTo(target.value, {
                  smooth: true,
                  offset: -80
                });
                // window.location.hash = target.value;
              },
            }}
          />
        </div>
        <div className="col col-w-1/3 flex items-end justify-between">
          {virtualTours.length && isLicensed ? (
            <a
              href={virtualTours}
              onClick={() => {
                track(VENUE_TAGS.VIEW_VIRTUAL_TOUR);
              }}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col z-10 items-center justify-center"
            >
              <div className="flex" style={{ height: 24 }}>
                <img src="/img/tour-red.svg" alt="Tour" />
              </div>
              <span className="font-heading font-bold uppercase text-dark-red text-xs">
                Tour
              </span>
            </a>
          ) : null}
        </div>
        {!isInternal ? (
            <div
                className="absolute pin-t pin-r py-1 px-2 bg-edge-blue uppercase text-white text-sm font-normal"
                style={{ backgroundColor: "rgba(0, 153, 255)", top: '71px' }}
            >
               <span
                   data-tip
                   data-for="clickme"
                   data-event="click"
                   className="flex items-center pointer"
               >
            Covid - Our Commitment
          </span>
              <ReactTooltip
                  id="clickme"
                  effect="solid"
                  type="light"
                  clickable={true}
                  className="tooltip-shadow op-1"
                  globalEventOff="click"
                  place={'bottom'}
              >
                <div className="flex text-transform-none">
                  <p>Whilst the capacities currently shown on the platform are pre-COVID, our commitment to you is to work together with the venues to find out the capacities that are relevant to the current social distancing measures.</p>
                </div>
              </ReactTooltip>
            </div>

        ) : null}
      </div>
      <div className="container pt-3 bg-grey">
        <div>{renderPricing()}</div>
      </div>
    </React.Fragment>
  );
}

export default connect(undefined, { venueInteraction })(MobileBanner);
