import React, { useState, useEffect } from "react";
import { getSiteVisitReport } from "services/siteVisitService";
import Loading from "Components/Loading";
import { token } from "modules/auth";
import { connect } from "react-redux";
import _get from "lodash/get";
import moment from "moment";
import { ButtonBrand } from "Components/Button";
import TitledBlock from "./Components/TitledBlock";
import ImageGallery from "react-image-gallery";
import BackgroundImage from "../../Components/General/BackgroundImage";
import {venueInteraction} from "../../modules/analytics";

const SiteVisitFull = props => {
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getSiteVisitReport(props.match.params.siteVisitId, props.token)
      .then(response => {
        setReport(response.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.match.params.siteVisitId, props.token]);

  useEffect(() => {
    if (report && report.visited) {
      props.venueInteraction({
        id: report.visited.id,
        type: 'venue_site_visit_viewed',
        data_score: report.visited.data_score,
        impression_id: report.visited.impression_id
      })
    }
  }, [report])

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div>
        Oops, we couldn't find the site visit report that you requested. Please
        try again or contact our technical team if the issue persists.
      </div>
    );
  }

  return <RenderSiteVisitFull {...report} />;
};

const RenderSiteVisitFull = props => {
  const image = _get(
    props,
    "visited.featured_image.path",
    "/img/venue-placeholder.jpg"
  );
  let visit_date = `${moment(props.visit_date_from).format("MMMM Do YYYY")}`;

  // Add second date only if the visit was more than 1 day
  if (props.visit_date_from !== props.visit_date_to) {
    visit_date = `${visit_date} - ${moment(props.visit_date_to).format(
      "MMMM Do YYYY"
    )}`;
  }

  const getImages = images => {
    const arr = [...images];

    return arr.map(img => ({
      original: img.url,
      thumbnail: img.url,
      renderItem: () => (
        <div className="re-16/9 relative max-w-full">
          <BackgroundImage
              nonDiv={true}
              alt={"Gallery"}
              className={"absolute-center"}
              imagePath={img.path}
              edits={{
                "resize": {
                  "width": 1400,
                  "fit": "cover"
                }}}
          />
        </div>
      ),
      renderThumbInner: () => (
        <BackgroundImage
            nonDiv={true}
            alt={"Gallery"}
            imagePath={img.path}
            width={92}
            height={92}
            edits={{
              "resize": {
                "width": 92,
                "height": 92,
                "fit": "cover"
              }}}
        />
      )
    }));
  };
  return (
    <div className="bg-off-white pt-2">
      <div className="container mx-auto bg-white py-5">
        <div className="row p-5">
          <div className="col col-w-full lg:col-w-1/2 flex flex-col">
            <div className="my-auto">
              <div className="mb-2 ">
                <h1 className="text-4xl xl:text-5xl font-bold mb-4">
                  {props.visited.display_name}
                </h1>
                <div className="col lg:col-w-1/3">
                  <div className="row">
                  <ButtonBrand
                      Element="a"
                      href={`/venue/${props.visited.slug}`}
                  >
                    View Venue Details
                  </ButtonBrand>
                  </div>
                </div>

              </div>
              <div className="mt-4 p-4 bg-light-grey">
                <div className={'flex justify-between items-center'}>
                  <div>
                    <h3 className="text-dark-grey mb-2">Venue site visit report</h3>
                    <h5 className="mb-2">
                      Author:{" "}
                {props.author_visible && props.author && typeof props.author !== 'undefined'
                          ? `${props.author.first_name} ${props.author.surname}`
                          : `Anonymous`}{" "}
                      (Agent)
                    </h5>
                    <h5>Visited: {visit_date}</h5>
                  </div>
                  <div>
                    {
                      props.author_image_Visible && props.author && props.author.profile_image_path  ?
                          <BackgroundImage
                              imagePath={props.author ? props.author.profile_image_path : ''}
                              className="bg-cover bg-center rounded-full h-24 w-24 flex items-center justify-center"
                              edits={{
                                "roundCrop": true,
                                "resize": {
                                  "width": 150,
                                  "height": 150,
                                  "fit": "cover"
                                }
                              }} />
                          : null
                    }
                </div>

                </div>
                </div>
              </div>
          </div>
          <div className="col col-w-full lg:col-w-1/2 text-center">

            <BackgroundImage
                imagePath={image}
                nonDiv={true}
                alt="Venue Featured"
                className="mt-4 lg:mt-0"
                edits={{
                  "resize": {
                    "width": 600,
                    "height": 350,

                  }
                }} />
          </div>
        </div>
        <div className="row p-5">
          <div className="col col-w-full">
            {props.is_legacy ?
                <div className={'text-lg'}>
                  <h2 className="mb-2">{'Impressions'}</h2>
                  <p className="mb-2"  dangerouslySetInnerHTML={{
                    __html:  props.impressions
                  }}/>
                </div>:
                <TitledBlock title="Impressions" className="text-lg">
                  <p>{ props.impressions}</p>
                </TitledBlock>
            }
            {props.is_legacy ?
                <div className={'text-lg'}>
                  <h2 className="mb-2">{'Considerations'}</h2>
                  <p className="mb-2"  dangerouslySetInnerHTML={{
                    __html:  props.considerations
                  }}/>
                </div> :
                <TitledBlock title="Considerations" className="mt-8 text-lg">
                  <p>{props.considerations}</p>
                </TitledBlock>
            }
          </div>
        </div>
        <div className="row p-5">
          {props.visited.images.length > 0 && (
            <div className="col col-w-full lg:col-w-1/2">
              <TitledBlock title="Images" className="">
                <div className="row">
                  <div className="col">
                    <ImageGallery
                      showPlayButton={false}
                      showFullscreenButton={false}
                      items={getImages(
                        props.images.length > 0 ? props.images : props.visited.images
                      )}
                    />
                  </div>
                </div>
              </TitledBlock>
            </div>
          )}
          {props.media_links.length > 0 && (
            <div className="col col-w-full lg:col-w-1/2">
              <TitledBlock title="Video" className="">
                <iframe
                  title="youtube video"
                  className="w-full h-64"
                  id="ytplayer"
                  type="text/html"
                  src={props.media_links[0].path.replace("watch?v=", "embed/")}
                  frameborder="0"
                ></iframe>
              </TitledBlock>
            </div>
          )}
        </div>
        <div className="row p-5">
          {props.contacts.length > 0 && (
            <div className="col col-w-full lg:col-w-1/2">
              <TitledBlock title="Contacts" className="">
                <div className="flex flex-col sm:flex-row">
                  {props.contacts.map((contact, index) => {
                    return (
                      <div className="pr-4 mb-2" key={index}>
                        <span className="block">{contact.name}</span>
                        <span className="block">{contact.position}</span>
                        <span className="block">{contact.telephone}</span>
                        <span className="block">{contact.email}</span>
                      </div>
                    );
                  })}
                </div>
              </TitledBlock>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  token: token(state),
}), {venueInteraction})(SiteVisitFull);
