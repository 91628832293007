import axios from 'axios';
import * as ROUTES from '../../Router/rfp_routes';
import {
  DRAFT_ENQUIRY_REQUEST,
  DRAFT_ENQUIRY_RECEIVED_REQUEST,
  DRAFT_ENQUIRY_RECEIVED_ERROR,
  DRAFT_ENQUIRY_UNLOAD
} from '../reducers/types'

export const draftEnquiryRequest = () => ({
  type: DRAFT_ENQUIRY_REQUEST
});

export const draftEnquiryReceivedRequest = (data) => ({
  type: DRAFT_ENQUIRY_RECEIVED_REQUEST,
  data
});

export const draftEnquiryReceivedError = error => ({
  type: DRAFT_ENQUIRY_RECEIVED_ERROR,
  error
});

export const unloadDraftEnquiry = () => ({
  type: DRAFT_ENQUIRY_UNLOAD
});

export const fetchDraftEnquiry = (shareId) => {
  return (dispatch) => {
    dispatch(draftEnquiryRequest());
    return axios.get(window.API + ROUTES.API.BRIEF.PUBLIC.replace(':shareId', shareId))
      .then(response => dispatch(draftEnquiryReceivedRequest(response.data)))
      .catch(error => dispatch(draftEnquiryReceivedError(error)));
  }
}