// we're thunking here so we don't have to pass the whole form values state around the entirity of AS
// (avoiding re-renders on every input change)

import axios from "axios";
import { getFormValues } from "redux-form";
import queryString from "query-string";
import { omit } from "lodash";

export default (history, location, localStateSetter, v) => (d, getState) => {
  localStateSetter.loading();
  const values = { ...getFormValues("location-search")(getState()) };
  values.location_type = "Map";

  const type = values.venueType_id;
  delete values.venueType_id;
  if (type) {
    values.types = [type];
  }

  const params = {
    ...values,
    ...v,
  };

  Object.keys(params).forEach(v => {
    if (params[v] === "") delete params[v];
  });

  axios
    .get(`${window.NODE_AS}/`, {
      params,
    })
    .then(r => {
      localStateSetter.results(r.data.data, r.data.count);
      history.push(
        `/venues-in-${location}?results=${r.data.count}&${queryString.stringify(
          omit(params, ["placeArea"])
        )}`
      );
    })
    .catch(err => {
      localStateSetter.error();
    });
};
