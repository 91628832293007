import axios from "axios";

export const cancelBookedEvents = async (
    { cancellationReason, eventId },
    token
) => {
    if (cancellationReason) {
        try {
            await axios.post(
                `${window.API}/event/${eventId}/cancel`,
                {
                    cancellation_reason: cancellationReason,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            return { result: true };
        } catch (error) {
            return { error };
        }
    }
};

