import React, {useState} from "react";
import moment from "moment";
import {ButtonSpan} from "Components/Button";
import {LabelledText, WhitePanelWithHeader} from "ui";

const Col = ({title, text}) => (
  <div className="md:w-1/2 lg:w-1/4 pr-1">
    <LabelledText label={title} text={text}/>
  </div>
);

const BoolWithNotes = ({title, bool, notes}) =>
  bool ? (
    <div className="flex">
      <Col title={title} text={bool ? "Yes" : "No"}/>
      {notes && (
        <div className="md:w-1/2 lg:w-3/4">
          <LabelledText label={`${title} Notes`} text={notes}/>
        </div>
      )}
    </div>
  ) : null;

const BriefSummary = ({
                        expanded,
                        number_of_delegates,
                        date_from,
                        date_to,
                        date_flexible,
                        bedrooms_required,
                        bedroom_dates,
                        bedroom_additional_notes,
                        room_style,
                        back_projection,
                        syndicate_room,
                        syndicate_room_notes,
                        exhibition_space,
                        exhibition_space_notes,
                        private_dining,
                        private_dining_notes,
                        any_equipment,
                        any_equipment_notes,
                        date_flexible_notes,
                        additional_notes,
                        event_timings,
                        briefPreferences,
                        isVenue,
                        date_decision_estimate,
                      }) => {
  const [showAll, setShowAll] = useState(expanded);

  const hasNotes =
    date_flexible ||
    syndicate_room ||
    private_dining ||
    exhibition_space ||
    any_equipment ||
    additional_notes;
  return (
    <WhitePanelWithHeader icon="far fa-clipboard" title="Event Brief">
      <div className="md:flex flex-wrap">
        <Col title="Delegates" text={`${number_of_delegates} People`}/>
        <Col
          title="Event Start Date"
          text={moment(date_from).format("ddd Do MMM YYYY")}
        />
        <Col
          title="Event End Date"
          text={moment(date_to).format("ddd Do MMM YYYY")}
        />
        {date_decision_estimate && (
          <Col
            title="Date Decision Estimate"
            text={moment(date_decision_estimate).format("ddd Do MMM YYYY")}
          />
        )}
        <Col title="Dates Flexible" text={date_flexible ? "Yes" : "No"}/>
        <Col
          title="Bedrooms required"
          text={bedrooms_required ? "Yes" : "No"}
        />
        <Col
          title="No of Nights"
          text={
            bedroom_dates && bedroom_dates.length ? bedroom_dates.length : "N/A"
          }
        />
        <Col title="Venue Style" text={room_style || "N/A"}/>
        <Col title="Back Projection" text={back_projection ? "Yes" : "No"}/>
        {event_timings && (
          <Col title="Event Timings" text={event_timings}/>
        )}
        {!isVenue && (
          <>
            {briefPreferences && briefPreferences.location && <Col title="Location" text={briefPreferences.location}/>}
            {briefPreferences && briefPreferences.event_type && <Col title="Event Type" text={briefPreferences.event_type}/>}
            {briefPreferences && briefPreferences.budget && <Col title="Budget" text={briefPreferences.budget}/>}
          </>
        )}
      </div>

      {!showAll && (bedroom_dates && bedroom_dates.length > 0 || hasNotes) && (
        <div className="text-center uppercase text-sm">
          <ButtonSpan onClick={() => setShowAll(true)}>
            <i className="fal fa-chevron-down"></i> See the full brief
          </ButtonSpan>
        </div>
      )}
      {showAll && (
        <div>
          {bedroom_dates && bedroom_dates.length > 0 && (
            <>
              <h4 className="mt-5 mb-2">Accommodation Requirements</h4>
              <div className="flex flex-wrap">
                {bedroom_dates.sort((a, b) => moment(a.date) - moment(b.date)).map(val => (
                  <Col
                    key={moment(val.date).format("ddd Do MMM YYYY")}
                    title={moment(val.date).format("ddd Do MMM YYYY")}
                    text={`${val.quantity} bedrooms`}
                  />
                ))}
              </div>
            </>
          )}
          <div className="flex flex-wrap">
            {bedroom_additional_notes && (
              <Col
                title="Bedroom Notes"
                text={bedroom_additional_notes}
              />
            )}
          </div>

          {hasNotes && (
            <>
              <h4 className="mt-5 mb-2">Notes</h4>
              <BoolWithNotes
                title="Flexible Dates"
                bool={date_flexible}
                notes={date_flexible_notes}
              />
              <BoolWithNotes
                title="Syndicate Room"
                bool={syndicate_room}
                notes={syndicate_room_notes}
              />
              <BoolWithNotes
                title="Exhibition Space"
                bool={exhibition_space}
                notes={exhibition_space_notes}
              />
              <BoolWithNotes
                title="Catering"
                bool={private_dining}
                notes={private_dining_notes}
              />
              <BoolWithNotes
                title="Any Additional Equipment"
                bool={any_equipment}
                notes={any_equipment_notes}
              />
              <LabelledText label="Additional Notes" text={additional_notes}/>
            </>
          )}

          {showAll && (bedroom_dates && bedroom_dates.length > 0 || hasNotes) && (
            <div className="text-center uppercase text-sm">
              <ButtonSpan onClick={() => setShowAll(false)}>
                <i className="fal fa-chevron-up"></i> Show less
              </ButtonSpan>
            </div>
          )}
        </div>
      )}
    </WhitePanelWithHeader>
  );
};

export default BriefSummary;
