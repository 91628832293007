import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import {hasAgencyRoles, isAgent, isInternal, token as tokenSelector} from "modules/auth";
import Modal from "Components/Modal";
import { ButtonPrimary, ButtonSpan } from "Components/Button";
import Loading from "Components/Loading";
import {ENQUIRY_VENUE_DATA_STATUS} from "../../modules/rfp/enquiryVenueDataStatus";
import moment from 'moment';
import managedTrinityUser from "../RfpBriefSection/ManagedTrinityUser";

const saveData = (function() {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.cssText = "display: none";
  return function(isCsv, data, fileName) {
    const blob = new Blob([data], {
      type: isCsv
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        : "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const ExportRfpProposalsModal = ({ onClose, data, enquiryData, loggedInUserId }) => {
  const [submitting, setSubmitting] = useState("");
  const { id } = useParams();
  const isAgency = useSelector(state => isAgent(state));
  const hasAgency = useSelector(state => hasAgencyRoles(state));
  const hasInternal = useSelector(state => isInternal(state));
  const token = useSelector(tokenSelector);


  const handleExport = (type, disableCommission = false, submittingType) => async () => {
    if (submitting) {
      return;
    }
    setSubmitting(submittingType);
    let url =   `${window.API}/rfp/enquiry/${id}/export/${type}`
    if(disableCommission)
    {
      url += `?disable_commission=1`
    }
    try {
      const response = await axios.get(
        url,
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      saveData(
        type === "csv",
        response.data,
        `your-enquiry-${id}.${type === "csv" ? "xlsx" : type}`
      );
      setSubmitting("");
      onClose();
    } catch (err) {
      setSubmitting("");
      alert(`Sorry there was a problem fetching your ${type}`);
    }
  };

  const handleConfirmationExport = (enquiryId, enquiryVenueDataId, name, disableCommission, type) => async () => {
    if (submitting) {
      return;
    }
    let url =   `${window.API}/rfp/${enquiryId}/confirm/${enquiryVenueDataId}/export`
    if(disableCommission)
    {
      url += `?disable_commission=1`
    }
    setSubmitting(type);
    try {
      const response = await axios.get(
          url,
          {
            responseType: "blob",
            headers: {Authorization: `Bearer ${token}`},
          }
      );
      saveData(
          false,
          response.data,
          `${name.replace(/\s/g, '-')}-${moment().format('DD-MM-YYYY')}-proposal-ref-${enquiryVenueDataId}-confirmation${disableCommission ? '-without-commission' : ''}.pdf`
      );
      setSubmitting('');

    } catch (err) {
      setSubmitting('');
      alert(`Sorry there was a problem fetching the pdf`);
    }
  };
  return (
    <Modal>
      <div className="modal">
        <div
          className="modal-inner"
          style={{
            width: 600,
            maxWidth: "90%",
          }}
        >
          <ButtonSpan
            className="m-3 absolute pin-t pin-r z-10"
            onClick={onClose}
          >
            <i className="fas fa-times-circle fa-2x ml-3" />
          </ButtonSpan>
          <h2 className="mb-3 text-3xl">Export Proposal</h2>
          <div className="bg-off-white md:flex py-8 px-3">
            <div className="md:w-1/2 flex justify-center items-center">
              <i className="fas fa-5x fa-cloud-download-alt"></i>
            </div>
            <div className="md:w-2/5 flex flex-col justify-center items-center">
              <ButtonPrimary
                classes="mb-3 w-full"
                onClick={handleExport("pdf", false, 'pdfWith')}
              >
                {submitting === "pdfWith" ? <Loading /> : "Export as PDF"}{" "}
              </ButtonPrimary>
              {(isAgency || hasAgency || hasInternal) && enquiryData && enquiryData.proposal_data.commission_items.length > 0 ?
              <ButtonPrimary
                  classes="mb-3 w-full"
                  onClick={handleExport("pdf", true, 'pdfWithout')}
              >
                {submitting === "pdfWithout" ? <Loading /> : "Export as PDF Without Commission"}{" "}
              </ButtonPrimary> : null }
              <ButtonPrimary classes="w-full" onClick={handleExport("csv")}>
                {submitting === "csv" ? <Loading /> : "Export as Excel"}{" "}
              </ButtonPrimary>
            </div>
          </div>
          {enquiryData && enquiryData.status === ENQUIRY_VENUE_DATA_STATUS.VENUE_CONFIRMED ?
              <>
                <h2 className="mb-3 mt-3 text-3xl">Export Confirmation</h2>
                <div className="bg-off-white md:flex py-8 px-3">
                  <div className="md:w-1/2 flex justify-center items-center">
                    <i className="fas fa-5x fa-cloud-download-alt"></i>
                  </div>
                  <div className="md:w-2/5 flex flex-col justify-center items-center">
                    <ButtonPrimary classes="mb-3 w-full" onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, false, 'confirm')}>
                      {submitting === "confirm" ? <Loading /> : "Export as PDF"}{" "}
                    </ButtonPrimary>
                    {(isAgency || hasAgency || hasInternal)  && enquiryData && enquiryData.proposal_data.commission_items.length > 0 ?
                    <ButtonPrimary classes="mb-3 w-full" onClick={handleConfirmationExport(id, enquiryData.id, data.brief.event_name, true, 'confirmWithout')}>
                      {submitting === "confirmWithout" ? <Loading /> : "Export as PDF Without Commission"}{" "}
                    </ButtonPrimary> : null }
                  </div>
                </div>
              </>

          : null
          }
        </div>
      </div>
    </Modal>
  );
};

export default ExportRfpProposalsModal;
