import * as Sentry from "@sentry/browser";

export function tawkTo(propertyId, key) {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();

  if (!window) {
    Sentry.captureException('DOM is unavailable');
  }

  const tawk = document.getElementById('tawkId');
  if (tawk) {
    return Tawk_API;
  }

  if (!key) {
    Sentry.captureException('Tawk.to key not provided');
  }

  let s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = 'https://embed.tawk.to/' + propertyId + '/' + key;
  s1.setAttribute('crossorigin', '*');
  s0.parentNode.insertBefore(s1, s0);
}
