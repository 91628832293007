import isPostalCode from "validator/lib/isPostalCode";
import isEmail from "validator/lib/isEmail";
import isInt from "validator/lib/isInt";
import isFloat from "validator/lib/isFloat";
import isURL from "validator/lib/isURL";
import moment from "moment";

const checkType = v => typeof v === "string" || typeof v === "number";

export const optional = validator => v => {
  if (!v) return undefined;
  return validator(v);
};

export const postcode = v =>
  checkType(v) && isPostalCode(v.toString())
    ? undefined
    : "Please enter a valid postcode";

export const required = (message = "This field is required") => v => {
  return !!v ? undefined : message;
};

export const arrayOfLength = (message = "This field is required") => v =>
  Array.isArray(v) && v.length ? undefined : message;

export const minLength = n => v =>
  v && v.length >= n ? undefined : `Please enter at least ${n} characters`;

export const maxLength = n => v =>
  v && v.length <= n ? undefined : `Max characters ${n}`;

export const email = v =>
  checkType(v) && isEmail(v.toString())
    ? undefined
    : "Please enter a valid email";

export const phone = v =>
  v && /^[0-9+\s]+$/.test(v) ? undefined : "Please enter a valid phone number";

export const float = v =>
  checkType(v) && isFloat(v.toString())
    ? undefined
    : "Please enter a valid number";

export const int = v =>
  checkType(v) && isInt(v.toString()) ? undefined : "Only numbers are allowed";

export const intMsg = msg => v =>
  checkType(v) && isInt(v.toString()) ? undefined : msg;

export const min = min => v =>
  checkType(v) && isInt(v.toString(), { min })
    ? undefined
    : v === undefined || v.toString() === "-1"
    ? `The value must be 0 or greater`
    : `The value must be greater than ${min - 1}`;

export const minFloat = min => v =>
  checkType(v) && isFloat(v.toString(), { min })
    ? undefined
    : `The value must be greater than ${min - 1}`;

export const positiveFloat = v =>
  checkType(v) && isFloat(v.toString(), { min: 0 })
    ? undefined
    : `The value must a number`;

export const positiveInt = v =>
  checkType(v) && isInt(v.toString(), { min: 0 })
    ? undefined
    : `The value must a number`;

export const max = max => v =>
  checkType(v) && isInt(v.toString(), { max })
    ? undefined
    : `The value must be less than ${max + 1}`;

export const bool = v =>
  typeof v === "boolean" || v === 1 || v === 0
    ? undefined
    : `This field is required`;

export const flexiBool = err => v =>
  v === 1 ||
  v === 0 ||
  (v && v.toLowerCase() === "true") ||
  (v && v.toLowerCase() === "false") ||
  (v && v.toLowerCase() === "yes") ||
  (v && v.toLowerCase() === "no")
    ? ""
    : err;

export const url = v =>
  checkType(v) && isURL(v) ? undefined : "Please enter a valid URL";

export const isDate = v => (moment(v).isValid() ? "" : "Date required");

export const notInPast = v => {
  if (moment(v).isBefore(moment().startOf("day"))) {
    return "Cannot be in past";
  }
  return "";
};

export const afterToday = v => {
  if (
    moment(v).isBefore(
      moment()
        .add(1, "days")
        .startOf("day")
    )
  ) {
    return "Must be after today";
  }
  return "";
};

export const isNotBefore = other => (v, vs) => {
  if (!vs[other]) return "";

  if (moment(v).isBefore(moment(vs[other]))) {
    return `Must not be before ${other}`;
  }

  return "";
};

export const isBefore = other => (v, vs) => {
  if (!vs[other]) return "";

  if (!moment(v).isBefore(moment(vs[other]))) {
    return `Must be before ${other}`;
  }

  return "";
};
