import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { getFormValues, reduxForm } from 'redux-form';
import { ButtonBrand, ButtonGrey, ButtonSpan, ButtonWhite } from 'Components/Button';
import Location from 'Components/Search/Location';
import Results from 'Components/Search/Results';
import CountryLookup from './CountryLookup';
import FlightChoices from './FlightChoices';
import EditRadius from './EditRadius';
import ResultsMeta from './ResultsMeta';
import Map from './Map';
import ExtraDetails from './ExtraDetails';
import SortBy from './SortBy';
import { search } from './search';
import { FaAngleDown, FaAngleUp, FaEdit, FaUndoAlt } from 'react-icons/fa';
import SaveSearchButton from 'Components/SavedSearches/SaveButton';
import FindPerfectSpace from 'Components/FindPerfectSpace';
import { Link as ScrollLink } from 'react-scroll';
import LocationChoices from './LocationChoices';
import NamedLocation from './NamedLocation';
import queryString from 'query-string';
import { isLoggedIn } from '../../modules/auth';
import { omit } from 'lodash';

import {
  getNamedLocationSubGroups,
  getNamedLocations,

} from 'modules/named-locations';

const defaultState = {
  categories: [],
  results: [],
  count: 0,
  loading: false,
  error: null,
  open: {
    location: true,
    delegates: false,
    type: false,
    rooms: false,
  },
};

class AdvancedSearch extends React.Component {
  state = {
    ...defaultState,
  };

  constructor(props) {
    super(props);
    this.axiosCancelSource = axios.CancelToken.source();
  }

  doSearch = (params = {}) => {
    this.axiosCancelSource.cancel('New Search');
    this.axiosCancelSource = axios.CancelToken.source();
    this.props.search(this.props.history, this.stateSetter, params, this.axiosCancelSource.token);
  };

  componentDidMount() {

      if (!this.props.isLoggedIn) {
          this.props.history.push(
              `/login?return=${encodeURI(
                  this.props.route_location.pathname
              )}&logInToProceed=1`
          );
      }

    axios
        .get(`${window.API}/venue-categories`)
        .then(r => {
          this.setState({
            categories: r.data.map(item => ({
              text: item.name,
              value: item.id,
            })),
          });
        })
        .catch(err => {
        });

    let parsed = queryString.parse(this.props.history.location.search);
    parsed = omit(parsed, ['named_location_type', 'named_location_group', 'named_location']);
    const { types, results, closePanels = false, ...params } = parsed;

    let typesAsString = Array.isArray(types) ? types : (types === undefined ? [] : [types]);
    params.types = typesAsString.map((i) => Number(i));
    if (Object.keys(params).length > 1) {
      this.props.initialize(params, {
        updateUnregisteredFields: true,
      });

    }

    this.doSearch();

    this.setState({
      searchTerm: this.props.location,
    });
    if (closePanels) {
      this.toggleAllParams(false, false);
    }
  }

  reset = () => {
    this.props.initialize(this.props.initialValues, {
      updateUnregisteredFields: true,
    });

    this.setState({
          ...defaultState,
          categories: this.state.categories,
        },
    );
  };

  stateSetter = {
    loading: () => this.setState({ loading: true, results: [] }),
    error: () => this.setState({ loading: false, error: true }),
    clear: () =>
        this.setState({ ...defaultState, categories: this.state.categories }),
    results: (results, count) =>
        this.setState({
          results,
          count,
          loading: false,
          error: false,
        }),
    tab: (name, state) => {
      const tabs = this.state.open;
      tabs[name] = state;
      this.setState({ open: { ...tabs } });
    },
  };

  toggleAllParams = (event, override = undefined) => {
    const areClosed = override !== undefined ? override : Object.values(this.state.open).includes(false);
    this.setState({
      open: {
        location: areClosed,
        delegates: areClosed,
        type: areClosed,
        rooms: areClosed,
      },
    });
  };

  openAllWithDelay = () => {
    const areClosed = Object.values(this.state.open).includes(false);
    if (areClosed) {
      setTimeout(() => {
        this.setState({
          open: {
            location: true,
            delegates: true,
            type: true,
            rooms: true,
          },
        });
      }, 500);
    }
  };

  submit = () => {
    // nowt
  };

  resultsHasDestinations = () => {
    const { results } = this.state;
    return !!results && results.some(result => result.destination_city);
  };

  sectionHeader = (name, key) => (
      <ButtonSpan
          onClick={() => {
            this.setState({
              open: {
                ...this.state.open,
                [key]: !this.state.open[key],
              },
            });
          }}
          className="outline-none"
          id={key}
      >
        <h2 className={`text-3xl flex justify-between bg-grey p-3 leading-none border-l-2 font-semibold border-black relative${
            this.state.open[key] ? '' : ' border-b'
        }`}
        >
          {name}
          <i className={`fal fa-${this.state.open[key] ? 'minus' : 'plus'}`}/>
        </h2>
      </ButtonSpan>
  );

  render() {
//    console.log({ named_location: this.props.named_location });
    return (
        <>
          <div className="bg-light-grey">
            <div className="container mx-auto px-3 bg-white">
              <div className="-mx-3 flex flex-wrap">

                <form
                    noValidate
                    onSubmit={this.props.handleSubmit(this.submit)}
                    className="w-full xl:w-3/4 xxl:w-5/6 p-3"
                >
                  <p className="mb-5 border-b border-black pb-1 hidden">
                    Skip the advanced search,{' '}
                    <Link to="/request-for-proposal" className="font-normal">
                      upload your brief
                    </Link>{' '}
                    and speak to a venue expert.
                  </p>
                  <div className="flex items-center justify-between">
                    <h1 className="mt-2 mb-5 text-4xl md:text-5xl">
                      Advanced <span className="hidden sm:inline-block">Venue </span> Search
                    </h1>
                    <div className="flex pointer" onClick={this.toggleAllParams}>
                      {Object.values(this.state.open).includes(false) ? (
                          <>
                        <span className="uppercase font-heading font-bold text-sm mr-4">
                          Expand all
                        </span>
                            <FaAngleDown/>
                          </>
                      ) : (
                          <>
                        <span className="uppercase font-heading font-bold text-sm mr-4">
                          Collapse all
                        </span>
                            <FaAngleUp/>
                          </>
                      )}
                    </div>
                  </div>
                  <div className="bg-off-white">
                    {this.sectionHeader('Choose your location', 'location')}
                    <div
                        className={`${
                            this.state.open.location ? 'flex flex-wrap' : 'hidden'
                        }`}
                    >
                      <div className="col-w-full lg:col-w-1/2 px-0">
                        <div className="h-full flex flex-col justify-between">
                          <div className="bg-off-white mb-4">
                            <div className="col-w-full pt-12 px-3">
                              <LocationChoices reset={this.reset}/>
                            </div>
                          </div>
                          {this.props.location_type === 'Map' ? (<>
                            <div className="px-3 pt-4 flex">
                              <Location
                                  id="location-search"
                                  formId="advanced-search"
                                  icon="/img/map-marker.svg"
                                  label="Choose a Destination"
                                  placeholder="Enter a destination"
                                  name="location"
                                  size="lg"
                                  onPlaceFound={(lat, lng, name) => {
                                    this.setState({
                                      searchTerm: name,
                                    });
                                    this.doSearch();
                                  }}
                              />

                              {!this.props.isNamedLocationPostcodeSearch && <EditRadius search={params => this.doSearch(params)} stateSetter={this.stateSetter}/>}

                            </div>
                            <div className="px-3 pt-4 pb-20 h-96 relative">
                              <NamedLocation search={params => this.doSearch(params)} location={this.props.history.location} searchTerm={this.state.searchTerm}/>
                              <div className="row px-2 py-4 w-full justify-between py-3 mt-10 absolute pin-b bg-light-grey mf">
                                <ScrollLink
                                    to="delegates"
                                    smooth
                                    className="pointer"
                                >
                                  <ButtonBrand
                                      classes="mr-4 text-lg"
                                      onClick={() =>
                                          this.stateSetter.tab('delegates', true)
                                      }
                                  >
                                    Next
                                  </ButtonBrand>
                                </ScrollLink>
                                <ScrollLink
                                    smooth
                                    className="pointer"
                                    to="results"
                                >
                                  <ButtonWhite>See search results</ButtonWhite>
                                </ScrollLink>
                              </div>
                            </div>
                          </>) : null}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2">
                        {this.props.location_type === 'Map' ? (
                            <Map
                                type="advanced"
                                results={this.state.results}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvso7D8EYP9kyPsNgBLNa9nQ75gzqmVGU&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }}/>}
                                containerElement={
                                  <div className="re-1/2 h-full relative"/>
                                }
                                search={params => this.doSearch(params)}
                                mapElement={<div className="absolute pin"/>}
                            />
                        ) : null}
                        {this.props.location_type === 'Country' ? (
                            <div className="p-3 lg:pt-12">
                              <CountryLookup search={params => this.doSearch(params)}/>
                            </div>
                        ) : null}
                        {this.props.location_type ===
                        'Flight time from the UK' ? (
                            <div className="p-3 lg:pt-12">
                              <FlightChoices search={params => this.doSearch(params)}/>
                            </div>
                        ) : null}
                      </div>
                    </div>
                    <ExtraDetails
                        sectionHeader={this.sectionHeader}
                        open={this.state.open}
                        count={this.state.count}
                        categories={this.state.categories}
                        stateSetter={this.stateSetter}
                        search={params => this.doSearch(params)}
                    />
                  </div>
                  <div className="mt-5 row" id="results">
                    {!this.state.loading && (
                        <>
                          <div className="col col-w-full lg:col-w-1/6 flex items-end">
                            <SortBy
                                stateSetter={this.stateSetter}
                                hasDestinations={this.resultsHasDestinations()}
                                search={params => this.doSearch(params)}
                            />
                          </div>
                          <div className="col mt-4 lg:mt-0 md:px-0 col-w-full lg:col-w-5/6 flex flex-row flex-wrap text-sm xxl:text-base items-end justify-end">
                            <ButtonGrey
                                classes="mb-2 lg:mb-0 w-full lg:w-auto md:px-4 mx-2"
                                onClick={() => {
                                  this.reset();
                                }}
                            >
                              <FaUndoAlt className="mr-2"/>
                              Start again
                            </ButtonGrey>
                            {this.props.isLoggedIn && (
                                <SaveSearchButton
                                    searchForm={this.props.search}
                                    results={this.state.results}
                                    classes="md:px-4 mx-2 w-full lg:w-auto mb-2 lg:mb-0 "
                                />
                            )}
                            <ScrollLink
                                to="location"
                                smooth
                                className="mx-2 w-full lg:w-auto mb-2 lg:mb-0 pointer"
                            >
                              <ButtonGrey
                                  classes="md:px-4 w-full lg:w-auto items-center justify-center flex mb-2 lg:mb-0 "
                                  onClick={this.openAllWithDelay}
                              >
                                <FaEdit className="mr-2"/>
                                Edit search
                              </ButtonGrey>
                            </ScrollLink>
                          </div>
                        </>
                    )}
                  </div>
                  {this.state.count ? (
                      <div className="w-full mt-10">
                        <Results
                            type="advanced"
                            results={this.state.results}
                            loading={this.state.loading}
                            error={this.state.error}
                            onWhite
                        />
                      </div>
                  ) : null}
                </form>
                <div className="w-full xl:w-1/4 xxl:w-1/6 p-3 relative">
                  <ResultsMeta
                      count={this.state.count}
                      loading={this.state.loading}
                      error={this.state.error}
                      radius={this.props.radius}
                      reset={this.reset}
                      results={this.state.results}
                      search={() =>
                          this.doSearch()
                      }
                  />
                </div>
              </div>
            </div>
          </div>
          <FindPerfectSpace/>
        </>
    );
  }
}

export default withRouter(
    reduxForm({
      form: 'advanced-search',
      destroyOnUnmount: false,
      initialValues: {
        location_type: 'Map',
        back_projection: 0,
        exhibition: 0,
        radius: 1609.344 * 15,
        types: [],
        types_text: ['Hotels'],
        sortBy: 'ContentUpdate',
      },
      enableReinitialize: true,
    })(
        connect(
            (state, props) => {
              const v = getFormValues('advanced-search')(state) || {};
              let hasLocation;
              switch (v.location_type) {
                case 'Map':
                  hasLocation = !!v.location;
                  break;
                case 'Country':
                  hasLocation = !!v.country_id;
                  break;
                case 'Flight time from the UK':
                  hasLocation = !!v.flight_time_from_uk;
                  break;
                default:
                  hasLocation = false;
              }
              const isNamedLocationSearch = !!v.named_location;
              let isNamedLocationPostcodeSearch = false;
              let namedLocations = [];
              let namedLocationSubGroups = [];
              if (isNamedLocationSearch) {
                namedLocationSubGroups = getNamedLocationSubGroups(state);
                namedLocations = getNamedLocations(state);
                const matchedLocation = namedLocations.find(loc => Number(loc.id) === Number(v.named_location));
//                console.log({ matchedLocation });
                isNamedLocationPostcodeSearch = matchedLocation && matchedLocation.postcode && matchedLocation.postcode.length > 0;
              }

              return {
                location_type: v.location_type,
                location: v.location,
                route_location: props.location,
                country_id: v.country_id,
                flight_time_from_uk: v.flight_time_from_uk,
                hasLocation,
                isLoggedIn: isLoggedIn(state),
                namedLocationSubGroups,
                namedLocations,
                isNamedLocationSearch: isNamedLocationSearch,
                isNamedLocationPostcodeSearch: isNamedLocationPostcodeSearch,
              };
            },
            { search },
        )(AdvancedSearch),
    ),
);
