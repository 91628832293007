import React, {Fragment, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import {Date as DateInput, Textarea, Toggle} from 'Components/Form';
import {stage3 as v} from './validate';
import RfpSubmit from './RfpSubmit';
import Dropzone from 'react-dropzone';
import {ButtonSpan} from '../../Components/Button';
import {isRoleTrinity, isRoleTrinityEdgeRFP, isSuperAgent} from '../../modules/permissions';
import AgencySpecificEnquiryData from "./AgencySpecificEnquiryData";
import CompanyFormModal from "../../Components/corporate/NewCompanyModal";
import {createCompany, createUser, fetchCompany} from "../../redux/actions";
import {isInternal, token, user, isAgency} from "../../modules/auth";
import MinimalUserModalForm from "../../Components/Users/MinimalUserModalForm";
import {fetchIndustries} from "../../modules/industries";
import classnames from "classnames";
import CorporateSpecificEnquiryData from "./CorporateSpecificEnquiryData";

const mapStateToProps = state => {
  return {
  ...state.industries,
  isRoleTrinityEdgeRFP: isRoleTrinityEdgeRFP(state.auth),
  isSuperAgent: isSuperAgent(state.auth),
  isAgency: isAgency(state),
  isRoleTrinity: isRoleTrinity(state.auth),
  isInternal: isInternal(state),
  token: token(state),
  corporate_account: user(state).corporate_account
}}

const EDGE_AGENCY_CODE = 'edge';
const TRINITY_AGENCY_CODE = 'trin';

const mapDispatchToProps = {
  change,
  createCompany,
  fetchCompany,
  createUser,
  fetchIndustries
}

const Stage3 = ({
                  form,
                  handleSubmit,
                  prevPage,
                  attachments,
                  setAttachments,
                  change,
                  isRoleTrinityEdgeRFP,
                  isSuperAgent,
                  token,
                  createCompany,
                  createUser,
                  industries,
                  fetchIndustries,
                  corporate_account,
                  hasVenues,
                  editEnquiry,
                  setSubmitToAgent,
                  setEnquiryToSubmitted,
                  fetchCompany,
                  isInternal,
                  isAgency,
                  setSubmitToDraft,
                  enquiry
}) => {
  const values = useSelector(getFormValues(form));
  const fileTypesString = '.xlsx,.xls,.csv,.jpg,.jpeg,.png,.bmp,.doc,.docx,.pdf,.txt,.eml,.msg';
  const [showNewCompany, setShowNewCompany] = useState(null);
  const [newCompanyModal, setNewCompanyModal] = useState(null);
  const [showNewUser, setShowNewUser] = useState(null);
  const [newUserModal, setNewUserModal] = useState(null);
  const [company, setCompany] = useState(null);
  const [clientName, setClientName] = useState(values?.client);
  const [edgeEnquiry, setEdgeEnquiry] = useState(true);
  const [trinityEnquiry, setTrinityEnquiry] = useState(false);
  const [shouldClearSearches, setShouldClearSearches] = useState(false);
  const [agencyCode, setAgencyCode] = useState(null);

  useEffect(() => {
    fetchIndustries(token);
    if (values.date_decision_estimate) {
      change(form, 'date_decision', 1)
    }
    if (values.company_id) {
      fetchCompany(values.company_id, token).then(response => {
        if (response.data) {
          setCompany(response.data.data)
        }
      })
    }
  }, []);

  useEffect(() => {
    if (!values.syndicate_room) {
      change(form, 'syndicate_room_notes', null);
    }
    if (!values.exhibition_space) {
      change(form, 'exhibition_space_notes', null);
    }
    if (!values.any_equipment) {
      change(form, 'any_equipment_notes', null);
    }
  }, [
    values.syndicate_room,
    values.exhibition_space,
    values.any_equipment,
    values.private_dining
  ])

  useEffect(() => {
    if (isRoleTrinityEdgeRFP) {
      change(form, 'is_edge_enquiry', 1);
      setAgencyCode(EDGE_AGENCY_CODE);
    } else {
      change(form, 'is_edge_enquiry', 0);
      change(form, 'is_trinity_enquiry', 0);
    }
  }, [(values.is_edge_enquiry === null || values.is_trinity_enquiry === null)])

  const handleDrop = async files => {
    const file = files[0];
    if (!file) {
      alert('Please ensure you have selected a file from the following types: ' + fileTypesString)
      return;
    }
    if (file.size > 10 * 1024 * 1024) {
      alert('File must be under 10MB');
      return;
    }
    setAttachments(oldFiles => ([...oldFiles, ...files]));
  };

  const handleDelete = async attachment_name => {
    try {
      setAttachments(oldFiles => ([...oldFiles.filter(file => file.name !== attachment_name)]));
    } catch (err) {
      alert('Sorry there was a problem deleting the attachment');
    }
  };

  const onEnquiryTypeToggleChange = (event) => {
    setCompany(null)
    setClientName(null)
    setShouldClearSearches(true)
    if (event === TRINITY_AGENCY_CODE) {
      setTrinityEnquiry(true)
      setAgencyCode(TRINITY_AGENCY_CODE);
      change(form, 'is_edge_enquiry', 0);
      change(form, 'is_trinity_enquiry', 1);
    } else {
      setEdgeEnquiry(true)
      setAgencyCode(EDGE_AGENCY_CODE);
      setTrinityEnquiry(false)
      change(form, 'is_edge_enquiry', 1);
      change(form, 'is_trinity_enquiry', 0);
    }
  };

  const handleCreateCompany = formValues => {
    return createCompany(formValues, token).then(response => {
      setNewCompanyModal(false);
      setShowNewCompany(false);
      alert('The company was created successfully. Please search for it by name to associate it with your enquiry.');
    });
  }

  const handleCreateUser = formValues => {
    formValues.append('user_type_id', 1)
    formValues.append('company_id', company.id)
    return createUser(token, formValues).then(() => {
      setNewUserModal(false);
      setShowNewUser(false);
    });
  }

  const addCompany = () => {
    if (company) {
      change(form, 'company_id', company.id)
    }
  }

  const addClient = () => {
    if (clientName) {
      change(form, 'client_id', clientName.id)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="row">
          <div className="col col-w-full xl:col-w-1/2">
            <Field
              name="back_projection"
              label="Do you need back projection"
              component={Toggle}
              wrapperClassName="mb-8 xl:w-1/2"
              validate={v.back_projection}
            />
          </div>
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="syndicate_room"
              label="Do you need a syndicate room"
              component={Toggle}
              wrapperClassName="mb-8 pr-8 xl:w-1/2"
              validate={v.syndicate_room}
            />
            {values && values.syndicate_room ? (
              <Field
                name="syndicate_room_notes"
                component={Textarea}
                rows={5}
                label="Notes"
                wrapperClassName="w-1/2 mb-8"
                validate={v.syndicate_room_notes}
              />
            ) : null}
          </div>
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="exhibition_space"
              label="Do you need exhibition space"
              component={Toggle}
              wrapperClassName="mb-8 pr-8 xl:w-1/2"
              validate={v.exhibition_space}
            />
            {values && values.exhibition_space ? (
              <Field
                name="exhibition_space_notes"
                label="Notes"
                component={Textarea}
                rows={5}
                wrapperClassName="w-1/2 mb-8"
                validate={v.exhibition_space_notes}
              />
            ) : null}
          </div>
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="private_dining"
                label="Do you need catering"
                component={Toggle}
                wrapperClassName="mb-8 pr-8 xl:w-1/2"
                validate={v.private_dining}
              />
              {values && values.private_dining ? (
                <Field
                  name="private_dining_notes"
                  component={Textarea}
                  rows={5}
                  label="Notes"
                  wrapperClassName="w-1/2 mb-8"
                  validate={v.private_dining_notes}
                />
              ) : null}
            </div>
            <div className="col col-w-full xl:col-w-1/2 flex">
              <Field
                name="any_equipment"
                label="Do you need any equipment"
                component={Toggle}
                wrapperClassName="mb-8 pr-8 xl:w-1/2"
                validate={v.any_equipment}
              />
              {values && values.any_equipment ? (
                <Field
                  name="any_equipment_notes"
                  component={Textarea}
                  rows={5}
                  label="Notes"
                  wrapperClassName="w-1/2 mb-8"
                  validate={v.any_equipment_notes}
                />
            ) : null}
          </div>
          <div className="col col-w-full xl:col-w-1/2 flex">
            <Field
              name="date_decision_estimate"
              label="Decision Date"
              component={DateInput}
              wrapperClassName="w-1/2 mb-8"
              validate={v.date_decision_date}
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-w-1/2">
            <Field
              name="event_timings"
              label="Event Timings"
              component={Textarea}
              rows={6}
              wrapperClassName="w-full mb-8"
              validate={v.event_timings}
              placeholder="Please add your start times, finish times and any required access times here"
            />
          </div>
        </div>

        <div className="row">
          <div className="col col-w-1/2">
            <Field
              name="additional_notes"
              label="Additional notes"
              component={Textarea}
              rows={12}
              wrapperClassName="w-full mb-8"
              validate={v.additional_notes}
              placeholder="Please add any information on Set up, Overnight hold or Parking requirements here"
            />
          </div>
          <div className="col col-w-1/2">
            <label htmlFor="additional_notes" className="mb-1 block font-normal font-heading text-sm uppercase">Additional/Supporting
              Documents</label>
            <Dropzone
              accept={fileTypesString}
              multiple={true}
              onDrop={handleDrop}
              className="max-w-full relative flex justify-center items-center border-2 border-black border-dashed rounded p-5 mb-5 pointer w-72"
            >
              <div>
                <i className="fas fa-upload mr-3"/>
                Upload document
                <br/>
                <span className="text-xs block mt-2">
                <b>FILE SIZE</b>: max 10MB
              </span>
              </div>
            </Dropzone>
            <div className="ml-3 mt-3">
              {Array.isArray(attachments) && attachments.map(item => (
                <React.Fragment key={item.id}>
                  <ButtonSpan
                    className="mb-2 text-brand inline-block p-2 bg-white mr-1 border border-grey"
                  >
                    {item.name}
                  </ButtonSpan>
                  <i
                    className="pointer fal fa-times text-warning mr-3"
                    onClick={() => handleDelete(item.name)}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {(isSuperAgent && (editEnquiry && clientName && [1, 2, 6].includes(clientName.user_type_id) || !editEnquiry)) && (
          <Fragment>
          <div className="row">
            <div className="col">
            <div className="mb-4">
              <h2>AGENCY ONLY</h2>
              <p>This information is only visible to the agency unless you choose to share the client name with the
                Venues.</p>
            </div>
            {isRoleTrinityEdgeRFP && !editEnquiry && (
              <div className="row">
                <div className="col col-w-1/2 md:col-w-1/3 mb-4">
                  <div className="flex text-s min-h-9.5">
                    <ButtonSpan
                      className={classnames(
                        "inline-block flex items-center px-3 border border-grey-md border-r-0",
                        {
                          "opacity-50": !values.is_edge_enquiry,
                          "bg-brand": values.is_edge_enquiry,
                        }
                      )}
                      onClick={() => onEnquiryTypeToggleChange(EDGE_AGENCY_CODE)}
                    >
                      Edge Enquiry
                    </ButtonSpan>
                    <ButtonSpan
                      className={classnames(
                        "inline-block flex items-center px-3 border border-grey-md",
                        {
                          "opacity-50": values.is_edge_enquiry,
                          "bg-brand": !values.is_edge_enquiry,
                        }
                      )}
                      onClick={() => onEnquiryTypeToggleChange(TRINITY_AGENCY_CODE)}
                    >
                      Trinity Enquiry
                    </ButtonSpan>
                  </div>
                </div>
              </div>
            )}
              <AgencySpecificEnquiryData
                validators={v}
                setShowNewCompany={setShowNewCompany}
                showNewCompany={showNewCompany}
                setShowNewUser={setShowNewUser}
                showNewUser={showNewUser}
                setCompany={setCompany}
                company={company}
                editEnquiry={editEnquiry}
                setClientName={setClientName}
                client={clientName}
                addCompany={addCompany}
                addClient={addClient}
                setNewCompanyModal={setNewCompanyModal}
                setNewUserModal={setNewUserModal}
                formValues={values}
                shouldClearSearches={shouldClearSearches}
                setShouldClearSearches={setShouldClearSearches}
              />
          </div>
          </div>
          </Fragment>
        )}
        {((company && company.corporate_account) || corporate_account) && (
          <div className="row">
            <div className="col">
              <div className="mb-4">
                <h2>CORPORATE EDGE ONLY</h2>
                <p>This information is only visible to the agent/company user.</p>
              </div>
              <CorporateSpecificEnquiryData
                form={form}
                company={company}
                validators={v}
                corporateAccount={corporate_account}
                change={change}
              />
            </div>
          </div>
        )}
        {/* if editing the enquiry it should always be enabled. */}
        {/* if clientName exists and client is of type corporate or agency it should only be enabled when clientName exists and current user isSuperAgent */}
        {(editEnquiry && clientName && [1, 2, 6].includes(clientName.user_type_id) || !editEnquiry) ? (
          <RfpSubmit
            page={2}
            enabled={(!isSuperAgent || (isSuperAgent && company && clientName))}
            prevPage={prevPage}
            hasVenues={hasVenues}
            editEnquiry={editEnquiry}
            setSubmitToAgent={setSubmitToAgent}
            setEnquiryToSubmitted={setEnquiryToSubmitted}
            isSuperAgent={isSuperAgent}
            isInternal={isInternal}
            isAgency={isAgency}
            setSubmitToDraft={setSubmitToDraft}
            enquiry={enquiry}
          />
        ) : (
          <RfpSubmit
            page={2}
            enabled={isSuperAgent}
            prevPage={prevPage}
            hasVenues={hasVenues}
            editEnquiry={editEnquiry}
            setSubmitToAgent={setSubmitToAgent}
            setEnquiryToSubmitted={setEnquiryToSubmitted}
            isSuperAgent={isSuperAgent}
            isInternal={isInternal}
            isAgency={isAgency}
            setSubmitToDraft={setSubmitToDraft}
            enquiry={enquiry}
          />
        )}
      </form>
      {newCompanyModal && (
        <CompanyFormModal
          onSubmit={handleCreateCompany}
          onClose={() => setNewCompanyModal(false)}
          isEdgeEnquiry={values.is_edge_enquiry}
          industries={industries}
          token={token}
          agencyCode={agencyCode}
        />
      )}
      {newUserModal && (
        <MinimalUserModalForm
          onSubmit={handleCreateUser}
          onClose={() => setNewUserModal(false)}
          company={company}
        />
      )}
    </>
  );
};

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(Stage3));
