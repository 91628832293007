export const FRONTEND = {
  COMPANY: {
    ALL: '/admin/users/company',
    EDIT: '/admin/users/company/edit/:id?',
    EDIT_OWN: '/admin/users/company/edit',
    CREATE: '/admin/users/company/create'
  },
  AGENCY: {
    ALL: '/admin/users/agency',
    EDIT: '/admin/users/agency/edit/:id?',
    CREATE: '/admin/users/agency/create',
    USER_EMAIL: '/admin/users/agency/user/edit/:email?'
  },
  USER_INVITES: {
    AGENCY_LANDING_PAGE: '/invite-agency-user/:invite_code',
    COMPANY_LANDING_PAGE: '/invite-company-user/:invite_code'
  },
  CORPORATE: {
    ALL: '/admin/corporate-accounts/manage',
    CREATE: '/admin/corporate-accounts/create',
    EDIT: '/admin/corporate-accounts/:slug/:section',
    DOCUMENTS: {
      CATEGORY: '/admin/corporate-accounts/:slug/view-documents',
      SINGLE_CATEGORY: '/admin/corporate-accounts/:slug/documents/category/:category',
      ADMIN_SINGLE_CATEGORY: '/admin/corporate-accounts/:slug/:section/category/:category',
      CREATE: '/admin/corporate-accounts/:slug/:section/create',
      EDIT: '/admin/corporate-accounts/:slug/:section/:id/edit',
      MANAGE: '/admin/corporate-accounts/:slug/manage-documents',
      VIEW: '/admin/corporate-accounts/:slug/view-documents',
    },
    LOCATIONS: {
      SINGLE: '/admin/corporate-accounts/:slug/location/:id',
      CREATE: '/admin/corporate-accounts/:slug/location/create',
      EDIT: '/admin/corporate-accounts/:slug/location/:id/edit',
      MANAGE: '/admin/corporate-accounts/:slug/manage-locations',
      VIEW:  '/admin/corporate-accounts/:slug/company-locations',
    },
    INDUSTRY_EVENTS: {
      MANAGE: '/admin/corporate-accounts/:slug/manage-industry-events'
    },
  },

  EVENTS: {
    ALL: '/admin/corporate-accounts/calendar-events',
    COMPANY_ALL: '/admin/corporate-accounts/:slug/events-calendar'
  },
  LOCATIONS: {
    COMPANY_ALL: '/admin/corporate-accounts/:slug/company-locations'
  },
  BOOKED_EVENTS: {
    ALL: '/admin/events/',
    SINGLE: '/admin/events/:slug'
  },
  PROPOSALS: {
    SHARE: '/proposal/view/:shareId'
  }
}

export const API = {
  COMPANY: {
    LIST: '/company/',
    LIST_EVENTS: '/company/events',
    SINGLE: '/company/:id',
    INVITE_USER: '/company/:id/invite',
    USER_INVITES: '/company/:id/invites'
  },
  USER_INVITES: {
    SINGLE: '/invite/:invite_code',
    RESEND: '/invite/resend/:invite_code',
    REGISTER: '/invite/register/:invite_code'
  },
  COMPANIES: {
    SEARCH: '/companies',
    SEARCH_EDGE: '/companies?code=edge',
    SEARCH_TRIN: '/companies?code=trin',
  },
  AGENCY: {
    LIST: '/agency/',
    SINGLE: '/agency/:id',
    INVITE_USER: '/agency/:id/invite',
    USER_INVITES: '/agency/:id/invites'
  },
  USER: {
    LIST: '/users',
    SINGLE: '/users/:email',
  },
  NOTES: {
    LIST: '/rfp/enquiry/:rfpId/notes',
    CREATE: '/rfp/enquiry/:rfpId/notes/add',
    EDIT: '/rfp/enquiry/:rfpId/notes/:noteId',
  },
  BOOKED_EVENTS: {
    ALL: '/event',
    CALENDAR: '/events/calendar',
    SINGLE: '/event/:slug',
    CONFIRMATION: '/event/:slug/confirm',
    DELETE_FILE: '/documents/event/:slug/upload/:id',
    FINAL_COMMISSION_VALUE: '/event/:slug/final-commission-values/',
    EXPORT: '/event/:slug/export/:type',
    EXPORT_ALL: '/event/export/:type',
    INVOICE: '/event/:slug/invoice',
    COMPLETE: '/event/:slug/complete',
    CHASE: '/event/:slug/:chase'
  },
  CORPORATE: {
    LIST: '/corporate-accounts/',
    SINGLE: '/corporate-accounts/:slug',
    CREATE: '/corporate-accounts/create',
    EDIT: '/corporate-accounts/:slug/edit',
    LIST_USERS: '/corporate-accounts/:slug/users',
    DOCUMENTS: {
      LIST: '/corporate-accounts/:slug/documents',
      SINGLE: '/corporate-accounts/:slug/document/:id',
      CREATE: '/corporate-accounts/:slug/document/',
      EDIT: '/corporate-accounts/:slug/document/:id',
      DELETE: '/corporate-accounts/:slug/document/:id',
      DELETEFILE: '/corporate-accounts/:slug/document/:id/file',
      CATEGORY: {
        ALL: '/corporate-accounts/:slug/document/categories',
        BY: '/corporate-accounts/:slug/document/category/:category/',
        CREATE: '/corporate-accounts/:slug/document/category'
      }
    },
    BOOKING_DESKS: {
      LIST: '/corporate-accounts/:slug/booking-desks',
      LIST_CORPORATE: '/corporate-accounts/:slug/corporate-account/booking-desks',
      SINGLE: '/corporate-accounts/:slug/booking-desk/:id',
      CREATE: '/corporate-accounts/:slug/booking-desk',
      EDIT: '/corporate-accounts/:slug/booking-desk/:id',
      DELETE: '/corporate-accounts/:slug/booking-desk/:id'
    },
    DEPARTMENTS: {
      LIST: '/corporate-accounts/:slug/departments',
      SINGLE: '/corporate-accounts/:slug/department/:id',
      CREATE: '/corporate-accounts/:slug/department',
      EDIT: '/corporate-accounts/:slug/department/:id',
    },
    LOCATIONS: {
      LIST: '/corporate-accounts/:slug/locations',
      SINGLE: '/corporate-accounts/:slug/location/:id',
      CREATE: '/corporate-accounts/:slug/location/',
      EDIT: '/corporate-accounts/:slug/location/:id'
    }
  },
  EVENTS: {
    SINGLE: '/calendar-events/event/:id',
    PUBLIC: {
      LIST: '/calendar-events/public',
      CREATE: '/calendar-events/public',
      SINGLE: '/calendar-events/public/:id',
      EDIT: '/calendar-events/public/:id'
    },
    INDUSTRY: {
      LIST: '/calendar-events/industry',
      CREATE: '/calendar-events/industry',
      SINGLE: '/calendar-events/industry/:id',
      EDIT: '/calendar-events/industry/:id'
    },
    STATUS: {
      LIST: '/event-status',
      SINGLE: '/event-status/:id'
    }
  },
  ROLES: {
    LIST: '/roles',
    CORPORATE_LIST: '/roles/corporate-roles',
    UPDATE: '/roles/user/give',
    REVOKE: '/roles/user/revoke',
  },
  COUNTRIES: {
    LIST: '/countries',
  }
}
