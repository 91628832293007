import { GBPFormatter } from "../../utils";

export default total => {
  let savingPrice = "";
  if (parseFloat(total.percentage_saving)) {
    savingPrice = (
      parseFloat(total.reduced_from_exc_vat) - parseFloat(total.total_exc_vat)
    ).toFixed(2);
  }
  return `${total.percentage_saving}%${
    savingPrice ? "   (" + GBPFormatter.format(savingPrice) + ")" : ""
  }`;
};
