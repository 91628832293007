import moment from "moment";

import React from 'react';

export const GBPFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  // minimumFractionDigits: 0,
  // maximumFractionDigits: 0,
});

export const venuePackageUpdatedCuttoff = new Date('2022-01-01 00:00:00');

export const formatCurrency = input => {
  let convertedInput = 0;
  if (Number.isInteger(input) || Number.parseFloat(input)) {
    convertedInput = input;
  }
  return GBPFormatter.format(convertedInput);
};

export const normalizeCurrency = val => {
  let output = val.replace(/,/g, "");
  output = output.replace(/£/g, "");
//  console.log("VALUE: " + output);
  return output;
};

/**
 * Get Vat Multiplier from Vat Rate
 *
 * Vat Rate should be in form of 1 = 1% e.g. 17.5% VAT = 17.5 NOT 0.175
 *
 * @param vatRate
 * @returns {number}
 */
const vatMultiplierFromVatRate = (vatRate) => {
  return 1 + (Number(vatRate) / 100);
}

/**
 * @param {Number} netValue
 * @param {*} vatRate
 */
function grossFromNet(netValue, vatRate) {
  const vatMultiplier = vatMultiplierFromVatRate(vatRate);
  return Number(netValue * vatMultiplier).toFixed(2)
}


/**
 * @param {Number} grossValue
 * @param {*} vatRate
 */
function netFromGross(grossValue, vatRate) {
  const vatMultiplier = vatMultiplierFromVatRate(vatRate);
  return Number(grossValue / vatMultiplier).toFixed(2);
}

/**
 * Return the total VAT from netValue with a given vatRate.
 *
 * @param {Number} netValue
 * @param {Number} vatRate
 *
 * @returns {Number} vatAmount
 */
function vatFromNet(netValue, vatRate) {
  const gross = grossFromNet(netValue, vatRate);
  return Number(gross - netValue).toFixed(2);
}

/**
 * Return the total VAT from grossValue with the give vatRate.
 *
 * @param {Number} grossValue
 * @param {Number} vatRate
 *
 * @returns {Number} vatAmount
 */
function vatFromGross(grossValue, vatRate) {
  const net = netFromGross(grossValue, vatRate);
  return Number(grossValue - net).toFixed(2);
}

/**
 * Return all components of a figure from a grossValue and vatRate.
 *
 * @param {Number} grossValue
 * @param {Number} vatRate
 *
 * @returns {Object} components
 */
function grossToComponents(grossValue, vatRate) {
  return {
    net: Number(netFromGross(grossValue, vatRate)),
    vat: Number(vatFromGross(grossValue, vatRate)),
    gross: Number(grossValue)
  };
}

/**
 * Return all components of a figure from a value, a vatRate and whether the initial value includes VAT .
 *
 * @param {boolean} includesVat
 * @param {*} value
 * @param {number} vatRate
 *
 * @returns {Object} components
 */
export const calculateVat = (includesVat, value, vatRate) => {
  let gross = value;
  if (!includesVat) {
    let vat = vatFromNet(value, vatRate);
    gross = Number(value) + Number(vat);
  }
  return grossToComponents(gross, vatRate);
}

export const removeEmptySearchParams = (queryParams) => {
  let keysForDel = [];
  queryParams.forEach((value, key) => {
    if (value === '' || value === null) {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach(key => {
    queryParams.delete(key);
  });
}

export const downloadButton = (fileUrl, logo = false, tooltip) => {
  if (!fileUrl) {
    return (
      <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x text-lg font-medium px-1 text-dark-grey opacity-25`}></i>
    )
  }
  return (
    <a className={`text-lg font-medium px-1`} href={fileUrl}  data-tip={tooltip} target={'_blank'}>
      <i className={`fas ${logo ? logo : 'fa-file-download'} fa-1x`}></i>
    </a>
  )
}

export const formattedApplicableDates = (startDate, endDate, applicableDays = [], dateFormat = 'Do MMM YYYY') => {
  const days = [];
  let m = moment(startDate);
  do {
    days.push(m.format(dateFormat));
    m.add(1, 'days');
  } while (m.diff(endDate, 'days') <= 0);


  const o = days.filter((d, i, days) => {
    return applicableDays.includes(i + 1);
  });

  return o;
}

export const applicableDayOptions = (startDate, endDate, applicableDays, setApplicableDays) => {
  const days = [];
  let m = moment(startDate);
  do {
    days.push(m.format('DD/MM'));
    m.add(1, 'days');
  } while (m.diff(endDate, 'days') <= 0);
  return (
      <div className="flex">
        {days.map((d, i, days) => {
          return <span key={i}
                       onClick={()=>{
                         if (applicableDays.includes(i+1)) {
                           // if more than one day is selected
                           if ( applicableDays.length > 1 ) {
                             // remove the day
                             setApplicableDays([...applicableDays.filter(e => e !== i + 1)]);
                           }
                         } else {
                           // add the day
                           setApplicableDays([...applicableDays, i+1]);
                         }
                       }}
                       className={`inline-block flex items-center px-2 text-xs py-2 border border-grey-md ${i + 1 !== days.length ? 'border-r-0' : ''} ${applicableDays.includes(i + 1) ? 'bg-brand' : ''}`} //hover:bg-grey-md
                       role="button"
          >{d}</span>
        })}
      </div>
  );
}