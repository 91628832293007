import React from "react";
import TrapezeHeader from "Components/TrapezeHeader";
import { FaQuestion } from "react-icons/fa";
import { ButtonOutlined } from "Components/Button";
import { Link } from "react-router-dom";

const IsThisYourVenue = ({ loggedIn }) => {
  return (
    <div className="bg-white mb-5 hidden lg:block">
      <TrapezeHeader
        bgColor="grey-darker"
        title="Is this your venue?"
        icon={<FaQuestion className="mr-2 text-white text-lg" />}
        bigText
        textLeft
      />
      <div className="py-3 px-4">
        {!loggedIn ? (
          <p className="text-sm">
            Register for FREE to edit your basic profile, or become a licenced
            venue for £75/month and gain access to profile analytics, offers,
            instant booking and much more...
          </p>
        ) : (
          <p className="text-sm">
            As a licenced venue, for £75/month, you can gain access to profile
            analytics, offers, instant booking and much more... Contact us today
            by emaiing{" "}
            <a href="mailto:info@edgevenues.com">info@edgevenues.com</a> or
            calling <a href="tel:441780484051">+44(0) 1780 484051</a>
          </p>
        )}
        <div className="mt-3">
          {!loggedIn && (
            <Link to="/register">
              <ButtonOutlined classes="border-2 border-green text-green w-full">
                Register online
              </ButtonOutlined>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default IsThisYourVenue;
