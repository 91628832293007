import axios from "axios";
import moment from "moment";
import pick from "lodash/pick";
import {getRoomStyles} from "../roomStyleService";
import {getStatusText} from '../../modules/rfp/enquiryVenueDataStatus';
import * as ROUTES from "../../Router/rfp_routes";

const requestedBy = user => ({
  name: `${user.first_name} ${user.surname}`,
  company: user.job_title || "",
  email: user.email || "",
  isDeleted: user.isDeleted,
});



const getEnquiries = async ({page, term, type, sortBy, status, dateFrom, dateTo}, token) => {
  try {
    if (undefined === status) {
      status = ''
    }
    if (undefined === dateFrom) {
      dateFrom = ''
    }
    if (undefined === dateTo) {
      dateTo = ''
    }
    const response = await axios.get(
      `${window.API}/rfp/enquiry?page=${page}${
        type ? "&type=" + type : ""
      }&term=${term}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}&sort_by=${sortBy.field}:${sortBy.order}`,
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    );

    if (!response.data.data) {
      return {
        result: {
          enquiries: [],
          current_page: 1,
          last_page: 1,
        },
      };
    }
    const enquiries = response.data.data.map(({data: enquiry}) => {
      let department = '';
      if (enquiry.department && enquiry.department.parent) {
        department = enquiry.department.parent.name + ' > ' + enquiry.department.name
      } else if (enquiry.department) {
        department = enquiry.department.name
      }
      return {
        type: enquiry.type || "new",
        ref_id: enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.minimal_user.company ? enquiry.minimal_user.company.code + enquiry.id : enquiry.id,
        company_code: enquiry.client_company ? enquiry.client_company.code + enquiry.id : enquiry.id,
        company_name: enquiry.client_company ? enquiry.client_company.name : enquiry.minimal_user.email,
        booking_desk: (enquiry.corporate_specific_data && enquiry.corporate_specific_data.booking_desk) ? enquiry.corporate_specific_data.booking_desk.name : '',
        department: department,
        id: enquiry.id,
        event_name: enquiry.event_name,
        requested_by: requestedBy(enquiry.minimal_user),
        is_managed: (!!enquiry.managing_trinity_user),
        managed_by: enquiry.managing_trinity_user,
        first_assigned_at: enquiry.first_assigned_at,
        user_type: enquiry.minimal_user.user_type.name,
        venues_contacted: enquiry.enquiry_venue_datas.length,
        date_submitted: moment(enquiry.created_at).format("DD/MM/YYYY HH:mm"),
        event_start_date: moment(enquiry.date_from).format("DD/MM/YYYY"),
        status: getStatusText(enquiry.enquiry_venue_datas),
        enquiry_status: enquiry.enquiry_status,
        assigned_to: enquiry.managing_trinity_user ? enquiry.managing_trinity_user.first_name + ' ' + enquiry.managing_trinity_user.surname : null,
        enquirer_unread_message_count: enquiry.enquirer_unread_message_count,
        venue_unread_message_count: enquiry.venue_unread_message_count,
        all_venues_have_contact_details: !!enquiry.all_enquiry_venues_have_emails,
        venues: enquiry.enquiry_venue_datas.map(evd => ({
          name: evd.minimal_venue.name,
          email: evd.minimal_venue.email
        })),
        commission_collected: enquiry.commission_collected,
        collect_commission: enquiry.collect_commission,
      }});

    return {
      result: {
        enquiries,
        current_page: response.data.meta ? response.data.meta.current_page : 1,
        last_page: response.data.meta ? response.data.meta.last_page : 1,
      },
    };
  } catch (error) {
    return { error };
  }
};

const getEnquiryByShareId = async (shareId, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/enquiry/share/${shareId}`, {
      headers: {Authorization: `Bearer ${token}`}
    });
    return singleEnquiryData(response, token);
  } catch (error) {
    return { error };
  }
}

const getEnquiryById = async (id, token) => {
  try {
    const response = await axios.get(`${window.API}/rfp/enquiry/${id}`, {
      headers: {Authorization: `Bearer ${token}`},
    });
    return singleEnquiryData(response, token);
  } catch (error) {
    return { error };
  }
};

const singleEnquiryData = async (response, token) => {
  try {
    const { result: rooms } = await getRoomStyles(null, token);
    const brief = {
      ...pick(response.data.data, [
        "additional_notes",
        "event_name",
        "enquiry_status",
        "number_of_delegates",
        "bedroom_dates",
        "date_from",
        "date_to",
        "date_flexible",
        "date_flexible_notes",
        "bedrooms_required",
        "bedroom_additional_notes",
        "back_projection",
        "syndicate_room",
        "syndicate_room_notes",
        "exhibition_space",
        "exhibition_space_notes",
        "private_dining",
        "private_dining_notes",
        "any_equipment",
        "any_equipment_notes",
        "attachments",
        "share_id",
        "event_timings",
        "date_decision_estimate"
      ]),
      room_style: (
        rooms.find(room => room.value === response.data.data.room_style_id) ||
        {}
      ).text,
    };
    let managing_trinity_user = null;
    if (response.data.data.managing_trinity_user) {
      managing_trinity_user = {
        id: response.data.data.managing_trinity_user.id,
        email: response.data.data.managing_trinity_user.email,
        phone: response.data.data.managing_trinity_user.phone,
        user_type: response.data.data.managing_trinity_user.user_type.name,
        name: `${response.data.data.managing_trinity_user.first_name} ${response.data.data.managing_trinity_user.surname}`,
      }
    }

    let event = null;
    if (response.data.data.event) {
      event = {
        id: response.data.data.event.id,
        contract_file: response.data.data.event.contract_file,
        contract_uploader: response.data.data.event.contract_uploader,
        contract_file_url: response.data.data.event.contract_file_url,
        contract_upload_date: response.data.data.event.contract_upload_date,
        event_status: response.data.data.event.event_status,
        requires_invoicing: response.data.data.event.requires_invoicing,
      }
    }

    const brief_preferences = response.data.data.brief_preferences;

    const client_details = {
      ref_id: response.data.data.client_company ? response.data.data.client_company.code + response.data.data.id : response.data.data.id,
      email: response.data.data.client ? response.data.data.client.email : response.data.data.minimal_user.email ,
      phone: response.data.data.client ? (response.data.data.client.phone ? response.data.data.client.phone  : response.data.data.client.mobile  ) :  response.data.data.minimal_user.phone,
      user_type: response.data.data.client ? 'N/A' : response.data.data.minimal_user.user_type.name,
      name: response.data.data.client ? `${response.data.data.client.first_name} ${response.data.data.client.surname}` : `${response.data.data.minimal_user.first_name} ${response.data.data.minimal_user.surname}`,
    };

    const agency_details = {
      name: response.data.data.minimal_user.agency ? response.data.data.minimal_user.agency.name : null
    };

    const company_details = {
      // name: response.data.data.minimal_user.company ? response.data.data.minimal_user.company.name : null
      name: response.data.data.client_company ? response.data.data.client_company : null,
    };

    const agency_specific_data = {
      client_name: response.data.data.client ? `${response.data.data.client.first_name} ${response.data.data.client.surname}` : '',
      company_name: response.data.data.client_company ? `${response.data.data.client_company.name}` : '',
      industry_sector: response.data.data.industry,
      share_with_venues: response.data.data.share_with_venues,
      estimated_value: response.data.data.estimated_value,
      estimated_commission: response.data.data.estimated_commission
    };

    let enquiry_venue_datas = [];
    if (response.data.data.enquiry_venue_datas.length > 0) {
      let  {data: venues} = await axios.get(`${window.NODE_AS}/compare`, {
        params: {
          venues: response.data.data.enquiry_venue_datas.map(
            venue => venue.venue_id
          ),
        },
      });
      enquiry_venue_datas = response.data.data.enquiry_venue_datas.map(
        enquiry_venue_data => ({
          ...enquiry_venue_data,
          venue: venues.find(
            venue => venue.id === enquiry_venue_data.venue_id
          ),
        })
      );
    }

    return {
      result: {
        id: response.data.data.id,
        ref_id: response.data.data.client_company ? response.data.data.client_company.code + response.data.data.id : response.data.data.id,
        collect_commission: response.data.data.collect_commission,
        is_revised: response.data.data.is_revised,
        enquiry_status: response?.data?.data?.enquiry_status?.code,
        brief,
        brief_preferences,
        event,
        client_details,
        agency_specific_data,
        managing_trinity_user,
        agency_details,
        company_details,
        enquiry_venue_datas

      },
    };
  } catch (error) {
    return { error };
  }
}

const submitEnquiry = async (data, token, editEnquiry = false, submitToAgent = false, enquiryToSubmitted = false, submitToDraft = false) => {
  let url = `${window.API}/rfp/enquiry`;
  if (data.bedrooms_required) {
    for (let i = 0; i < data.bedroom_dates.length; i++) {
      data.bedroom_dates[i].date = moment(data.bedroom_dates[i].date).format('YYYY-MM-DD HH:mm:ss');
    }
  }
  let formData = new FormData();
  data.files.forEach(file => {
    formData.append('files[]', file, file.name);
  });
  if (data.venues) {
    data.venues.forEach(venue => {
      formData.append('venues[]', venue);
    });
  }
  // else {
  //   formData.append('draft_enquiry', 1);
  // }
  if (submitToDraft) {
    formData.append('draft_enquiry', 1);
  } else {
    formData.append('draft_enquiry', 0);
  }

  data.bedrooms_required ? data.bedroom_dates.forEach(date => {
    formData.append('bedroom_dates[]', JSON.stringify(date));
  }) : formData.append('bedroom_dates[]', null);

  formData.append('date_from',  moment(data.date_from).add(12, "hours"))
  formData.append('date_to',  moment(data.date_to).add(12, "hours"))
  Object.keys(data).forEach((key) => {
    if (key !== 'files' && key !== 'venues' && key !== 'date_from' && key !== 'date_to' && key !== 'bedroom_dates') {
      if (data[key] !== null || data[key] !== "null") {
        formData.append(key, data[key])
      }
    }
  })
  if (submitToAgent) {
    formData.append('submit_to_agent', 1)
    formData.append('agent_input_required', 1)
  }

  if (editEnquiry) {
    formData.append('_method', 'PATCH');
    if (!data.company_id) {
      formData.delete('company_id');
    }
    if (data.date_decision_estimate === null) {
      formData.delete('date_decision_estimate');
    }
    if (data.department_id === null) {
      formData.delete('department_id');
    }
    if (data.estimated_commission === null) {
      formData.delete('estimated_commission');
    }
    if (data.estimated_value === null) {
      formData.delete('estimated_value');
    }
    if (!data.is_trinity_enquiry) {
      formData.delete('is_trinity_enquiry');
    }
    if (data.meeting_host_id === null) {
      formData.delete('meeting_host_id');
    }
    if (data.booking_desk_id === null) {
      formData.delete('booking_desk_id');
    }
    if (enquiryToSubmitted) {
      formData.append('submit_enquiry', 1);
    }
    url = window.API + ROUTES.API.BRIEF.EDIT.replace(':shareId', data.id);
  }
  Object.keys(data).forEach((key) => {
    if (key !== 'files' && key !== 'venues' && key !== 'date_from' && key !== 'date_to' && key !== 'bedroom_dates') {
      if (data[key] === null || data[key] === "null") {
        formData.delete(key)
      }
    }
  })

  try {
    const response = await axios.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { result: response.data };
  } catch (error) {
    return { error };
  }
};

const toggleCommissionCollected = async (rfpId, token) => {
  try {
    const response = await axios.post(
        `${window.API}/rfp/enquiry/${rfpId}/commission-collected`,
        null,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
    );
    return { result: response.data };
  } catch (error) {
    return { error };
  }
}

export { getEnquiries, getEnquiryById, submitEnquiry, toggleCommissionCollected, getEnquiryByShareId };
