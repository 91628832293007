import axios from 'axios';
import {
  SALES_SUMMARY_FIGURES_RECEIVED,
  SALES_SUMMARY_FIGURES_RECEIVED_ERROR,
  SALES_SUMMARY_FIGURES_REQUEST,
  SALES_SUMMARY_FIGURES_UNLOAD,
  SALES_SUMMARY_ITEM_RECEIVED,
  SALES_SUMMARY_ITEM_RECEIVED_ERROR,
  SALES_SUMMARY_ITEM_REQUEST, SALES_SUMMARY_ITEM_UNLOAD,
  SNAPSHOT_FIGURES_RECEIVED,
  SNAPSHOT_FIGURES_RECEIVED_ERROR,
  SNAPSHOT_FIGURES_REQUEST,
  SNAPSHOT_FIGURES_UNLOAD
} from '../reducers/types';
import { format } from "date-fns";

/** SnapShot */
export const snapshotFiguresRequest = () => ({
  type: SNAPSHOT_FIGURES_REQUEST
});

export const snapShotFiguresRequestReceived = (data) => ({
  type: SNAPSHOT_FIGURES_RECEIVED,
  data
})

export const snapShotFiguresError = (error) => ({
  type: SNAPSHOT_FIGURES_RECEIVED_ERROR,
  error
})

export const snapShotFiguresUnload = () => ({
  type: SNAPSHOT_FIGURES_UNLOAD,
})

/** Sales Summary */
export const salesSummaryFiguresRequest = () => ({
  type: SALES_SUMMARY_FIGURES_REQUEST
});

export const salesSummaryFiguresRequestReceived = (data) => ({
  type: SALES_SUMMARY_FIGURES_RECEIVED,
  data
})

export const salesSummaryFiguresError = (error) => ({
  type: SALES_SUMMARY_FIGURES_RECEIVED_ERROR,
  error
})

export const salesSummaryFiguresUnload = () => ({
  type: SALES_SUMMARY_FIGURES_UNLOAD,
})

/** Sales Summary Sum */
export const salesSummaryItemsRequest = () => ({
  type: SALES_SUMMARY_ITEM_REQUEST
});

export const salesSummaryItemsRequestReceived = (data) => {return{
  type: SALES_SUMMARY_ITEM_RECEIVED,
  data
}}

export const salesSummaryItemsError = (error) => ({
  type: SALES_SUMMARY_ITEM_RECEIVED_ERROR,
  error
})

export const salesSummaryItemsUnload = () => ({
  type: SALES_SUMMARY_ITEM_UNLOAD,
})




/** Snapshot Figures */
export const fetchSnapShotFigures = (venueIds, token) => {
  var queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');
  return (dispatch) => {
    dispatch(snapshotFiguresRequest());
    return axios.get(window.API + '/reporting/snapshot-figures' + '?' +  queryString , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(snapShotFiguresRequestReceived(response.data)))
        .catch(error => dispatch(snapShotFiguresError(error)));
  };
};

/** Snapshot Figures */
export const fetchSalesSummaryFigures = (venueIds, startDate, endDate,  token) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  let start_date = format(startDate, "YYYY-MM-DD");
  let  end_date = format(endDate, "YYYY-MM-DDT23:59:59");
  return (dispatch) => {
    dispatch(salesSummaryFiguresRequest());
    return axios.get(window.API + '/reporting/sales-summary' + '?' +  queryString + '&start_date=' + start_date + '&end_date=' + end_date  , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(salesSummaryFiguresRequestReceived(response.data)))
        .catch(error => dispatch(salesSummaryFiguresError(error)));
  };
};


/** Snapshot Figures */
export const fetchSalesSummaryItems = (venueIds, startDate, endDate, type, token) => {
  let queryString = Object.keys(venueIds).map(key => 'venue_ids[]' + '=' + venueIds[key]).join('&');

  let start_date = format(startDate, "YYYY-MM-DD");
  let  end_date = format(endDate, "YYYY-MM-DDT23:59:59");
  return (dispatch) => {
    dispatch(salesSummaryItemsRequest());
    return axios.get(window.API + '/reporting/sales-summary-items' + '?' +  queryString + '&start_date=' + start_date + '&end_date=' + end_date + '&type=' + type  , {
      headers: { Authorization: `Bearer ${token}` },
    }).then(response => dispatch(salesSummaryItemsRequestReceived(response.data)))
        .catch(error => dispatch(salesSummaryItemsError(error)));
  };
};

