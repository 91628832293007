import React, { useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFormSubmitErrors, destroy, SubmissionError } from "redux-form";
import { Panel, ProgressBar } from "ui";
import {isAgency, isInternal as isInternalSelector, isSuperAgent} from "modules/auth";
import Loading from "Components/Loading";
import useParams from "hooks/useParams";
import useService from "hooks/useService";
import { token as tokenSelector } from "modules/auth";
import mapServerErrors from "modules/map-server-errors";
import { getVenues } from "services/venueService";
import {submitEnquiry} from "services/rfpService";
import VenueSlider from "./VenueSlider";
import {pushCheckOutStep, pushSubmitEnquiry} from "../../modules/google-tag-manager";
import {getRoomStyles} from "../../services/rfpService";
import {getPages} from "./pages";
import moment from "moment";

const form = "rfp_enquiry";

const mapStateToProps = (state, props) => {
  if (props.enquiry) {
    let venueIds = [];
    props.enquiry.enquiry_venue_datas && props.enquiry.enquiry_venue_datas.map(vd => {
      venueIds.push(vd.venue_id)
    });
    return {
      enquiry: props.enquiry,
      editEnquiry: true,
      isAgency: isAgency(state),
      isSuperAgent: isSuperAgent(state),
      venueIds: venueIds,
      initialValues: {
        ...props.enquiry,
        date_from: moment(props.enquiry.date_from).utc(true).set({hour:0,minute:0,second:0,millisecond:0}).toISOString(),
        date_to: moment(props.enquiry.date_to).utc(true).set({hour:0,minute:0,second:0,millisecond:0}).toISOString(),
        booking_desk_id: props.enquiry && props.enquiry.corporate_specific_data ? props.enquiry.corporate_specific_data.booking_desk_id : null,
        is_trinity_enquiry: props.enquiry && props.enquiry.corporate_specific_data ? props.enquiry.corporate_specific_data.is_trinity_enquiry : false,
        brief_location: props.enquiry ? props.enquiry.brief_preferences?.location : '',
        brief_event_type: props.enquiry? props.enquiry.brief_preferences?.event_type : '',
        brief_budget: props.enquiry ? props.enquiry.brief_preferences?.budget : '',
      }
    }
  }
  return {
    isAgency: isAgency(state),
    isSuperAgent: isSuperAgent(state)
  }
}

const Rfp = ({ location, initialValues, editEnquiry = false, venueIds = [], isAgency, isSuperAgent, enquiry }) => {
  const titleEl = useRef(null);
  const token = useSelector(tokenSelector);
  const submitErrors = useSelector(getFormSubmitErrors(form));
  const isInternal = useSelector(isInternalSelector);
  const errors = Object.keys(submitErrors);

  const [page, setPage] = useState(0);
  const [submitToAgent, setSubmitToAgent] = useState(false);
  const [submitToDraft, setSubmitToDraft] = useState(false);
  const [enquiryToSubmitted, setEnquiryToSubmitted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [submitting, setSubmitting] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [showDateWarningMessage, setShowDateWarningMessage] = useState(false);
  const params = useParams(location?.search, "venues");
  const stageTitle = editEnquiry ? "Edit your Event Brief" : "Your Event Enquiry";
  let venuesCsv = null;
  if (params && params.venues) {
    venuesCsv = params.venues;
  } else if (venueIds.length > 0) {
    venuesCsv = venueIds;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(destroy(form));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params && params['venues']) {
      pushCheckOutStep(page + 1, params['venues'], location.pathname + location.search)
    }
  }, [page, params, location?.pathname, location?.search]);

  const { loading, data: venues } = useService({
    initialData: [],
    initialLoading: false,
    service: getVenues,
    params: venuesCsv ? venuesCsv : null,
    trigger: params,
  });
  const hasVenues = !!venues;

  const { loading : styleLoading, data: roomStyleOptions, error } = useService({
    service: getRoomStyles,
    trigger: true,
    params: null,
  });

  const nextPage = () => {
    setCompleted(prev => {
      const done = [...prev];
      done[page] = true;
      return done;
    });
    setPage(page + 1);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleSubmitEnquiry = async data => {
    setSubmitting(true);
    setSubmitError(false);

    const { result, error } = await submitEnquiry(
      { ...data, venues: params.venues, files: attachments},
      token,
      editEnquiry,
      submitToAgent,
      enquiryToSubmitted,
      submitToDraft
    );

    setSubmitting(false);

    if (error) {
      setSubmitError(true);
      titleEl.current.scrollIntoView();
      throw new SubmissionError(mapServerErrors(error));
    } else {
      if (result && params.venues) {
        pushSubmitEnquiry(result.data.id, params.venues)
      }
      setSubmitSuccess(true);
    }
  };

  const pages = getPages(
    form,
    nextPage,
    prevPage,
    errors,
    handleSubmitEnquiry,
    attachments,
    setAttachments,
    roomStyleOptions,
    hasVenues,
    initialValues,
    editEnquiry,
    setSubmitToAgent,
    setEnquiryToSubmitted,
    isAgency,
    isSuperAgent,
    setSubmitToDraft,
    enquiry,
    setShowDateWarningMessage
  );
  // if (!params || !params.venues || !params.venues.length) {
  //   return (
  //     <div className="py-5 container">
  //       <h1 className="mb-5">Request for proposal</h1>
  //       <p className="text-lg">
  //         Please complete you brief, and one of our agents will review and advise on suggested venues to contact for a response<br/>
  //         or click <Link to={'/advanced-search'}>here</Link> to search for a Venue.
  //       </p>
  //     </div>
  //   );
  // }
  //
  // if (loading) {
  //   return <Loading large />;
  // }
  //
  if (styleLoading) {
    return <Loading large />;
  }

  const submitSuccessMessage = () => {
    if (!venues && isAgency && !isSuperAgent) {
      return <>Your brief has been saved as Draft. When you are happy with the requirements please submit it to the venues. {" "}
        You can <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here </Link>{" "}
      </>
    }
    if (enquiryToSubmitted) {
      return <>Thank you for your enquiry, your request for proposal has been submitted. You can{" "}
        <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here. </Link>{" "}
      </>
    } else if (submitToDraft || (!venues && !submitToAgent)) { // and draft
      return <>Your brief has been saved as Draft. When you are happy with the requirements please submit it to agent who will suggest suitable venues. {" "}
        You can <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/draft-rfps"}>view your RFPs here </Link>{" "}
      </>
    } else if (editEnquiry && !submitToAgent) {
      return <>Thanks for editing the brief. It has passed on to the original user/agent who created this enquiry to submit. {" "}
        You can <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/draft-rfps"}>view your RFPs here </Link>{" "}
      </>
    } else if (submitToAgent) {
      return <>Your brief has passed on to an agent who will suggest suitable venues. {" "}
        You can <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here </Link>{" "}
      </>
    }
    // original text (request-for-proposal?venues=)
    return <>Thank you for your enquiry, your request for proposal has been submitted. You can{" "}
      <Link to={isInternal ? "/admin/rfp" : "/admin/my-edge/your-rfps"}>view your RFPs here </Link>{" "}
      but this is not editable at this stage now this has been submitted.
    </>
  }

  if (submitSuccess && page === 2) {
    return (
      <div className="py-5 container">
        <h1 className="mb-5">
          {stageTitle}
        </h1>
        <p className="text-lg">
          {submitSuccessMessage()}
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="py-5 container">
        <h1 className="mb-5" ref={titleEl}>
          {stageTitle}
        </h1>
        {showDateWarningMessage ? (
                <div className='bg-warning px-3 py-6 my-5 block font-bold text-white'>
                  <p >Since you have changed start and/or end dates for an enquiry with an existing checksheet you now need to review the conference package, meeting room and accommodation dates for the checksheet to ensure they match the new date range</p>
                </div>
        ) : null}
        <ProgressBar
          stages={pages.map((p, i) => ({
            ...p,
            complete: completed[i],
          }))}
          currentStage={page}
        />
        {submitError && page === 2 && (
          <p className="text-lg text-warning">
            There was a problem submitting your request.
          </p>
        )}
        {page === 0 ? (
          <>
            <h3>Get started</h3>
            <p className="max-w-md">
              {venues && venues.length > 0 ? (
                <>
                  You only have to complete step 1 to send an enquiry to your selected venues.
                  <br />
                  However, if you provide more information initially it will help the proposal process.
                </>
              ) : (
                <>
                  Please complete you brief, and one of our agents will review and advise on suggested venues to contact
                  for a response or click <Link to={'/advanced-search'}>here</Link> to search for a Venue.
                </>
              )}
            </p>
          </>
        ) : page === 1 ? (
          <>
            <h3>Bedrooms</h3>
            <p>
              From here you can indicate how many rooms you require before, during and after your event
            </p>
          </>
        ) : (
          <>
            <h3>The last step</h3>
            <p>Please let us know if you have any of the below requirements</p>
          </>
        )}
        <Panel>{submitting ? <Loading large /> : pages[page].Component}</Panel>
        {venues && (
          <VenueSlider venues={venues} />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(Rfp);
