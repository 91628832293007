import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Public from "./Public";
import ScrollToTop from "Components/ScrollToTop";
import { LoadingHalfPage } from "../Components/Loading";
import SentryAppendDetails from "../Components/SentryAppendDetails";
import { RouteTracker } from "../modules/google-tag-manager";
import Seo from "Components/Seo";

const Admin = lazy(() => import("./Admin" /* webpackChunkName: "admin" */));

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Seo />
        <RouteTracker />
        <SentryAppendDetails />
        <Suspense fallback={<LoadingHalfPage />}>
          <Switch>
            <Route path="/admin" render={props => <Admin {...props} />} />
            <Route component={Public} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}
