import axios from 'axios';
import {
    CORPORATE_ACCOUNT_LIST_RECEIVED,
    CORPORATE_ACCOUNT_LIST_REQUEST,
    CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_LIST_UNLOAD,
    CORPORATE_ACCOUNT_REQUEST,
    CORPORATE_ACCOUNT_RECEIVED,
    CORPORATE_ACCOUNT_RECEIVED_ERROR,
    CORPORATE_ACCOUNT_UNLOAD,
    CREATE_CORPORATE_ACCOUNT,
    CREATE_CORPORATE_ACCOUNT_ERROR,
    EDIT_CORPORATE_ACCOUNT,
    EDIT_CORPORATE_ACCOUNT_ERROR,
    CORPORATE_ACCOUNT_LIST_USERS_REQUEST,
    CORPORATE_ACCOUNT_LIST_USERS_RECEIVED,
    CORPORATE_ACCOUNT_LIST_USERS_ERROR,
    UNLOAD_CORPORATE_ACCOUNT_USERS,
} from '../reducers/types';
import * as ROUTES from '../../Router/corporate_routes';
import {reset} from "redux-form";
import {removeEmptySearchParams} from "../../utils";

export const corporateAccountListRequest = () => ({
    type: CORPORATE_ACCOUNT_LIST_REQUEST,
});
export const corporateAccountListReceivedRequest = (data) => ({
    type: CORPORATE_ACCOUNT_LIST_RECEIVED,
    data,
});
export const corporateAccountListReceivedError = (error) => ({
    type: CORPORATE_ACCOUNT_LIST_RECEIVED_ERROR,
    error,
});

export const unloadCorporateAccountList = () => ({
    type: CORPORATE_ACCOUNT_LIST_UNLOAD
});

export const corporateAccountRequest = () => ({
    type: CORPORATE_ACCOUNT_REQUEST,
});
export const corporateAccountReceivedRequest = (data) => ({
    type: CORPORATE_ACCOUNT_RECEIVED,
    data,
});
export const corporateAccountReceivedError = (data) => ({
    type: CORPORATE_ACCOUNT_RECEIVED_ERROR,
    data,
});

export const unloadCorporateAccount = () => ({
    type: CORPORATE_ACCOUNT_UNLOAD
});

export const createCorporateAccount = (data) => ({
    type: CREATE_CORPORATE_ACCOUNT,
    data
});

export const createCorporateAccountError = (error) => {
    return {
        type: CREATE_CORPORATE_ACCOUNT_ERROR,
        error
    }
};

export const editCorporateAccount = (data) => ({
    type: EDIT_CORPORATE_ACCOUNT,
    data
});

export const editCorporateAccountError = (error) => {
    return {
        type: EDIT_CORPORATE_ACCOUNT_ERROR,
        error
    }
};

export const corporateAccountUserRequest = () => ({
    type: CORPORATE_ACCOUNT_LIST_USERS_REQUEST
});

export const corporateAccountUsersReceivedRequest = data => ({
    type: CORPORATE_ACCOUNT_LIST_USERS_RECEIVED,
    data
});

export const corporateAccountUsersReceivedError = error => ({
    type: CORPORATE_ACCOUNT_LIST_USERS_ERROR,
    error
});

export const unloadCorporateAccountUsers = () => ({
    type: UNLOAD_CORPORATE_ACCOUNT_USERS
});

export const fetchCorporateAccounts = (token) => {
    return (dispatch) => {
        dispatch(corporateAccountListRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.LIST, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountListReceivedRequest(response.data)))
            .catch(error => dispatch(corporateAccountListReceivedError(error)));
    };
};

export const fetchCorporateAccount = (id, token) => {
    return (dispatch) => {
        dispatch(corporateAccountRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.SINGLE.replace(':slug', id), {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(corporateAccountReceivedRequest(response.data)))
            .catch(error => {
                dispatch(corporateAccountReceivedError(error));
            });
    };
};

export const fetchCorporateAccountUsers = (id, token, queryParams = {}) => {
    queryParams = new URLSearchParams(queryParams);
    removeEmptySearchParams(queryParams);
    let queryString = queryParams.toString()
    return (dispatch) => {
        dispatch(corporateAccountUserRequest());
        return axios.get(window.API + ROUTES.API.CORPORATE.LIST_USERS.replace(':slug', id) + '?' + queryString, {
            headers: {Authorization: `Bearer ${token}`}
        }).then(response => dispatch(corporateAccountUsersReceivedRequest(response.data)))
          .catch(error => corporateAccountUsersReceivedError(error));
    }
};

export const createAccount = (token, formValues) => {
    return (dispatch) => {
        dispatch(corporateAccountRequest());
        return axios.post(window.API + ROUTES.API.CORPORATE.CREATE, formValues, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(createCorporateAccount(response)))
            .catch(error => {
                dispatch(createCorporateAccountError(error));
                return Promise.reject(error);
            });
    };
};

export const resetCreateForm = () => {
    return (dispatch) => {
        dispatch(reset('corporateAccountForm'));

    };
};

export const editAccount = (slug, token, formValues) => {
    return (dispatch) => {
        dispatch(corporateAccountRequest());
        return axios.post(window.API + ROUTES.API.CORPORATE.EDIT.replace(':slug', slug), formValues, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(response => dispatch(createCorporateAccount(response)))
            .catch(error => {
                dispatch(createCorporateAccountError(error));
                return Promise.reject(error);
            });
    };
};